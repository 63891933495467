var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __knownSymbol = (name, symbol) => (symbol = Symbol[name]) ? symbol : Symbol.for("Symbol." + name);
var __typeError = (msg) => {
  throw TypeError(msg);
};
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __require = /* @__PURE__ */ ((x) => typeof require !== "undefined" ? require : typeof Proxy !== "undefined" ? new Proxy(x, {
  get: (a, b) => (typeof require !== "undefined" ? require : a)[b]
}) : x)(function(x) {
  if (typeof require !== "undefined") return require.apply(this, arguments);
  throw Error('Dynamic require of "' + x + '" is not supported');
});
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
var __accessCheck = (obj, member, msg) => member.has(obj) || __typeError("Cannot " + msg);
var __privateGet = (obj, member, getter) => (__accessCheck(obj, member, "read from private field"), getter ? getter.call(obj) : member.get(obj));
var __privateAdd = (obj, member, value) => member.has(obj) ? __typeError("Cannot add the same private member more than once") : member instanceof WeakSet ? member.add(obj) : member.set(obj, value);
var __privateSet = (obj, member, value, setter) => (__accessCheck(obj, member, "write to private field"), setter ? setter.call(obj, value) : member.set(obj, value), value);
var __privateWrapper = (obj, member, setter, getter) => ({
  set _(value) {
    __privateSet(obj, member, value, setter);
  },
  get _() {
    return __privateGet(obj, member, getter);
  }
});
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
var __await = function(promise, isYieldStar) {
  this[0] = promise;
  this[1] = isYieldStar;
};
var __asyncGenerator = (__this, __arguments, generator) => {
  var resume = (k, v, yes, no) => {
    try {
      var x = generator[k](v), isAwait = (v = x.value) instanceof __await, done = x.done;
      Promise.resolve(isAwait ? v[0] : v).then((y) => isAwait ? resume(k === "return" ? k : "next", v[1] ? { done: y.done, value: y.value } : y, yes, no) : yes({ value: y, done })).catch((e) => resume("throw", e, yes, no));
    } catch (e) {
      no(e);
    }
  }, method = (k) => it[k] = (x) => new Promise((yes, no) => resume(k, x, yes, no)), it = {};
  return generator = generator.apply(__this, __arguments), it[__knownSymbol("asyncIterator")] = () => it, method("next"), method("throw"), method("return"), it;
};
var __yieldStar = (value) => {
  var obj = value[__knownSymbol("asyncIterator")], isAwait = false, method, it = {};
  if (obj == null) {
    obj = value[__knownSymbol("iterator")]();
    method = (k) => it[k] = (x) => obj[k](x);
  } else {
    obj = obj.call(value);
    method = (k) => it[k] = (v) => {
      if (isAwait) {
        isAwait = false;
        if (k === "throw") throw v;
        return v;
      }
      isAwait = true;
      return {
        done: false,
        value: new __await(new Promise((resolve) => {
          var x = obj[k](v);
          if (!(x instanceof Object)) __typeError("Object expected");
          resolve(x);
        }), 1)
      };
    };
  }
  return it[__knownSymbol("iterator")] = () => it, method("next"), "throw" in obj ? method("throw") : it.throw = (x) => {
    throw x;
  }, "return" in obj && method("return"), it;
};
var __forAwait = (obj, it, method) => (it = obj[__knownSymbol("asyncIterator")]) ? it.call(obj) : (obj = obj[__knownSymbol("iterator")](), it = {}, method = (key, fn) => (fn = obj[key]) && (it[key] = (arg) => new Promise((yes, no, done) => (arg = fn.call(obj, arg), done = arg.done, Promise.resolve(arg.value).then((value) => yes({ value, done }), no)))), method("next"), method("return"), it);

// src/utils/array.ts
function findItemAndDelete(array, predicate) {
  return deleteAtIndex(array, array.findIndex(predicate));
}
function addItem(array, item) {
  const index2 = array.indexOf(item);
  if (index2 > -1) return false;
  array.push(item);
  return true;
}
function deleteItem(array, item) {
  return deleteAtIndex(array, array.indexOf(item));
}
function deleteItems(array, ...items) {
  for (const item of items) {
    deleteItem(array, item);
  }
  return array;
}
function findById(array, id) {
  if (!array) return false;
  return array.find((item) => item.id === id);
}
function hasItem(array, item) {
  if (!array) return false;
  return array.indexOf(item) > -1;
}
function deleteAtIndex(array, index2) {
  if (index2 === -1) return false;
  array.splice(index2, 1);
  return true;
}
function toChunks(array, chunkSize) {
  const chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    const chunk = array.slice(i, i + chunkSize);
    chunks.push(chunk);
  }
  return chunks;
}
function* chunkedIterate(array, chunkSize) {
  for (let i = 0; i < array.length; i += chunkSize) {
    const chunk = array.slice(i, i + chunkSize);
    yield chunk;
  }
}
function chunkify(iterator, chunkSize) {
  return __asyncGenerator(this, null, function* () {
    let chunk = [];
    try {
      for (var iter = __forAwait(iterator), more, temp, error; more = !(temp = yield new __await(iter.next())).done; more = false) {
        const item = temp.value;
        chunk.push(item);
        if (chunk.length === chunkSize) {
          yield chunk;
          chunk = [];
        }
      }
    } catch (temp) {
      error = [temp];
    } finally {
      try {
        more && (temp = iter.return) && (yield new __await(temp.call(iter)));
      } finally {
        if (error)
          throw error[0];
      }
    }
    if (chunk.length > 0) {
      yield chunk;
    }
  });
}

// src/utils/clone.ts
import _clone from "rfdc";
var clone = _clone();

// src/utils/hostname.ts
function extractHostname(url) {
  const hostname = url.indexOf("//") > -1 ? url.split("/")[2] : url.split("/")[0];
  return hostname.split("?")[0];
}

// src/utils/constants.ts
var COMPATIBLE_SERVER_VERSION = 1;
function isServerCompatible(version) {
  return COMPATIBLE_SERVER_VERSION === version;
}
function isProduction() {
  return process.env.NODE_ENV === "production" || process.env.NODE_ENV === "test";
}
var hosts = {
  API_HOST: isProduction() ? "https://api.notesnook.com" : "http://localhost:5264",
  AUTH_HOST: isProduction() ? "https://auth.streetwriters.co" : "http://localhost:8264",
  SSE_HOST: isProduction() ? "https://events.streetwriters.co" : "http://localhost:7264",
  SUBSCRIPTIONS_HOST: isProduction() ? "https://subscriptions.streetwriters.co" : "http://localhost:9264",
  ISSUES_HOST: isProduction() ? "https://issues.streetwriters.co" : "http://localhost:2624"
};
var constants_default = hosts;
var HOSTNAMES = {
  [extractHostname(hosts.API_HOST)]: "Notesnook Sync Server",
  [extractHostname(hosts.AUTH_HOST)]: "Authentication Server",
  [extractHostname(hosts.SSE_HOST)]: "Eventing Server",
  [extractHostname(hosts.SUBSCRIPTIONS_HOST)]: "Subscriptions Management Server",
  [extractHostname(hosts.ISSUES_HOST)]: "Bug Reporting Server"
};
var getServerNameFromHost = (host) => {
  return HOSTNAMES[host];
};

// src/utils/internal-link.ts
var InternalLinkTypes = ["note"];
function createInternalLink(type, id, params) {
  let link = `nn://${type}/${id}`;
  if (params) {
    link += "?" + Object.entries(params).map(([key, value]) => `${key}=${value}`).join("&");
  }
  return link;
}
function parseInternalLink(link) {
  const url = new URL(link);
  if (url.protocol !== "nn:") return;
  const [type, id] = url.href.split("?")[0].split("/").slice(2);
  if (!type || !id || !isValidInternalType(type)) return;
  return {
    type,
    id,
    params: Object.fromEntries(url.searchParams.entries())
  };
}
function isInternalLink(link) {
  return link && link.startsWith("nn://");
}
function isValidInternalType(type) {
  return InternalLinkTypes.includes(type);
}

// src/utils/content-block.ts
var INTERNAL_LINK_REGEX = /(?:\[\[(nn:\/\/note\/.+?)\]\])/gm;
function extractInternalLinks(block) {
  const matches = block.content.matchAll(INTERNAL_LINK_REGEX);
  const links = [];
  for (const match2 of matches) {
    if (match2.index === void 0) continue;
    const url = match2[1].slice(0, match2[1].lastIndexOf("|"));
    const text = match2[1].slice(match2[1].lastIndexOf("|") + 1);
    const link = parseInternalLink(url);
    if (!link) continue;
    links.push(__spreadProps(__spreadValues({}, link), {
      start: match2.index,
      end: match2.index + match2[0].length,
      text
    }));
  }
  return links;
}
function normalize(block, links) {
  let diff = 0;
  for (const link of links) {
    link.start -= diff;
    link.end -= diff;
    block.content = block.content.slice(0, link.start) + link.text + block.content.slice(link.end);
    diff += link.end - link.start - link.text.length;
    link.end = link.start + link.text.length;
  }
  return block;
}
function highlightInternalLinks(block, noteId) {
  const links = extractInternalLinks(block);
  normalize(block, links);
  const highlighted = [];
  for (const link of links) {
    const start = block.content.slice(0, link.start);
    const end = block.content.slice(link.end);
    if (link.id !== noteId) continue;
    highlighted.push([
      {
        text: ellipsize(start, 50, "start"),
        highlighted: false
      },
      {
        highlighted: link.id === noteId,
        text: link.text
      },
      {
        highlighted: false,
        text: ellipsize(end, 50, "end")
      }
    ]);
  }
  return highlighted;
}
function ellipsize(text, maxLength, from) {
  const needsTruncation = text.length > maxLength;
  const offsets = needsTruncation ? from === "start" ? [-maxLength, void 0] : [0, maxLength] : [0, text.length];
  const truncated = text.slice(offsets[0], offsets[1]);
  return needsTruncation ? from === "start" ? "..." + truncated : truncated + "..." : truncated;
}

// src/utils/date.ts
import dayjs from "dayjs";
function getWeekGroupFromTimestamp(timestamp) {
  const date = new Date(timestamp);
  const { start, end } = getWeek(date);
  const startMonth = start.month !== end.month ? " " + MONTHS_SHORT[start.month] : "";
  const startYear = start.year !== end.year ? ", " + start.year : "";
  const startDate = `${start.day}${startMonth}${startYear}`;
  const endDate = `${end.day} ${MONTHS_SHORT[end.month]}, ${end.year}`;
  return `${startDate} - ${endDate}`;
}
var MS_IN_HOUR = 36e5;
function getWeek(date) {
  const day = date.getDay() || 7;
  if (day !== 1) {
    const hours2 = 24 * (day - 1);
    date.setTime(date.getTime() - MS_IN_HOUR * hours2);
  }
  const start = {
    month: date.getMonth(),
    year: date.getFullYear(),
    day: date.getDate()
  };
  const hours = 24 * 6;
  date.setTime(date.getTime() + MS_IN_HOUR * hours);
  const end = {
    month: date.getMonth(),
    year: date.getFullYear(),
    day: date.getDate()
  };
  return { start, end };
}
function getTimeFormat(format2) {
  return format2 === "12-hour" ? "hh:mm A" : "HH:mm";
}
function formatDate(date, options = {
  dateFormat: "DD-MM-YYYY",
  timeFormat: "12-hour",
  type: "date-time"
}) {
  switch (options.type) {
    case "date-time":
      return dayjs(date).format(
        `${options.dateFormat} ${getTimeFormat(options.timeFormat)}`
      );
    case "time":
      return dayjs(date).format(getTimeFormat(options.timeFormat));
    case "date":
      return dayjs(date).format(options.dateFormat);
  }
}
var MONTHS_FULL = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];
var MONTHS_SHORT = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];

// src/utils/event-manager.ts
var EventManager = class {
  constructor() {
    this._registry = /* @__PURE__ */ new Map();
  }
  unsubscribeAll() {
    this._registry.clear();
  }
  subscribeMulti(names, handler, thisArg) {
    return names.map((name) => this.subscribe(name, handler.bind(thisArg)));
  }
  subscribe(name, handler, once = false) {
    if (!name || !handler) throw new Error("name and handler are required.");
    this._registry.set(handler, { name, once });
    return { unsubscribe: () => this.unsubscribe(name, handler) };
  }
  subscribeSingle(name, handler) {
    if (!name || !handler) throw new Error("name and handler are required.");
    this._registry.forEach((props, handler2) => {
      if (props.name === name) this._registry.delete(handler2);
    });
    this._registry.set(handler, { name, once: false });
    return { unsubscribe: () => this.unsubscribe(name, handler) };
  }
  unsubscribe(_name, handler) {
    return this._registry.delete(handler);
  }
  publish(name, ...args) {
    this._registry.forEach((props, handler) => {
      if (props.name === name) {
        handler(...args);
        if (props.once) this._registry.delete(handler);
      }
    });
  }
  publishWithResult(name, ...args) {
    return __async(this, null, function* () {
      const handlers = [];
      this._registry.forEach((props, handler) => {
        if (props.name === name) {
          handlers.push(handler);
          if (props.once) this._registry.delete(handler);
        }
      });
      if (handlers.length <= 0) return true;
      return yield Promise.all(handlers.map((handler) => handler(...args)));
    });
  }
  remove(...names) {
    this._registry.forEach((props, handler) => {
      if (names.includes(props.name)) this._registry.delete(handler);
    });
  }
};
var event_manager_default = EventManager;

// src/utils/filename.ts
import db from "mime-db";
function getFileNameWithExtension(filename, mime) {
  if (!mime || mime === "application/octet-stream") return filename;
  const mimeData = db[mime];
  if (!mimeData || !mimeData.extensions || mimeData.extensions.length === 0)
    return filename;
  const extension = mimeData.extensions[0];
  if (mimeData.extensions.some((extension2) => filename.endsWith(extension2)))
    return filename;
  return `${filename}.${extension}`;
}
var PDFMimeType = "application/pdf";
var DocumentMimeTypes = [
  PDFMimeType,
  "application/msword",
  "application/vnd.ms-word",
  "application/vnd.oasis.opendocument.text",
  "application/vnd.openxmlformats-officedocument.wordprocessingml",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml",
  "application/vnd.oasis.opendocument.spreadsheet",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml",
  "application/vnd.oasis.opendocument.presentation"
];
function isDocument(mime) {
  return DocumentMimeTypes.some((a) => a.startsWith(mime));
}
var WebClipMimeType = "application/vnd.notesnook.web-clip";
function isWebClip(mime) {
  return mime === WebClipMimeType;
}
function isImage(mime) {
  return mime.startsWith("image/");
}
function isVideo(mime) {
  return mime.startsWith("video/");
}
function isAudio(mime) {
  return mime.startsWith("audio/");
}

// src/collections/reminders.ts
import dayjs2 from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore.js";
import isToday from "dayjs/plugin/isToday.js";
import isTomorrow from "dayjs/plugin/isTomorrow.js";
import isYesterday from "dayjs/plugin/isYesterday.js";

// src/utils/id.ts
import SparkMD5 from "spark-md5";

// src/utils/has-require.ts
function hasRequire() {
  return typeof __require === "function" && // eslint-disable-next-line no-undef, @typescript-eslint/ban-ts-comment
  // @ts-ignore
  (typeof IS_DESKTOP_APP === "undefined" || !IS_DESKTOP_APP);
}

// src/utils/random.ts
function randomBytes(size) {
  const crypto = globalThis.crypto || (hasRequire() ? __require("node:crypto") : null);
  if (!crypto) throw new Error("Crypto is not supported on this platform.");
  if ("randomBytes" in crypto && typeof crypto.randomBytes === "function")
    return crypto.randomBytes(size);
  if (!crypto.getRandomValues)
    throw new Error(
      "Crypto.getRandomValues is not available on this platform."
    );
  const buffer = Buffer.alloc(size);
  crypto.getRandomValues(buffer);
  return buffer;
}
function randomInt() {
  return randomBytes(4).readInt32BE();
}

// src/utils/object-id.ts
var PROCESS_UNIQUE = randomBytes(5).toString("hex");
var index = ~~(randomInt() * 16777215);
function createObjectId(date = Date.now()) {
  index++;
  const time = Math.floor(date / 1e3);
  let timeHex = time.toString(16);
  if (timeHex.length !== 8) timeHex = timeHex.padStart(2, "0").padEnd(8, "0");
  let incHex = swap16(index).toString(16);
  if (incHex.length !== 6) incHex = incHex.padStart(6, "0");
  return timeHex + PROCESS_UNIQUE + incHex;
}
function swap16(val) {
  return (val & 255) << 16 | val & 65280 | val >> 16 & 255;
}
function getObjectIdTimestamp(id) {
  return new Date(parseInt(id.substring(0, 8), 16) * 1e3);
}

// src/utils/id.ts
function getId(time) {
  return createObjectId(time);
}
function makeId(text) {
  return SparkMD5.hash(text);
}
function makeSessionContentId(sessionId) {
  return sessionId + "_content";
}

// src/common.ts
var EV = new event_manager_default();
function checkIsUserPremium(type) {
  return __async(this, null, function* () {
    const results = yield EV.publishWithResult(
      EVENTS.userCheckStatus,
      type
    );
    if (typeof results === "boolean") return results;
    return results.some((r) => r.type === type && r.result === true);
  });
}
var SYNC_CHECK_IDS = {
  autoSync: "autoSync",
  sync: "sync"
};
function checkSyncStatus(type) {
  return __async(this, null, function* () {
    const results = yield EV.publishWithResult(
      EVENTS.syncCheckStatus,
      type
    );
    if (typeof results === "boolean") return results;
    else if (typeof results === "undefined") return true;
    return results.some((r) => r.type === type && r.result === true);
  });
}
function sendSyncProgressEvent(EV2, type, current) {
  EV2.publish(EVENTS.syncProgress, {
    type,
    current
  });
}
function sendMigrationProgressEvent(EV2, collection, total, current) {
  EV2.publish(EVENTS.migrationProgress, {
    collection,
    total,
    current: current === void 0 ? total : current
  });
}
var CLIENT_ID = "notesnook";
var CHECK_IDS = {
  noteColor: "note:color",
  noteTag: "note:tag",
  noteExport: "note:export",
  vaultAdd: "vault:add",
  notebookAdd: "notebook:add",
  backupEncrypt: "backup:encrypt"
};
var EVENTS = {
  userCheckStatus: "user:checkStatus",
  userSubscriptionUpdated: "user:subscriptionUpdated",
  userEmailConfirmed: "user:emailConfirmed",
  userLoggedIn: "user:loggedIn",
  userLoggedOut: "user:loggedOut",
  userFetched: "user:fetched",
  userSignedUp: "user:signedUp",
  userSessionExpired: "user:sessionExpired",
  databaseSyncRequested: "db:syncRequested",
  syncProgress: "sync:progress",
  syncCompleted: "sync:completed",
  syncItemMerged: "sync:itemMerged",
  syncAborted: "sync:aborted",
  syncCheckStatus: "sync:checkStatus",
  databaseUpdated: "db:updated",
  databaseCollectionInitiated: "db:collectionInitiated",
  appRefreshRequested: "app:refreshRequested",
  migrationProgress: "migration:progress",
  noteRemoved: "note:removed",
  tokenRefreshed: "token:refreshed",
  userUnauthorized: "user:unauthorized",
  downloadCanceled: "file:downloadCanceled",
  uploadCanceled: "file:uploadCanceled",
  fileDownload: "file:download",
  fileUpload: "file:upload",
  fileDownloaded: "file:downloaded",
  fileUploaded: "file:uploaded",
  attachmentDeleted: "attachment:deleted",
  mediaAttachmentDownloaded: "attachments:mediaDownloaded",
  vaultLocked: "vault:locked",
  systemTimeInvalid: "system:invalidTime"
};
var separators = ["-", "/", "."];
var DD = "DD";
var MM = "MM";
var YYYY = "YYYY";
var DATE_FORMATS = [
  ...[
    [DD, MM, YYYY],
    [MM, DD, YYYY],
    [YYYY, MM, DD]
  ].map((item) => separators.map((sep) => item.join(sep))).flat(),
  "MMM D, YYYY"
];
var TIME_FORMATS = ["12-hour", "24-hour"];
var CURRENT_DATABASE_VERSION = 6.1;

// src/database/index.ts
import {
  Migrator,
  Kysely,
  sql,
  OperationNodeTransformer
} from "@streetwriters/kysely";

// src/logger.ts
import {
  LogLevel,
  Logger,
  NoopLogger,
  combineReporters,
  consoleReporter,
  format
} from "@notesnook/logger";
var WEEK = 864e5 * 7;
var NNLogsMigrationProvider = class {
  getMigrations() {
    return __async(this, null, function* () {
      return {
        "1": {
          up(db2) {
            return __async(this, null, function* () {
              yield db2.schema.createTable("logs").addColumn("timestamp", "integer", (c) => c.notNull()).addColumn("message", "text", (c) => c.notNull()).addColumn("level", "integer", (c) => c.notNull()).addColumn("date", "text").addColumn("scope", "text").addColumn("extras", "text").addColumn("elapsed", "integer").execute();
              yield db2.schema.createIndex("log_timestamp_index").on("logs").column("timestamp").execute();
            });
          }
        }
      };
    });
  }
};
var DatabaseLogReporter = class {
  constructor(db2) {
    this.writer = new DatabaseLogWriter(db2);
  }
  write(log) {
    this.writer.push(log);
  }
};
var DatabaseLogWriter = class {
  constructor(db2) {
    this.db = db2;
    this.queue = [];
    this.hasCleared = false;
    setInterval(
      () => {
        setTimeout(() => {
          if (!this.hasCleared) {
            this.hasCleared = true;
            this.rotate();
          }
          this.flush();
        });
      },
      process.env.NODE_ENV === "test" ? 200 : 1e4
    );
  }
  push(message) {
    const date = new Date(message.timestamp);
    message.date = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    this.queue.push(message);
  }
  flush() {
    return __async(this, null, function* () {
      if (this.queue.length === 0) return;
      const queueCopy = this.queue.slice();
      this.queue = [];
      for (const chunk of toChunks(queueCopy, 1e3)) {
        yield this.db.insertInto("logs").values(chunk).execute();
      }
    });
  }
  rotate() {
    return __async(this, null, function* () {
      const range = Date.now() - WEEK;
      yield this.db.deleteFrom("logs").where("timestamp", "<", range).execute();
    });
  }
};
var DatabaseLogManager = class {
  constructor(db2) {
    this.db = db2;
  }
  get() {
    return __async(this, null, function* () {
      const logs = yield this.db.selectFrom("logs").select([
        "timestamp",
        "message",
        "level",
        "scope",
        "extras",
        "elapsed",
        "date"
      ]).execute();
      const groupedLogs = {};
      for (const log of logs) {
        const key = log.date;
        if (!groupedLogs[key]) groupedLogs[key] = [];
        groupedLogs[key].push(log);
      }
      return Object.keys(groupedLogs).sort((a, b) => b.localeCompare(a, void 0, { numeric: true })).map((key) => {
        var _a;
        return {
          key,
          logs: (_a = groupedLogs[key]) == null ? void 0 : _a.sort((a, b) => a.timestamp - b.timestamp)
        };
      });
    });
  }
  clear() {
    return __async(this, null, function* () {
      yield this.db.deleteFrom("logs").execute();
    });
  }
  delete(key) {
    return __async(this, null, function* () {
      yield this.db.deleteFrom("logs").where("date", "==", key).execute();
    });
  }
  close() {
    return this.db.destroy();
  }
};
function initialize(options, disableConsoleLogs) {
  return __async(this, null, function* () {
    const db2 = yield createDatabase("notesnook-logs", __spreadProps(__spreadValues({}, options), {
      migrationProvider: new NNLogsMigrationProvider()
    }));
    const reporters = [new DatabaseLogReporter(db2)];
    if (process.env.NODE_ENV !== "production" && !disableConsoleLogs)
      reporters.push(consoleReporter);
    const instance = new Logger({
      reporter: combineReporters(reporters),
      lastTime: Date.now()
    });
    if (logger instanceof NoopLogger) logger.replaceWith(instance);
    logger = instance;
    logManager = new DatabaseLogManager(db2);
  });
}
var logger = new NoopLogger();
var logManager = void 0;

// src/database/index.ts
var BooleanProperties = /* @__PURE__ */ new Set([
  "compressed",
  "deleted",
  "disabled",
  "favorite",
  "localOnly",
  "locked",
  "migrated",
  "pinned",
  "readonly",
  "remote",
  "synced"
]);
var DataMappers = {
  note: (row) => {
    row.conflicted = row.conflicted === 1;
  },
  reminder: (row) => {
    if (row.selectedDays) row.selectedDays = JSON.parse(row.selectedDays);
  },
  settingitem: (row) => {
    if (row.value && (row.key.startsWith("groupOptions") || row.key.startsWith("toolbarConfig") || row.key.startsWith("sideBarOrder") || row.key.startsWith("sideBarHiddenItems") || row.key.startsWith("profile")))
      row.value = JSON.parse(row.value);
  },
  tiptap: (row) => {
    if (row.conflicted) row.conflicted = JSON.parse(row.conflicted);
    if (row.locked && row.data) row.data = JSON.parse(row.data);
  },
  sessioncontent: (row) => {
    if (row.locked && row.data) row.data = JSON.parse(row.data);
  },
  attachment: (row) => {
    if (row.key) row.key = JSON.parse(row.key);
  },
  vault: (row) => {
    if (row.key) row.key = JSON.parse(row.key);
  }
};
function setupDatabase(db2, options) {
  return __async(this, null, function* () {
    if (options.password)
      yield sql`PRAGMA key = ${sql.ref(options.password)}`.execute(db2);
    yield sql`PRAGMA journal_mode = ${sql.raw(
      options.journalMode || "WAL"
    )}`.execute(db2);
    yield sql`PRAGMA synchronous = ${sql.raw(
      options.synchronous || "normal"
    )}`.execute(db2);
    yield sql`PRAGMA recursive_triggers = true`.execute(db2);
    if (options.pageSize)
      yield sql`PRAGMA page_size = ${sql.raw(
        options.pageSize.toString()
      )}`.execute(db2);
    if (options.tempStore)
      yield sql`PRAGMA temp_store = ${sql.raw(options.tempStore)}`.execute(db2);
    if (options.cacheSize)
      yield sql`PRAGMA cache_size = ${sql.raw(
        options.cacheSize.toString()
      )}`.execute(db2);
    if (options.lockingMode)
      yield sql`PRAGMA locking_mode = ${sql.raw(options.lockingMode)}`.execute(
        db2
      );
  });
}
function initializeDatabase(db2, migrationProvider) {
  return __async(this, null, function* () {
    try {
      const migrator = new Migrator({
        db: db2,
        provider: migrationProvider
      });
      const { error, results } = yield migrator.migrateToLatest();
      if (error)
        throw error instanceof Error ? error : new Error(JSON.stringify(error));
      const errors = (results == null ? void 0 : results.filter((it) => it.status === "Error")) || [];
      if (errors.length > 0)
        throw new Error(
          `failed to execute migrations: ${errors.map((e) => e.migrationName).join(", ")}`
        );
      return db2;
    } catch (e) {
      logger.error(e, "Failed to initialized database.");
      yield db2.destroy();
      throw e;
    }
  });
}
function createDatabase(name, options) {
  return __async(this, null, function* () {
    const db2 = new Kysely({
      // log: (event) => {
      //   if (event.queryDurationMillis > 5)
      //     console.warn(event.query.sql, event.queryDurationMillis);
      // },
      dialect: options.dialect(name, () => __async(this, null, function* () {
        yield db2.connection().execute((db3) => __async(this, null, function* () {
          yield setupDatabase(db3, options);
          yield initializeDatabase(db3, options.migrationProvider);
          if (options.onInit) yield options.onInit(db3);
        }));
      })),
      plugins: [new SqliteBooleanPlugin()]
    });
    if (!options.skipInitialization)
      yield db2.connection().execute((db3) => __async(this, null, function* () {
        yield setupDatabase(db3, options);
        yield initializeDatabase(db3, options.migrationProvider);
        if (options.onInit) yield options.onInit(db3);
      }));
    return db2;
  });
}
function changeDatabasePassword(db2, password) {
  return __async(this, null, function* () {
    yield sql`PRAGMA rekey = "${password ? password : ""}"`.execute(db2);
  });
}
function isFalse(column) {
  return (eb) => eb.or([eb(column, "is", eb.lit(null)), eb(column, "==", eb.lit(0))]);
}
var _transformer;
var SqliteBooleanPlugin = class {
  constructor() {
    __privateAdd(this, _transformer, new SqliteBooleanTransformer());
  }
  transformQuery(args) {
    return __privateGet(this, _transformer).transformNode(args.node);
  }
  transformResult(args) {
    for (let i = 0; i < args.result.rows.length; ++i) {
      const row = args.result.rows[i];
      if (typeof row !== "object") continue;
      if (isDeleted(row)) {
        args.result.rows[i] = {
          deleted: true,
          synced: row.synced,
          dateModified: row.dateModified,
          id: row.id
        };
        continue;
      }
      for (const key in row) {
        if (BooleanProperties.has(key)) {
          row[key] = row[key] === 1;
        }
      }
      const mapper = !!row.type && DataMappers[row.type];
      if (mapper) {
        mapper(row);
      }
    }
    return Promise.resolve(args.result);
  }
};
_transformer = new WeakMap();
var SqliteBooleanTransformer = class extends OperationNodeTransformer {
  transformValue(node) {
    return __spreadProps(__spreadValues({}, super.transformValue(node)), {
      value: this.serialize(node.value)
    });
  }
  transformPrimitiveValueList(node) {
    return __spreadProps(__spreadValues({}, super.transformPrimitiveValueList(node)), {
      values: node.values.map((value) => this.serialize(value))
    });
  }
  serialize(value) {
    return typeof value === "boolean" ? value ? 1 : 0 : typeof value === "object" && value !== null ? JSON.stringify(value) : value;
  }
};

// src/database/sql-collection.ts
import {
  sql as sql2
} from "@streetwriters/kysely";

// src/utils/virtualized-grouping.ts
var CACHE_SIZE = 2;
var VirtualizedGrouping = class {
  constructor(length, batchSize, ids, fetchItems, groupItems, groups) {
    this.length = length;
    this.batchSize = batchSize;
    this.ids = ids;
    this.fetchItems = fetchItems;
    this.groupItems = groupItems;
    this.groups = groups;
    this.cache = /* @__PURE__ */ new Map();
    this.pending = /* @__PURE__ */ new Map();
    this._placeholders = [];
  }
  get placeholders() {
    if (this._placeholders.length !== this.length) {
      this._placeholders = new Array(this.length).fill(true);
    }
    return this._placeholders;
  }
  key(index2) {
    return `${index2}`;
  }
  type(index2) {
    const batchIndex = Math.floor(index2 / this.batchSize);
    const batch = this.cache.get(batchIndex);
    if (!batch) return "item";
    const { items, groups } = batch;
    const itemIndexInBatch = index2 - batchIndex * this.batchSize;
    const group = groups == null ? void 0 : groups.get(itemIndexInBatch);
    return group && !group.hidden && items[itemIndexInBatch] ? "header-item" : "item";
  }
  cacheItem(index2) {
    const batchIndex = Math.floor(index2 / this.batchSize);
    const batch = this.cache.get(batchIndex);
    if (!batch) return;
    const { items, groups, data } = batch;
    const itemIndexInBatch = index2 - batchIndex * this.batchSize;
    const group = groups == null ? void 0 : groups.get(itemIndexInBatch);
    return {
      item: items[itemIndexInBatch],
      group: group && !group.hidden ? group.group : void 0,
      data: data == null ? void 0 : data[itemIndexInBatch]
    };
  }
  item(index2, operate) {
    return __async(this, null, function* () {
      const batchIndex = Math.floor(index2 / this.batchSize);
      const { items, groups, data } = this.cache.get(batchIndex) || (yield this.batchLoader(batchIndex, operate));
      const itemIndexInBatch = index2 - batchIndex * this.batchSize;
      const group = groups == null ? void 0 : groups.get(itemIndexInBatch);
      return {
        item: items[itemIndexInBatch],
        group: group && !group.hidden ? group.group : void 0,
        data: data == null ? void 0 : data[itemIndexInBatch]
      };
    });
  }
  /**
   *
   * @param index
   */
  load(batchIndex, operate) {
    return __async(this, null, function* () {
      var _a;
      const [lastBatchIndex, lastBatch] = lastInMap(this.cache) || [];
      const direction = lastBatchIndex !== void 0 && lastBatchIndex < batchIndex ? "down" : "up";
      const start = batchIndex * this.batchSize;
      const end = start + this.batchSize;
      const { ids, items } = yield this.fetchItems(start, end);
      const groups = (_a = this.groupItems) == null ? void 0 : _a.call(this, items);
      if (items.length > this.batchSize)
        throw new Error("Got more items than the batch size.");
      if (direction === "down") {
        const [, firstGroup] = groups ? firstInMap(groups) : [];
        const group = (lastBatch == null ? void 0 : lastBatch.groups) ? lastInMap(lastBatch.groups)[1] : void 0;
        if (group && firstGroup && group.group.title === firstGroup.group.title)
          firstGroup.hidden = true;
      } else {
        const prevGroups = this.groupItems && start > 0 ? this.groupItems((yield this.fetchItems(start - 1, start)).items) : void 0;
        if (prevGroups && groups) {
          const [, prevGroup] = lastInMap(prevGroups);
          const [, group] = firstInMap(groups);
          if (group && (prevGroup == null ? void 0 : prevGroup.group.title) === (group == null ? void 0 : group.group.title))
            group.hidden = true;
        }
      }
      const batch = {
        items,
        groups,
        data: operate ? yield operate(ids, items) : void 0
      };
      this.cache.set(batchIndex, batch);
      this.clear();
      return batch;
    });
  }
  batchLoader(batch, operate) {
    if (this.pending.has(batch)) return this.pending.get(batch);
    const promise = this.load(batch, operate);
    this.pending.set(batch, promise);
    return promise.finally(() => {
      this.pending.delete(batch);
    });
  }
  clear() {
    if (this.cache.size <= CACHE_SIZE) return;
    for (const [key] of this.cache) {
      this.cache.delete(key);
      if (this.cache.size === CACHE_SIZE) break;
    }
  }
};
function lastInMap(map) {
  let i = 0;
  for (const item of map) {
    if (++i === map.size) return item;
  }
  return [void 0, void 0];
}
function firstInMap(map) {
  let i = 0;
  for (const item of map) {
    if (++i === 1) return item;
  }
  return [void 0, void 0];
}

// src/database/sql-collection.ts
var formats = {
  month: "%Y-%m",
  year: "%Y",
  week: "%Y-%W",
  abc: null,
  default: "%Y-%W",
  none: null
};
var MAX_SQL_PARAMETERS = 200;
var SQLCollection = class {
  constructor(db2, _startTransaction, type, eventManager, sanitizer) {
    this.db = db2;
    this.type = type;
    this.eventManager = eventManager;
    this.sanitizer = sanitizer;
  }
  clear() {
    return __async(this, null, function* () {
      yield this.db().deleteFrom(this.type).execute();
    });
  }
  init() {
    return __async(this, null, function* () {
    });
  }
  upsert(item) {
    return __async(this, null, function* () {
      if (!item.id) throw new Error("The item must contain the id field.");
      if (!item.deleted) item.dateCreated = item.dateCreated || Date.now();
      if (!item.remote) {
        item.dateModified = Date.now();
        item.synced = false;
      }
      delete item.remote;
      if (!this.sanitizer.sanitize(this.type, item)) return;
      yield this.db().replaceInto(this.type).values(item).execute();
      this.eventManager.publish(EVENTS.databaseUpdated, {
        type: "upsert",
        collection: this.type,
        item
      });
    });
  }
  softDelete(ids) {
    return __async(this, null, function* () {
      yield this.db().transaction().execute((tx) => __async(this, null, function* () {
        for (const chunk of toChunks(ids, MAX_SQL_PARAMETERS)) {
          yield tx.replaceInto(this.type).values(
            chunk.map((id) => ({
              id,
              deleted: true,
              dateModified: Date.now(),
              synced: false
            }))
          ).execute();
        }
      }));
      this.eventManager.publish(EVENTS.databaseUpdated, {
        type: "softDelete",
        collection: this.type,
        ids
      });
    });
  }
  delete(ids) {
    return __async(this, null, function* () {
      if (ids.length <= 0) return;
      yield this.db().transaction().execute((tx) => __async(this, null, function* () {
        for (const chunk of toChunks(ids, MAX_SQL_PARAMETERS)) {
          yield tx.deleteFrom(this.type).where("id", "in", chunk).execute();
        }
      }));
      this.eventManager.publish(EVENTS.databaseUpdated, {
        type: "delete",
        collection: this.type,
        ids
      });
    });
  }
  exists(id) {
    return __async(this, null, function* () {
      const { count } = (yield this.db().selectFrom(this.type).select((a) => a.fn.count("id").as("count")).where("id", "==", id).where(isFalse("deleted")).limit(1).executeTakeFirst()) || {};
      return count !== void 0 && count > 0;
    });
  }
  count() {
    return __async(this, null, function* () {
      const { count } = (yield this.db().selectFrom(this.type).select((a) => a.fn.count("id").as("count")).where(isFalse("deleted")).executeTakeFirst()) || {};
      return count || 0;
    });
  }
  get(id) {
    return __async(this, null, function* () {
      const item = yield this.db().selectFrom(this.type).selectAll().where("id", "==", id).executeTakeFirst();
      if (!item || isDeleted(item)) return;
      return item;
    });
  }
  put(items) {
    return __async(this, null, function* () {
      if (items.length <= 0) return [];
      const entries = items.reduce((array, item) => {
        if (!item) return array;
        if (!item.remote) {
          item.synced = false;
        }
        delete item.remote;
        if (!this.sanitizer.sanitize(this.type, item)) return array;
        array.push(item);
        return array;
      }, []);
      if (entries.length <= 0) return [];
      yield this.db().transaction().execute((tx) => __async(this, null, function* () {
        for (const chunk of toChunks(entries, MAX_SQL_PARAMETERS)) {
          yield tx.replaceInto(this.type).values(chunk).execute();
        }
      }));
      return entries;
    });
  }
  update(_0, _1) {
    return __async(this, arguments, function* (ids, partial, options = {}) {
      const { sendEvent = true, modify = true, condition } = options;
      if (!this.sanitizer.sanitize(this.type, partial)) return;
      yield this.db().transaction().execute((tx) => __async(this, null, function* () {
        for (const chunk of toChunks(ids, MAX_SQL_PARAMETERS)) {
          yield tx.updateTable(this.type).where("id", "in", chunk).$if(!!condition, (eb) => eb.where(condition)).set(__spreadProps(__spreadValues({}, partial), {
            dateModified: modify ? Date.now() : void 0,
            synced: partial.synced || false
          })).execute();
        }
      }));
      if (sendEvent) {
        this.eventManager.publish(EVENTS.databaseUpdated, {
          type: "update",
          collection: this.type,
          ids,
          item: partial
        });
      }
    });
  }
  records(ids) {
    return __async(this, null, function* () {
      const results = yield this.db().selectFrom(this.type).selectAll().$if(ids.length > 0, (eb) => eb.where("id", "in", ids)).execute();
      const items = {};
      for (const item of results) {
        items[item.id] = item;
      }
      return items;
    });
  }
  unsyncedCount() {
    return __async(this, null, function* () {
      const { count } = (yield this.db().selectFrom(this.type).select((a) => a.fn.count("id").as("count")).where(isFalse("synced")).$if(
        this.type === "content",
        (eb) => eb.where("conflicted", "is", null)
      ).$if(
        this.type === "notes",
        (eb) => eb.where("conflicted", "is not", true)
      ).$if(
        this.type === "attachments",
        (eb) => eb.where(
          (eb2) => eb2.or([eb2("dateUploaded", ">", 0), eb2("deleted", "==", true)])
        )
      ).executeTakeFirst()) || {};
      return count || 0;
    });
  }
  unsynced(chunkSize, forceSync) {
    return __asyncGenerator(this, null, function* () {
      let lastRowId = null;
      while (true) {
        const rows = yield new __await(this.db().selectFrom(this.type).selectAll().$if(lastRowId != null, (qb) => qb.where("id", ">", lastRowId)).$if(!forceSync, (eb) => eb.where(isFalse("synced"))).$if(
          this.type === "content",
          (eb) => eb.where("conflicted", "is", null)
        ).$if(
          this.type === "notes",
          (eb) => eb.where("conflicted", "is not", true)
        ).$if(
          this.type === "attachments",
          (eb) => eb.where(
            (eb2) => eb2.or([eb2("dateUploaded", ">", 0), eb2("deleted", "==", true)])
          )
        ).orderBy("id").limit(chunkSize).execute());
        if (rows.length === 0) break;
        yield rows;
        lastRowId = rows[rows.length - 1].id;
      }
    });
  }
  stream(chunkSize) {
    return __asyncGenerator(this, null, function* () {
      let lastRow = null;
      while (true) {
        const rows = yield new __await(this.db().selectFrom(this.type).where(isFalse("deleted")).orderBy("dateCreated asc").orderBy("id asc").$if(
          lastRow !== null,
          (qb) => qb.where(
            (eb) => eb.refTuple("dateCreated", "id"),
            ">",
            (eb) => eb.tuple(lastRow.dateCreated, lastRow.id)
          )
        ).selectAll().limit(chunkSize).execute());
        if (rows.length === 0) break;
        for (const row of rows) {
          yield row;
        }
        lastRow = rows[rows.length - 1];
      }
    });
  }
  createFilter(selector, batchSize) {
    return new FilteredSelector(
      this.type,
      this.db().selectFrom(this.type).$call(selector),
      batchSize
    );
  }
};
var FilteredSelector = class {
  constructor(type, filter, batchSize = 500) {
    this.type = type;
    this.batchSize = batchSize;
    this._fields = [];
    this._limit = 0;
    this.filter = filter;
  }
  fields(fields) {
    this._fields = fields;
    return this;
  }
  limit(limit) {
    this._limit = limit;
    return this;
  }
  ids(sortOptions) {
    return __async(this, null, function* () {
      return (yield this.filter.$if(
        !!sortOptions,
        (eb) => eb.$call(this.buildSortExpression(sortOptions))
      ).select("id").execute()).map((i) => i.id);
    });
  }
  items(ids, sortOptions) {
    return __async(this, null, function* () {
      if (ids && !(ids == null ? void 0 : ids.length)) return [];
      return yield this.filter.$if(!!ids && ids.length > 0, (eb) => eb.where("id", "in", ids)).$if(
        !!sortOptions,
        (eb) => eb.$call(this.buildSortExpression(sortOptions))
      ).$if(this._fields.length === 0, (eb) => eb.selectAll()).$if(this._fields.length > 0, (eb) => eb.select(this._fields)).$if(!!this._limit, (eb) => eb.limit(this._limit)).execute();
    });
  }
  records(ids, sortOptions) {
    return __async(this, null, function* () {
      if (ids && !(ids == null ? void 0 : ids.length)) return {};
      const results = yield this.items(ids, sortOptions);
      const items = {};
      for (const item of results) {
        items[item.id] = item;
      }
      if (ids) return Object.fromEntries(ids.map((id) => [id, items[id]]));
      return items;
    });
  }
  has(id) {
    return __async(this, null, function* () {
      const { count } = (yield this.filter.where("id", "==", id).limit(1).select((a) => a.fn.count("id").as("count")).executeTakeFirst()) || {};
      return count !== void 0 && count > 0;
    });
  }
  count() {
    return __async(this, null, function* () {
      const { count } = (yield this.filter.select((a) => a.fn.count("id").as("count")).executeTakeFirst()) || {};
      return count || 0;
    });
  }
  find(filter) {
    return __async(this, null, function* () {
      const item = yield this.filter.where(filter).limit(1).$if(this._fields.length === 0, (eb) => eb.selectAll()).$if(this._fields.length > 0, (eb) => eb.select(this._fields)).executeTakeFirst();
      return item;
    });
  }
  where(expr) {
    this.filter = this.filter.where(expr);
    return this;
  }
  map(fn) {
    return __asyncGenerator(this, null, function* () {
      try {
        for (var iter = __forAwait(this), more, temp, error; more = !(temp = yield new __await(iter.next())).done; more = false) {
          const item = temp.value;
          yield fn(item);
        }
      } catch (temp) {
        error = [temp];
      } finally {
        try {
          more && (temp = iter.return) && (yield new __await(temp.call(iter)));
        } finally {
          if (error)
            throw error[0];
        }
      }
    });
  }
  grouped(options) {
    return __async(this, null, function* () {
      sanitizeSortOptions(this.type, options);
      const count = yield this.count();
      return new VirtualizedGrouping(
        count,
        this.batchSize,
        () => this.ids(options),
        (start, end) => __async(this, null, function* () {
          const items = yield this.filter.$call(this.buildSortExpression(options)).offset(start).limit(end - start).selectAll().execute();
          return {
            ids: items.map((i) => i.id),
            items
          };
        }),
        (items) => groupArray(items, createKeySelector(options)),
        () => this.groups(options)
      );
    });
  }
  groups(options) {
    return __async(this, null, function* () {
      sanitizeSortOptions(this.type, options);
      const fields = ["id", "type"];
      if (this.type === "notes") fields.push("notes.pinned", "notes.conflicted");
      else if (this.type === "notebooks") fields.push("notebooks.pinned");
      else if (this.type === "attachments" && options.groupBy === "abc")
        fields.push("attachments.filename");
      else if (this.type === "reminders" || options.sortBy === "dueDate") {
        fields.push(
          "reminders.mode",
          "reminders.snoozeUntil",
          "reminders.disabled",
          "reminders.date",
          createUpcomingReminderTimeQuery().as("dueDate")
        );
      }
      if (options.groupBy === "abc") fields.push("title");
      else if (options.sortBy === "title" && options.groupBy !== "none")
        fields.push("dateCreated");
      else if (options.sortBy !== "dueDate") fields.push(options.sortBy);
      return Array.from(
        groupArray(
          yield this.filter.select(fields).$call(this.buildSortExpression(options, true)).execute(),
          createKeySelector(options)
        ).values()
      );
    });
  }
  sorted(options) {
    return __async(this, null, function* () {
      const count = yield this.count();
      return new VirtualizedGrouping(
        count,
        this.batchSize,
        () => this.ids(options),
        (start, end) => __async(this, null, function* () {
          const items = yield this.filter.$call(this.buildSortExpression(options)).offset(start).limit(end - start).selectAll().execute();
          return {
            ids: items.map((i) => i.id),
            items
          };
        })
      );
    });
  }
  [Symbol.asyncIterator]() {
    return __asyncGenerator(this, null, function* () {
      let lastRow = null;
      const fields = this._fields.slice();
      if (fields.length > 0) {
        if (!fields.find((f) => f.includes(".dateCreated")))
          fields.push("dateCreated");
        if (!fields.find((f) => f.includes(".id"))) fields.push("id");
      }
      while (true) {
        const rows = yield new __await(this.filter.orderBy("dateCreated asc").orderBy("id asc").$if(
          lastRow !== null,
          (qb) => qb.where(
            (eb) => eb.refTuple("dateCreated", "id"),
            ">",
            (eb) => eb.tuple(lastRow.dateCreated, lastRow.id)
          )
        ).limit(this.batchSize).$if(fields.length === 0, (eb) => eb.selectAll()).$if(fields.length > 0, (eb) => eb.select(fields)).execute());
        if (rows.length === 0) break;
        for (const row of rows) {
          yield row;
        }
        lastRow = rows[rows.length - 1];
      }
    });
  }
  buildSortExpression(options, hasDueDate) {
    sanitizeSortOptions(this.type, options);
    const sortBy = /* @__PURE__ */ new Set();
    if (isGroupOptions(options)) {
      if (options.groupBy === "abc") sortBy.add("title");
      else if (options.sortBy === "title" && options.groupBy !== "none")
        sortBy.add("dateCreated");
    }
    sortBy.add(options.sortBy);
    return (qb) => {
      if (this.type === "notes")
        qb = qb.orderBy(sql2`IFNULL(conflicted, 0) desc`);
      if (this.type === "notes" || this.type === "notebooks")
        qb = qb.orderBy(sql2`IFNULL(pinned, 0) desc`);
      if (this.type === "reminders")
        qb = qb.orderBy(
          (qb2) => qb2.parens(createIsReminderActiveQuery()),
          "desc"
        );
      for (const item of sortBy) {
        if (item === "title") {
          qb = qb.orderBy(
            options.sortBy !== "title" ? sql2`substring(ltrim(title, ' \u00a0\r\n\t\v'), 1, 1) COLLATE NOCASE` : sql2`ltrim(title, ' \u00a0\r\n\t\v') COLLATE NOCASE`,
            options.sortDirection
          );
        } else {
          const timeFormat = isGroupOptions(options) ? formats[options.groupBy] : null;
          if (!timeFormat || isSortByDate(options)) {
            if (item === "dueDate") {
              if (hasDueDate)
                qb = qb.orderBy(item, options.sortDirection);
              else
                qb = qb.orderBy(
                  (qb2) => qb2.parens(createUpcomingReminderTimeQuery()),
                  options.sortDirection
                );
            } else qb = qb.orderBy(item, options.sortDirection);
            continue;
          }
          qb = qb.orderBy(
            sql2`strftime('${sql2.raw(timeFormat)}', ${sql2.raw(
              item
            )} / 1000, 'unixepoch', 'localtime')`,
            options.sortDirection
          );
        }
      }
      return qb;
    };
  }
};
function isGroupOptions(options) {
  return "groupBy" in options;
}
function isSortByDate(options) {
  return options.sortBy === "dateCreated" || options.sortBy === "dateEdited" || options.sortBy === "dateDeleted" || options.sortBy === "dateModified" || options.sortBy === "dateUploaded" || options.sortBy === "dueDate";
}
var BASE_FIELDS = ["dateCreated", "dateModified"];
var VALID_SORT_OPTIONS = {
  reminders: ["dueDate", "title"],
  tags: ["title"],
  attachments: ["filename", "dateUploaded", "size"],
  colors: ["title"],
  notebooks: ["title", "dateDeleted", "dateEdited"],
  notes: ["title", "dateDeleted", "dateEdited"],
  content: [],
  notehistory: [],
  relations: [],
  sessioncontent: [],
  settings: [],
  shortcuts: [],
  vaults: []
};
function sanitizeSortOptions(type, options) {
  const validFields = [...VALID_SORT_OPTIONS[type], ...BASE_FIELDS];
  if (!validFields.includes(options.sortBy)) options.sortBy = validFields[0];
  return options;
}

// src/collections/reminders.ts
import { sql as sql3 } from "@streetwriters/kysely";
dayjs2.extend(isTomorrow);
dayjs2.extend(isSameOrBefore);
dayjs2.extend(isYesterday);
dayjs2.extend(isToday);
var Reminders = class {
  constructor(db2) {
    this.db = db2;
    this.name = "reminders";
    this.collection = new SQLCollection(
      db2.sql,
      db2.transaction,
      "reminders",
      db2.eventManager,
      db2.sanitizer
    );
  }
  init() {
    return __async(this, null, function* () {
      yield this.collection.init();
    });
  }
  add(reminder) {
    return __async(this, null, function* () {
      if (!reminder) return;
      if (reminder.remote)
        throw new Error("Please use db.reminders.merge to merge reminders.");
      const id = reminder.id || getId();
      const oldReminder = yield this.collection.get(id);
      reminder = __spreadValues(__spreadValues({}, oldReminder), reminder);
      if (!reminder.date || !reminder.title)
        throw new Error(`date and title are required in a reminder.`);
      yield this.collection.upsert({
        id,
        type: "reminder",
        dateCreated: reminder.dateCreated || Date.now(),
        dateModified: reminder.dateModified || Date.now(),
        date: reminder.date,
        description: reminder.description,
        mode: reminder.mode || "once",
        priority: reminder.priority || "vibrate",
        recurringMode: reminder.recurringMode,
        selectedDays: reminder.selectedDays || [],
        title: reminder.title,
        localOnly: reminder.localOnly,
        disabled: reminder.disabled,
        snoozeUntil: reminder.snoozeUntil
      });
      return id;
    });
  }
  // get raw() {
  //   return this.collection.raw();
  // }
  get all() {
    var _a;
    return this.collection.createFilter(
      (qb) => qb.where(isFalse("deleted")),
      (_a = this.db.options) == null ? void 0 : _a.batchSize
    );
  }
  exists(itemId) {
    return this.collection.exists(itemId);
  }
  reminder(id) {
    return this.collection.get(id);
  }
  remove(...reminderIds) {
    return __async(this, null, function* () {
      yield this.collection.softDelete(reminderIds);
    });
  }
};
function formatReminderTime(reminder, short = false, options = {
  timeFormat: "12-hour",
  dateFormat: "DD-MM-YYYY"
}) {
  const { date } = reminder;
  let time = date;
  let tag = "";
  let text = "";
  if (reminder.mode === "permanent") return `Ongoing`;
  if (reminder.snoozeUntil && reminder.snoozeUntil > Date.now()) {
    return `Snoozed until ${formatDate(reminder.snoozeUntil, {
      timeFormat: options.timeFormat,
      type: "time"
    })}`;
  }
  if (reminder.mode === "repeat") {
    time = getUpcomingReminderTime(reminder);
  }
  const formattedTime = formatDate(time, {
    timeFormat: options.timeFormat,
    type: "time"
  });
  const formattedDateTime = formatDate(time, {
    dateFormat: `ddd, ${options.dateFormat}`,
    timeFormat: options.timeFormat,
    type: "date-time"
  });
  if (dayjs2(time).isTomorrow()) {
    tag = "Upcoming";
    text = `Tomorrow, ${formattedTime}`;
  } else if (dayjs2(time).isYesterday()) {
    tag = "Last";
    text = `Yesterday, ${formattedTime}`;
  } else {
    const isPast = dayjs2(time).isSameOrBefore(dayjs2());
    tag = isPast ? "Last" : "Upcoming";
    if (dayjs2(time).isToday()) {
      text = `Today, ${formattedTime}`;
    } else {
      text = formattedDateTime;
    }
  }
  return short ? text : `${tag}: ${text}`;
}
function isReminderToday(reminder) {
  const { date } = reminder;
  let time = date;
  if (reminder.mode === "permanent") return true;
  if (reminder.mode === "repeat") {
    time = getUpcomingReminderTime(reminder);
  }
  return dayjs2(time).isToday();
}
function getUpcomingReminderTime(reminder) {
  if (reminder.mode === "once") return reminder.date;
  const isDay = reminder.recurringMode === "day";
  const isWeek = reminder.recurringMode === "week";
  const isMonth = reminder.recurringMode === "month";
  const isYear = reminder.recurringMode === "year";
  const time = dayjs2(reminder.date);
  const now = dayjs2();
  const relativeTime = isYear ? now.clone().hour(time.hour()).minute(time.minute()).month(time.month()).date(time.date()) : now.clone().hour(time.hour()).minute(time.minute());
  const isPast = relativeTime.isSameOrBefore(now);
  if (isYear) {
    if (isPast) return relativeTime.add(1, "year").valueOf();
    else return relativeTime.valueOf();
  }
  if (isDay) {
    if (isPast) return relativeTime.add(1, "day").valueOf();
    else return relativeTime.valueOf();
  }
  if (!reminder.selectedDays || !reminder.selectedDays.length)
    return relativeTime.valueOf();
  const sorted = reminder.selectedDays.sort((a, b) => a - b);
  const lastSelectedDay = sorted[sorted.length - 1];
  if (isWeek) {
    if (now.day() > lastSelectedDay || now.day() === lastSelectedDay && isPast)
      return relativeTime.day(sorted[0]).add(1, "week").valueOf();
    else {
      for (const selectedDay of sorted) {
        if (selectedDay > now.day() || selectedDay === now.day() && !isPast)
          return relativeTime.day(selectedDay).valueOf();
      }
    }
  } else if (isMonth) {
    if (now.date() > lastSelectedDay || now.date() === lastSelectedDay && isPast)
      return relativeTime.date(sorted[0]).add(1, "month").valueOf();
    else {
      for (const selectedDay of sorted) {
        if (selectedDay > now.date() || now.date() === selectedDay && !isPast)
          return relativeTime.date(selectedDay).valueOf();
      }
    }
  }
  return relativeTime.valueOf();
}
function getUpcomingReminder(reminders) {
  const sorted = reminders.sort((a, b) => {
    const d1 = a.mode === "repeat" ? getUpcomingReminderTime(a) : a.date;
    const d2 = b.mode === "repeat" ? getUpcomingReminderTime(b) : b.date;
    return !d1 || !d2 ? 0 : d2 - d1;
  });
  return sorted[0];
}
function isReminderActive(reminder) {
  return !reminder.disabled && (reminder.mode !== "once" || reminder.date > Date.now() || !!reminder.snoozeUntil && reminder.snoozeUntil > Date.now());
}
function createUpcomingReminderTimeQuery(unix = "now") {
  const time = sql3`time(date / 1000, 'unixepoch', 'localtime')`;
  const dateNow = sql3`date(${unix})`;
  const dateTime = sql3`datetime(${dateNow} || ${time})`;
  const dateTimeNow = sql3`datetime(${unix})`;
  const weekDayNow = sql3`CAST(strftime('%w', ${dateNow}) AS INTEGER)`;
  const monthDayNow = sql3`CAST(strftime('%d', ${dateNow}) AS INTEGER)`;
  const lastSelectedDay = sql3`(SELECT MAX(value) FROM json_each(selectedDays))`;
  const monthDate = sql3`strftime('%m-%d%H:%M', date / 1000, 'unixepoch', 'localtime')`;
  return sql3`CASE 
        WHEN mode = 'once' THEN date / 1000
        WHEN recurringMode = 'year' THEN
            strftime('%s',
                strftime('%Y-', ${dateNow}) || ${monthDate},
                IIF(datetime(strftime('%Y-', ${dateNow}) || ${monthDate}) <= ${dateTimeNow}, '+1 year', '+0 year'),
                'utc'
            )
        WHEN recurringMode = 'day' THEN
            strftime('%s',
                ${dateNow} || ${time}, 
                IIF(${dateTime} <= ${dateTimeNow}, '+1 day', '+0 day'),
                'utc'
            )
        WHEN recurringMode = 'week' AND selectedDays IS NOT NULL AND json_array_length(selectedDays) > 0 THEN 
            CASE
                WHEN ${weekDayNow} > ${lastSelectedDay}
                OR (${weekDayNow} == ${lastSelectedDay} AND ${dateTime} <= ${dateTimeNow})
                THEN
                    strftime('%s', datetime(${dateNow}, ${time}, '+1 day', 'weekday ' || json_extract(selectedDays, '$[0]'), 'utc'))
                ELSE
                    strftime('%s', datetime(${dateNow}, ${time}, 'weekday ' || (SELECT value FROM json_each(selectedDays) WHERE value > ${weekDayNow} OR (value == ${weekDayNow} AND ${dateTime} > ${dateTimeNow})), 'utc'))
            END
        WHEN recurringMode = 'month' AND selectedDays IS NOT NULL AND json_array_length(selectedDays) > 0 THEN
            CASE
                WHEN ${monthDayNow} > ${lastSelectedDay}
                OR (${monthDayNow} == ${lastSelectedDay} AND datetime(${dateNow} || ${time}) <= ${dateTimeNow})
                THEN
                    strftime('%s', strftime('%Y-%m-', ${dateNow}) || printf('%02d', json_extract(selectedDays, '$[0]')) || ${time}, '+1 month', 'utc')
                ELSE strftime('%s', strftime('%Y-%m-', ${dateNow}) || (SELECT printf('%02d', value) FROM json_each(selectedDays) WHERE value > ${monthDayNow} OR (value == ${monthDayNow} AND ${dateTime} > ${dateTimeNow})) || ${time}, 'utc')
            END
        ELSE strftime('%s', ${dateNow} || ${time}, 'utc')
    END * 1000
`.$castTo();
}
function createIsReminderActiveQuery(now = "now") {
  return sql3`IIF(
    (disabled IS NULL OR disabled = 0)
    AND (mode != 'once'
      OR datetime(date / 1000, 'unixepoch', 'localtime') > datetime(${now}) 
      OR (snoozeUntil IS NOT NULL
        AND datetime(snoozeUntil / 1000, 'unixepoch', 'localtime') > datetime(${now}))
    ), 1, 0)`.$castTo();
}

// src/utils/grouping.ts
var getSortValue = (options, item) => {
  if ((options == null ? void 0 : options.sortBy) === "dateDeleted" && "dateDeleted" in item && item.dateDeleted)
    return item.dateDeleted;
  else if ((options == null ? void 0 : options.sortBy) === "dateEdited" && "dateEdited" in item && item.dateEdited)
    return item.dateEdited;
  return item.dateCreated || 0;
};
function getSortSelectors(options) {
  if (options.sortBy === "title")
    return {
      asc: (a, b) => getTitle(a).localeCompare(getTitle(b), void 0, { numeric: true }),
      desc: (a, b) => getTitle(b).localeCompare(getTitle(a), void 0, { numeric: true })
    };
  return {
    asc: (a, b) => getSortValue(options, a) - getSortValue(options, b),
    desc: (a, b) => getSortValue(options, b) - getSortValue(options, a)
  };
}
var MILLISECONDS_IN_DAY = 1e3 * 60 * 60 * 24;
var MILLISECONDS_IN_WEEK = MILLISECONDS_IN_DAY * 7;
function createKeySelector(options = {
  groupBy: "default",
  sortBy: "dateEdited",
  sortDirection: "desc"
}) {
  return (item) => {
    if ("pinned" in item && item.pinned) return "Pinned";
    else if ("conflicted" in item && item.conflicted) return "Conflicted";
    const date = /* @__PURE__ */ new Date();
    if (item.type === "reminder")
      return isReminderActive(item) ? "Active" : "Inactive";
    else if (options.groupBy === "abc")
      return getFirstCharacter(getTitle(item));
    else {
      const value = getSortValue(options, item) || 0;
      switch (options.groupBy) {
        case "none":
          return "All";
        case "month":
          date.setTime(value);
          return `${MONTHS_FULL[date.getMonth()]} ${date.getFullYear()}`;
        case "week":
          return getWeekGroupFromTimestamp(value);
        case "year":
          date.setTime(value);
          return date.getFullYear().toString();
        case "default":
        default: {
          return value > date.getTime() - MILLISECONDS_IN_WEEK ? "Recent" : value > date.getTime() - MILLISECONDS_IN_WEEK * 2 ? "Last week" : "Older";
        }
      }
    }
  };
}
function groupArray(items, keySelector) {
  const groups = /* @__PURE__ */ new Map();
  for (let i = 0; i < items.length; ++i) {
    const item = items[i];
    const groupTitle = keySelector(item);
    const group = groups.get(groupTitle);
    if (typeof group === "undefined")
      groups.set(groupTitle, [
        i,
        {
          index: i,
          group: { id: groupTitle, title: groupTitle, type: "header" }
        }
      ]);
  }
  return new Map(groups.values());
}
function getFirstCharacter(str) {
  if (!str) return "-";
  str = str.trim();
  if (str.length <= 0) return "-";
  return str[0].toUpperCase();
}
function getTitle(item) {
  return ("filename" in item ? item.filename : item.title) || "Unknown";
}

// src/utils/html-diff.ts
import { Parser } from "htmlparser2";
var ALLOWED_ATTRIBUTES = ["href", "src", "data-hash"];
function isHTMLEqual(one, two) {
  if (typeof one !== "string" || typeof two !== "string") return false;
  return toDiffable(one) === toDiffable(two);
}
function toDiffable(html) {
  let text = "";
  const parser = new Parser(
    {
      ontext: (data) => text += data.trim(),
      onopentag: (_name, attr) => {
        for (const key of ALLOWED_ATTRIBUTES) {
          const value = attr[key];
          if (!value) continue;
          text += value.trim();
        }
      }
    },
    {
      lowerCaseTags: false
      // parseAttributes: true
    }
  );
  parser.end(html);
  return text;
}

// src/utils/html-parser.ts
import { decodeHTML5 } from "entities";
import { Parser as Parser2 } from "htmlparser2";
var parseHTML = (input) => "DOMParser" in globalThis ? new globalThis.DOMParser().parseFromString(
  wrapIntoHTMLDocument(input),
  "text/html"
) : null;
function getDummyDocument() {
  const doc = parseHTML("<div></div>");
  return doc;
}
function getInnerText(element) {
  return decodeHTML5(element.textContent || element.innerText);
}
function wrapIntoHTMLDocument(input) {
  if (typeof input !== "string") return input;
  if (input.includes("<body>")) return input;
  return `<!doctype html><html lang="en"><head><title>Document Fragment</title></head><body>${input}</body></html>`;
}
function extractFirstParagraph(html) {
  let text = "";
  let start = false;
  const parser = new Parser2(
    {
      onopentag: (name) => {
        if (name === "p") start = true;
      },
      onclosetag: (name) => {
        if (name === "p") {
          start = false;
          parser.pause();
          parser.reset();
        }
      },
      ontext: (data) => {
        if (start) text += data;
      }
    },
    {
      lowerCaseTags: false,
      decodeEntities: true
    }
  );
  parser.end(html);
  return text;
}
var HTMLParser = class {
  constructor(options = {}) {
    const { ontag } = options;
    this.parser = new Parser2(
      {
        onopentag: (name, attr) => ontag && ontag(name, attr, {
          start: this.parser.startIndex,
          end: this.parser.endIndex
        })
      },
      {
        recognizeSelfClosing: true,
        xmlMode: false,
        decodeEntities: false,
        lowerCaseAttributeNames: false,
        lowerCaseTags: false,
        recognizeCDATA: false
      }
    );
  }
  parse(html) {
    this.parser.end(html);
    this.parser.reset();
  }
};

// src/utils/html-rewriter.ts
import { Parser as Parser3 } from "htmlparser2";
var HTMLRewriter = class {
  constructor(options = {}) {
    this.transformed = "";
    this.currentTag = null;
    this.ignoreIndex = null;
    const { ontag } = options;
    this.parser = new Parser3(
      {
        onreset: () => {
          this.transformed = "";
        },
        oncomment: () => this.write("<!--"),
        oncommentend: () => this.write("-->"),
        onopentag: (name, attr) => {
          if (this.ignoreIndex !== null) {
            this.ignoreIndex++;
            return;
          }
          this.closeTag();
          if (ontag) {
            const result = ontag(name, attr, {
              start: this.parser.startIndex,
              end: this.parser.endIndex
            });
            if (result === false) {
              this.ignoreIndex = 0;
              return;
            } else if (result) {
              name = result.name;
              attr = result.attr;
            }
          }
          this.write(`<${name}`);
          if (attr) {
            for (const key in attr) {
              if (!key) continue;
              this.write(` ${key}="${attr[key]}"`);
            }
          }
          this.currentTag = name;
        },
        onclosetag: (name, isImplied) => {
          if (this.ignoreIndex === 0) {
            this.ignoreIndex = null;
            return;
          }
          if (this.ignoreIndex !== null) {
            this.ignoreIndex--;
            return;
          }
          if (!isImplied) this.closeTag();
          this.write(isImplied ? "/>" : `</${name}>`);
          if (this.currentTag) {
            this.currentTag = null;
          }
        },
        ontext: (data) => {
          if (this.ignoreIndex !== null) {
            return;
          }
          this.closeTag();
          this.write(data);
        }
      },
      {
        recognizeSelfClosing: true,
        xmlMode: false,
        decodeEntities: false,
        lowerCaseAttributeNames: false,
        lowerCaseTags: false,
        recognizeCDATA: false
      }
    );
  }
  /**
   * @private
   */
  closeTag() {
    if (this.currentTag) {
      this.write(">");
      this.currentTag = null;
    }
  }
  transform(html) {
    this.parser.end(html);
    return this.transformed;
  }
  end() {
    this.parser.reset();
  }
  write(html) {
    this.transformed += html;
  }
};

// src/utils/http.ts
function get(url, token) {
  return request(url, "GET", token);
}
function deleteRequest(url, token) {
  return request(url, "DELETE", token);
}
function patch(url, data, token) {
  return bodyRequest(url, transformFormData(data), token, "PATCH");
}
patch.json = function(url, data, token) {
  return bodyRequest(
    url,
    transformJson(data),
    token,
    "PATCH",
    "application/json"
  );
};
function post(url, data, token) {
  return bodyRequest(url, transformFormData(data), token, "POST");
}
post.json = function(url, data, token) {
  return bodyRequest(
    url,
    transformJson(data),
    token,
    "POST",
    "application/json"
  );
};
var http_default = {
  get,
  post,
  delete: deleteRequest,
  patch
};
function request(url, method, token) {
  return __async(this, null, function* () {
    return handleResponse(
      yield fetchWrapped(url, {
        method,
        headers: getHeaders(token)
      })
    );
  });
}
function bodyRequest(url, data, token, method, contentType = "application/x-www-form-urlencoded") {
  return __async(this, null, function* () {
    return handleResponse(
      yield fetchWrapped(url, {
        method,
        body: data,
        headers: __spreadProps(__spreadValues({}, getHeaders(token)), {
          "Content-Type": contentType
        })
      })
    );
  });
}
function errorTransformer(errorJson) {
  let errorMessage = "Unknown error.";
  let errorCode = "unknown";
  if (!errorJson.error && !errorJson.errors && !errorJson.error_description)
    return { description: errorMessage, code: errorCode, data: {} };
  const { error, error_description, errors, data } = errorJson;
  if (errors) {
    errorMessage = errors.join("\n");
  }
  outer: switch (error) {
    case "invalid_grant": {
      switch (error_description) {
        case "invalid_username_or_password":
          errorMessage = "Username or password incorrect.";
          errorCode = error_description;
          break outer;
        default:
          errorMessage = error_description || error;
          errorCode = error || "invalid_grant";
          break outer;
      }
    }
    default:
      errorMessage = error_description || error || errorMessage;
      errorCode = error || errorCode;
      break;
  }
  return {
    description: errorMessage,
    code: errorCode,
    data: data ? JSON.parse(data) : void 0
  };
}
function fetchWrapped(input, init) {
  return __async(this, null, function* () {
    try {
      const response = yield fetch(input, init);
      return response;
    } catch (e) {
      const host = extractHostname(input);
      const serverName = getServerNameFromHost(host);
      if (serverName)
        throw new Error(
          `${serverName} is not responding. Please check your internet connection. If the problem persists, feel free email us at support@streetwriters.co. (Reference error: ${e.message})`
        );
      throw e;
    }
  });
}
function handleResponse(response) {
  return __async(this, null, function* () {
    try {
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.includes("application/json")) {
        const json = yield response.json();
        if (response.ok) {
          return json;
        }
        throw new RequestError(errorTransformer(json));
      } else {
        if (response.status === 429)
          throw new Error("You are being rate limited.");
        if (response.ok) return yield response.text();
        else if (response.status === 401) {
          EV.publish(EVENTS.userUnauthorized, response.url);
          throw new Error("Unauthorized.");
        } else
          throw new Error(
            `Request failed with status code: ${response.status} ${response.statusText}.`
          );
      }
    } catch (e) {
      logger.error(e, "Error while sending request:", {
        url: response.url
      });
      throw e;
    }
  });
}
var RequestError = class extends Error {
  constructor(error) {
    super(error.description);
    this.code = error.code;
    this.data = error.data;
  }
};
function getHeaders(token) {
  return token ? { Authorization: "Bearer " + token } : void 0;
}
function transformJson(data) {
  return JSON.stringify(data);
}
function transformFormData(data) {
  if (data) {
    return Object.entries(data).map(
      ([key, value]) => value ? `${encodeURIComponent(key)}=${value ? encodeURIComponent(value) : ""}` : ""
    ).join("&");
  }
}

// src/utils/query-transformer.ts
function escapeSQLString(str) {
  if (str.startsWith('"') && str.endsWith('"')) {
    const innerStr = str.slice(1, -1).replace(/"/g, '""');
    return `"${innerStr}"`;
  }
  return str.replace(/"/g, '""');
}
function tokenize(query) {
  const tokens = [];
  let buffer = "";
  let isQuoted = false;
  for (let i = 0; i < query.length; ++i) {
    const char = query[i];
    if (char === '"') {
      isQuoted = !isQuoted;
    }
    if (char === " " && !isQuoted) {
      if (buffer.length > 0) {
        tokens.push(buffer);
        buffer = "";
      }
    } else {
      buffer += char;
    }
  }
  if (buffer.length > 0) tokens.push(buffer);
  return tokens;
}
function parseTokens(tokens) {
  const ast = { type: "query", children: [] };
  let currentPhrase = [];
  for (const token of tokens) {
    if (token === "AND" || token === "OR" || token === "NOT") {
      if (currentPhrase.length > 0) {
        ast.children.push({ type: "phrase", value: currentPhrase });
        currentPhrase = [];
      }
      ast.children.push({ type: token });
    } else {
      currentPhrase.push(token);
    }
  }
  if (currentPhrase.length > 0) {
    ast.children.push({ type: "phrase", value: currentPhrase });
  }
  return ast;
}
function transformAST(ast) {
  const transformedAST = __spreadProps(__spreadValues({}, ast), { children: [] });
  let lastWasPhrase = false;
  for (let i = 0; i < ast.children.length; i++) {
    const child = ast.children[i];
    if (child.type === "phrase") {
      if (lastWasPhrase) {
        transformedAST.children.push({ type: "AND" });
      }
      transformedAST.children.push({
        type: "phrase",
        value: child.value
      });
      lastWasPhrase = true;
    } else if (child.type === "AND" || child.type === "OR" || child.type === "NOT") {
      if (lastWasPhrase && i + 1 < ast.children.length && ast.children[i + 1].type === "phrase") {
        transformedAST.children.push(child);
        lastWasPhrase = false;
      }
    }
  }
  return transformedAST;
}
function generateSQL(ast) {
  return ast.children.map((child) => {
    if (child.type === "phrase") {
      return `"${escapeSQLString(child.value.join(" "))}"`;
    }
    if (child.type === "AND" || child.type === "OR" || child.type === "NOT") {
      return child.type;
    }
    return "";
  }).join(" ");
}
function transformQuery(query) {
  return generateSQL(transformAST(parseTokens(tokenize(query))));
}

// src/utils/queue-value.ts
var _counter;
var QueueValue = class {
  constructor(value, destructor) {
    this.value = value;
    this.destructor = destructor;
    __privateAdd(this, _counter);
    __privateSet(this, _counter, 0);
  }
  use() {
    __privateWrapper(this, _counter)._++;
    return this.value;
  }
  discard() {
    __privateWrapper(this, _counter)._--;
    if (__privateGet(this, _counter) === 0) this.destructor();
  }
};
_counter = new WeakMap();

// src/utils/set.ts
var SetManipulator = class {
  constructor() {
  }
  // Processes a histogram consructed from two arrays, 'a' and 'b'.
  // This function is used generically by the below set operation
  // methods, a.k.a, 'evaluators', to return some subset of
  // a set union, based on frequencies in the histogram.
  process(a, b, key = (item) => String(item), evaluator) {
    const hist = {};
    const out = [];
    let ukey;
    a.forEach((value) => {
      ukey = key(value);
      if (!hist[ukey]) {
        hist[ukey] = { value, frequency: 1 };
      }
    });
    b.forEach((value) => {
      ukey = key(value);
      if (hist[ukey]) {
        if (hist[ukey].frequency === 1) hist[ukey].frequency = 3;
      } else hist[ukey] = { value, frequency: 2 };
    });
    if (evaluator) {
      for (const key2 in hist) {
        if (evaluator(hist[key2].frequency)) out.push(hist[key2].value);
      }
      return out;
    }
    return hist;
  }
  // Join two sets together.
  // Set.union([1, 2, 2], [2, 3]) => [1, 2, 3]
  union(a, b, key) {
    return this.process(a, b, key, () => true);
  }
  // Return items common to both sets.
  // Set.intersection([1, 1, 2], [2, 2, 3]) => [2]
  intersection(a, b, key) {
    return this.process(a, b, key, (freq) => freq === 3);
  }
  // Symmetric difference. Items from either set that
  // are not in both sets.
  // Set.difference([1, 1, 2], [2, 3, 3]) => [1, 3]
  difference(a, b, key) {
    return this.process(a, b, key, (freq) => freq < 3);
  }
  // Relative complement. Items from 'a' which are
  // not also in 'b'.
  // Set.complement([1, 2, 2], [2, 2, 3]) => [3]
  complement(a, b, key) {
    return this.process(a, b, key, (freq) => freq === 1);
  }
  // Returns true if both sets are equivalent, false otherwise.
  // Set.equals([1, 1, 2], [1, 2, 2]) => true
  // Set.equals([1, 1, 2], [1, 2, 3]) => false
  equals(a, b, key) {
    let max = 0;
    let min = Math.pow(2, 53);
    const hist = this.process(a, b, key);
    for (const key2 in hist) {
      max = Math.max(max, hist[key2].frequency);
      min = Math.min(min, hist[key2].frequency);
    }
    return min === 3 && max === 3;
  }
};
var set = new SetManipulator();

// src/utils/title-format.ts
var NEWLINE_STRIP_REGEX = /[\r\n\t\v]+/gm;
var DATE_REGEX = /\$date\$/g;
var COUNT_REGEX = /\$count\$/g;
var TIME_REGEX = /\$time\$/g;
var HEADLINE_REGEX = /\$headline\$/g;
var TIMESTAMP_REGEX = /\$timestamp\$/g;
var DATE_TIME_STRIP_REGEX = /[\\\-: ]/g;
function formatTitle(titleFormat, dateFormat, timeFormat, headline = "", totalNotes = 0) {
  const date = formatDate(Date.now(), {
    dateFormat,
    type: "date"
  });
  const time = formatDate(Date.now(), {
    timeFormat,
    type: "time"
  });
  const timestamp = `${date}${time}`.replace(DATE_TIME_STRIP_REGEX, "");
  return titleFormat.replace(NEWLINE_STRIP_REGEX, " ").replace(DATE_REGEX, date).replace(TIME_REGEX, time).replace(HEADLINE_REGEX, headline || "").replace(TIMESTAMP_REGEX, timestamp).replace(COUNT_REGEX, `${totalNotes + 1}`);
}

// src/utils/crypto.ts
var Crypto = class {
  constructor(storage) {
    this.storage = storage;
  }
  generateRandomKey() {
    return __async(this, null, function* () {
      const passwordBytes = randomBytes(124);
      const password = passwordBytes.toString("base64");
      return yield this.storage().generateCryptoKey(password);
    });
  }
};
function isCipher(item) {
  return item !== null && typeof item === "object" && "cipher" in item && "iv" in item && "salt" in item;
}

// src/types.ts
var GroupingKey = [
  "home",
  "notes",
  "notebooks",
  "tags",
  "trash",
  "favorites",
  "reminders"
];
function isDeleted(item) {
  return !!item.deleted && item.type !== "trash";
}
function isTrashItem(item) {
  return item.type === "trash";
}
function isGroupHeader(item) {
  return item.type === "header";
}
function isGroupingKey(key) {
  return GroupingKey.includes(key);
}
function isDecryptedContent(content) {
  return !isCipher(content.data);
}
function isEncryptedContent(content) {
  return isCipher(content.data);
}

// src/content-types/tiptap.ts
import showdown from "@streetwriters/showdown";
import { findAll, isTag, removeElement, replaceElement } from "domutils";
import {
  convert
} from "html-to-text";
import { parseDocument } from "htmlparser2";

// src/utils/dataurl.ts
import { parse, validate } from "@readme/data-urls";
function toObject(dataurl2) {
  const result = parse(dataurl2);
  if (!result) return {};
  return {
    mimeType: result.contentType,
    data: result.data
  };
}
function fromObject({ mimeType, data }) {
  if (validate(data)) return data;
  return `data:${mimeType};base64,${data}`;
}
function isValid(url) {
  return validate(url);
}
var dataurl = { toObject, fromObject, isValid };
var dataurl_default = dataurl;

// src/content-types/tiptap.ts
import { render } from "dom-serializer";
var ATTRIBUTES = {
  hash: "data-hash",
  mime: "data-mime",
  filename: "data-filename",
  src: "src",
  href: "href",
  blockId: "data-block-id"
};
showdown.helper.document = getDummyDocument();
var converter = new showdown.Converter();
converter.setFlavor("original");
var splitter = /\W+/gm;
var Tiptap = class {
  constructor(data) {
    this.data = data;
  }
  toHTML() {
    return this.data;
  }
  toTXT() {
    return convertHtmlToTxt(this.data);
  }
  toMD() {
    return converter.makeMarkdown(this.data);
  }
  toHeadline() {
    return extractFirstParagraph(this.data);
  }
  // isEmpty() {
  //   return this.toTXT().trim().length <= 0;
  // }
  search(query) {
    const tokens = query.toLowerCase().split(splitter);
    const lowercase = this.toTXT().toLowerCase();
    return tokens.some((token) => lowercase.indexOf(token) > -1);
  }
  insertBlockIds() {
    let index2 = 0;
    return new HTMLRewriter({
      ontag(name, attr) {
        switch (name) {
          case "p":
          case "h1":
          case "h2":
          case "h3":
          case "h4":
          case "h5":
          case "h6":
          case "blockquote":
          case "ul":
          case "ol":
          case "pre":
          case "img":
          case "iframe":
          case "div":
            return {
              name,
              attr: __spreadProps(__spreadValues({}, attr), { [ATTRIBUTES.blockId]: `${name}${++index2}` })
            };
        }
      }
    }).transform(this.data);
  }
  insertMedia(resolve) {
    return __async(this, null, function* () {
      const hashes = [];
      new HTMLParser({
        ontag: (name, attr) => {
          const hash = attr[ATTRIBUTES.hash];
          if (name === "img" && hash) hashes.push(hash);
        }
      }).parse(this.data);
      if (!hashes.length) return this.data;
      const images = yield resolve(hashes);
      return new HTMLRewriter({
        ontag: (name, attr) => {
          const hash = attr[ATTRIBUTES.hash];
          if (name === "img" && hash) {
            const src = images[hash];
            if (!src) return;
            attr[ATTRIBUTES.src] = src;
          }
        }
      }).transform(this.data);
    });
  }
  resolveAttachments(resolve) {
    return __async(this, null, function* () {
      const attachments = {};
      const document = parseDocument(this.data);
      const elements = findAll(
        (e) => !!e.attribs[ATTRIBUTES.hash],
        document.childNodes
      );
      elements.forEach(
        (element) => attachments[element.attribs[ATTRIBUTES.hash]] = element.attribs
      );
      const resolved = yield resolve(attachments);
      for (const element of elements) {
        const hash = element.attribs[ATTRIBUTES.hash];
        const html = resolved[hash];
        if (html === void 0) continue;
        if (html === false) {
          removeElement(element);
          continue;
        }
        replaceElement(element, parseDocument(html));
      }
      this.data = render(document);
      return this;
    });
  }
  resolveInternalLinks(resolve) {
    this.data = new HTMLRewriter({
      ontag: (name, attr) => {
        const href = attr[ATTRIBUTES.href];
        if (name === "a" && href && href.startsWith("nn://")) {
          const link = resolve(href);
          if (!link) return;
          attr[ATTRIBUTES.href] = link;
        }
      }
    }).transform(this.data);
    return this;
  }
  extract(...types) {
    const result = { blocks: [], internalLinks: [] };
    const document = parseDocument(this.data, {
      withEndIndices: true,
      withStartIndices: true
    });
    if (types.includes("blocksWithLink")) {
      result.blocks.push(
        ...findAll((element) => {
          return !!element.attribs[ATTRIBUTES.blockId] && element.childNodes.some(
            (node) => isTag(node) && node.tagName === "a" && isInternalLink(node.attribs.href)
          );
        }, document.childNodes).map((element) => {
          return {
            id: element.attribs[ATTRIBUTES.blockId],
            type: element.tagName.toLowerCase(),
            content: convertHtmlToTxt(
              this.data.slice(element.startIndex || 0, element.endIndex || 0),
              false
            )
          };
        })
      );
    }
    if (types.includes("blocks")) {
      result.blocks.push(
        ...findAll((element) => {
          return isTag(element) && !!element.attribs[ATTRIBUTES.blockId];
        }, document.childNodes).map((node) => ({
          id: node.attribs[ATTRIBUTES.blockId],
          type: node.tagName.toLowerCase(),
          content: convertHtmlToTxt(
            this.data.slice(node.startIndex || 0, node.endIndex || 0),
            false
          )
        }))
      );
    }
    if (types.includes("internalLinks")) {
      result.internalLinks.push(
        ...findAll(
          (e) => e.tagName === "a" && !!e.attribs.href && e.attribs.href.startsWith("nn://"),
          document.childNodes
        ).map((e) => parseInternalLink(e.attribs.href)).filter((v) => !!v)
      );
    }
    return result;
  }
  /**
   * @param {string[]} hashes
   * @returns
   */
  removeAttachments(hashes) {
    return new HTMLRewriter({
      ontag: (_name, attr) => {
        if (hashes.includes(attr[ATTRIBUTES.hash])) return false;
      }
    }).transform(this.data);
  }
  postProcess(saveAttachment) {
    return __async(this, null, function* () {
      if (!this.data.includes(ATTRIBUTES.src) && !this.data.includes(ATTRIBUTES.hash) && // check for internal links
      !this.data.includes("nn://"))
        return {
          data: this.data,
          hashes: [],
          internalLinks: []
        };
      const internalLinks = [];
      const sources = [];
      new HTMLParser({
        ontag: (name, attr, pos) => {
          const hash = attr[ATTRIBUTES.hash];
          const src = attr[ATTRIBUTES.src];
          const href = attr[ATTRIBUTES.href];
          if (name === "img" && !hash && src) {
            sources.push({
              src,
              filename: attr[ATTRIBUTES.filename],
              mime: attr[ATTRIBUTES.mime],
              id: `${pos.start}${pos.end}`
            });
          } else if (name === "a" && href && href.startsWith("nn://")) {
            const internalLink = parseInternalLink(href);
            if (!internalLink) return;
            internalLinks.push(internalLink);
          }
        }
      }).parse(this.data);
      const images = {};
      for (const image of sources) {
        try {
          const { data, mimeType } = dataurl_default.toObject(image.src);
          if (!data || !mimeType) continue;
          const hash = yield saveAttachment(data, mimeType, image.filename);
          if (!hash) continue;
          images[image.id] = hash;
        } catch (e) {
          logger.error(e, "Failed to save image attachment.", {
            filename: image.filename
          });
          images[image.id] = false;
        }
      }
      const hashes = [];
      const html = new HTMLRewriter({
        ontag: (name, attr, pos) => {
          switch (name) {
            case "img": {
              const hash = attr[ATTRIBUTES.hash];
              if (hash) {
                hashes.push(hash);
                delete attr[ATTRIBUTES.src];
              } else {
                const hash2 = images[`${pos.start}${pos.end}`];
                if (!hash2) return;
                hashes.push(hash2);
                attr[ATTRIBUTES.hash] = hash2;
                delete attr[ATTRIBUTES.src];
              }
              break;
            }
            case "iframe":
            case "span": {
              const hash = attr[ATTRIBUTES.hash];
              if (!hash) return;
              hashes.push(hash);
              break;
            }
          }
        }
      }).transform(this.data);
      return {
        data: html,
        hashes,
        internalLinks
      };
    });
  }
};
function convertHtmlToTxt(html, wrap = true) {
  return convert(html, {
    wordwrap: wrap ? 80 : false,
    preserveNewlines: true,
    selectors: [
      { selector: "table", format: "dataTable" },
      { selector: "ul.checklist", format: "taskList" },
      { selector: "ul.simple-checklist", format: "checkList" },
      { selector: "p", format: "paragraph" },
      { selector: `a[href^="nn://"]`, format: "internalLink" }
    ],
    formatters: {
      internalLink: (elem, walk, builder) => {
        builder.addInline(`[[${elem.attribs.href}|`);
        walk(elem.children, builder);
        builder.addInline("]]");
      },
      taskList: (elem, walk, builder, formatOptions) => {
        return formatList(elem, walk, builder, formatOptions, (elem2) => {
          return elem2.attribs.class && elem2.attribs.class.includes("checked") ? " \u2705 " : " \u2610 ";
        });
      },
      paragraph: (elem, walk, builder) => {
        const { "data-spacing": dataSpacing } = elem.attribs;
        if (elem.parent && elem.parent.name === "li") {
          walk(elem.children, builder);
        } else {
          builder.openBlock({
            leadingLineBreaks: dataSpacing == "single" ? 1 : 2
          });
          walk(elem.children, builder);
          builder.closeBlock({
            trailingLineBreaks: 1
          });
        }
      }
    }
  });
}
function formatList(elem, walk, builder, formatOptions, nextPrefixCallback) {
  var _a;
  const isNestedList = ((_a = elem == null ? void 0 : elem.parent) == null ? void 0 : _a.name) === "li";
  let maxPrefixLength = 0;
  const listItems = (elem.children || []).filter(
    (child) => child.type !== "text" || child.data && !/^\s*$/.test(child.data)
  ).map(function(child) {
    if (child.name !== "li") {
      return { node: child, prefix: "" };
    }
    const prefix = isNestedList ? nextPrefixCallback(child).trimStart() : nextPrefixCallback(child);
    if (prefix.length > maxPrefixLength) {
      maxPrefixLength = prefix.length;
    }
    return { node: child, prefix };
  });
  if (!listItems.length) {
    return;
  }
  builder.openList({
    interRowLineBreaks: 1,
    leadingLineBreaks: isNestedList ? 1 : formatOptions.leadingLineBreaks || 2,
    maxPrefixLength,
    prefixAlign: "left"
  });
  for (const { node, prefix } of listItems) {
    builder.openListItem({ prefix });
    walk([node], builder);
    builder.closeListItem();
  }
  builder.closeList({
    trailingLineBreaks: isNestedList ? 1 : formatOptions.trailingLineBreaks || 2
  });
}

// src/content-types/index.ts
function getContentFromData(type, data) {
  switch (type) {
    case "tiptap":
      return new Tiptap(data);
    default:
      throw new Error(
        `Unknown content type: "${type}". Please report this error at support@streetwriters.co.`
      );
  }
}

// src/collections/attachments.ts
import dayjs3 from "dayjs";
import { sql as sql4 } from "@streetwriters/kysely";
var Attachments = class {
  constructor(db2) {
    this.db = db2;
    this.name = "attachments";
    this.collection = new SQLCollection(
      db2.sql,
      db2.transaction,
      "attachments",
      db2.eventManager,
      db2.sanitizer
    );
    EV.subscribe(
      EVENTS.fileDownloaded,
      (_0) => __async(this, [_0], function* ({
        success,
        filename,
        groupId,
        eventData
      }) {
        if (!success || !eventData || !eventData.readOnDownload) return;
        const attachment = yield this.attachment(filename);
        if (!attachment) return;
        const src = yield this.read(filename, getOutputType(attachment));
        if (!src) return;
        EV.publish(EVENTS.mediaAttachmentDownloaded, {
          groupId,
          hash: attachment.hash,
          attachmentType: getAttachmentType(attachment),
          src
        });
      })
    );
    EV.subscribe(
      EVENTS.fileUploaded,
      (_0) => __async(this, [_0], function* ({
        success,
        error,
        filename
      }) {
        const attachment = yield this.attachment(filename);
        if (!attachment) return;
        if (success) yield this.markAsUploaded(attachment.id);
        else
          yield this.markAsFailed(
            attachment.id,
            error || "Failed to upload attachment."
          );
      })
    );
  }
  init() {
    return __async(this, null, function* () {
      yield this.collection.init();
    });
  }
  add(item) {
    return __async(this, null, function* () {
      if (!item) throw new Error("attachment cannot be undefined");
      if (!item.hash) throw new Error("Please provide attachment hash.");
      const oldAttachment = yield this.attachment(item.hash);
      const id = (oldAttachment == null ? void 0 : oldAttachment.id) || getId();
      const encryptedKey = item.key ? yield this.encryptKey(item.key) : oldAttachment == null ? void 0 : oldAttachment.key;
      const attachment = __spreadProps(__spreadValues(__spreadValues({}, oldAttachment), item), {
        key: encryptedKey
      });
      const {
        iv,
        size,
        alg,
        hash,
        hashType,
        filename,
        mimeType,
        salt,
        chunkSize,
        key
      } = attachment;
      if (!iv || !size || !alg || !hash || !hashType || // !filename ||
      //  !mimeType ||
      !salt || !chunkSize || !key) {
        logger.error(
          {},
          "Attachment is invalid because all properties are required:",
          { attachment }
        );
        return;
      }
      yield this.collection.upsert({
        type: "attachment",
        id,
        iv,
        salt,
        size,
        alg,
        key,
        chunkSize,
        filename: filename || getFileNameWithExtension(
          filename || hash,
          mimeType || "application/octet-stream"
        ),
        hash,
        hashType,
        mimeType: mimeType || "application/octet-stream",
        dateCreated: attachment.dateCreated || Date.now(),
        dateModified: attachment.dateModified || Date.now(),
        dateUploaded: attachment.dateUploaded,
        failed: attachment.failed
      });
      return id;
    });
  }
  generateKey() {
    return __async(this, null, function* () {
      yield this._getEncryptionKey();
      return yield this.db.crypto().generateRandomKey();
    });
  }
  decryptKey(key) {
    return __async(this, null, function* () {
      const encryptionKey = yield this._getEncryptionKey();
      const plainData = yield this.db.storage().decrypt(encryptionKey, key);
      if (!plainData) return null;
      return JSON.parse(plainData);
    });
  }
  remove(hashOrId, localOnly) {
    return __async(this, null, function* () {
      logger.debug("Removing attachment", { hashOrId, localOnly });
      const attachment = yield this.attachment(hashOrId);
      if (!attachment) {
        logger.debug("Attachment not found", { hashOrId, localOnly });
        return false;
      }
      if (!localOnly && !(yield this.canDetach(attachment)))
        throw new Error("This attachment is inside a locked note.");
      if (yield this.db.fs().deleteFile(attachment.hash, localOnly || !attachment.dateUploaded)) {
        if (!localOnly) {
          yield this.detach(attachment);
        }
        yield this.db.relations.unlinkOfType("attachment", [attachment.id]);
        yield this.collection.softDelete([attachment.id]);
        return true;
      }
      return false;
    });
  }
  detach(attachment) {
    return __async(this, null, function* () {
      for (const note of yield this.db.relations.to(attachment, "note").selector.fields(["notes.contentId"]).items()) {
        if (!note.contentId) continue;
        yield this.db.content.removeAttachments(note.contentId, [
          attachment.hash
        ]);
      }
    });
  }
  canDetach(attachment) {
    return __async(this, null, function* () {
      const linkedNotes = yield this.db.relations.to(attachment, "note").get();
      return (yield this.db.relations.to({ ids: linkedNotes.map((n) => n.toId), type: "note" }, "vault").count()) <= 0;
    });
  }
  ofNote(noteId, ...types) {
    const selector = this.db.relations.from(
      { type: "note", id: noteId },
      "attachment"
    ).selector;
    return new FilteredSelector(
      "attachments",
      types.includes("all") ? selector.filter : selector.filter.where((eb) => {
        const filters = [];
        if (types.includes("images"))
          filters.push(eb("mimeType", "like", `image/%`));
        if (types.includes("videos"))
          filters.push(eb("mimeType", "like", `video/%`));
        if (types.includes("audio"))
          filters.push(eb("mimeType", "like", `audio/%`));
        if (types.includes("documents"))
          filters.push(eb("mimeType", "in", DocumentMimeTypes));
        if (types.includes("webclips"))
          filters.push(
            eb("mimeType", "==", `application/vnd.notesnook.web-clip`)
          );
        if (types.includes("files")) {
          filters.push(
            eb.and([
              eb("mimeType", "!=", `application/vnd.notesnook.web-clip`),
              eb("mimeType", "not like", `image/%`)
            ])
          );
        }
        return eb.or(filters);
      })
    );
  }
  exists(hash) {
    return __async(this, null, function* () {
      return !!(yield this.attachment(hash));
    });
  }
  read(hash, outputType) {
    return __async(this, null, function* () {
      const attachment = yield this.attachment(hash);
      if (!attachment) return;
      const key = yield this.decryptKey(attachment.key);
      if (!key) return;
      const data = yield this.db.fs().readEncrypted(attachment.hash, key, {
        chunkSize: attachment.chunkSize,
        iv: attachment.iv,
        salt: attachment.salt,
        size: attachment.size,
        alg: attachment.alg,
        outputType
      });
      if (!data) return;
      return outputType === "base64" && typeof data === "string" ? dataurl_default.fromObject({
        mimeType: attachment.mimeType,
        data
      }) : data;
    });
  }
  attachment(hashOrId) {
    return __async(this, null, function* () {
      const attachment = yield this.all.find(
        (eb) => eb.or([eb("id", "==", hashOrId), eb("hash", "==", hashOrId)])
      );
      if (attachment) logger.debug("attachment exists", { hashOrId });
      return attachment;
    });
  }
  markAsUploaded(id) {
    return this.collection.update([id], {
      dateUploaded: Date.now(),
      failed: null
    });
  }
  reset(id) {
    return this.collection.update([id], {
      dateUploaded: null
    });
  }
  markAsFailed(id, reason) {
    return this.collection.update([id], {
      failed: !reason ? null : reason
    });
  }
  cacheAttachments(attachments) {
    return __async(this, null, function* () {
      const items = yield (attachments || this.db.attachments.linked).fields(["attachments.id", "attachments.hash", "attachments.chunkSize"]).items();
      yield this.db.fs().queueDownloads(
        items.map((a) => ({
          filename: a.hash,
          chunkSize: a.chunkSize
        })),
        "offline-mode",
        { readOnDownload: false }
      );
    });
  }
  save(data, mimeType, filename) {
    return __async(this, null, function* () {
      const hashResult = yield this.db.fs().hashBase64(data);
      if (!hashResult) return;
      if (yield this.exists(hashResult.hash)) return hashResult.hash;
      const key = yield this.generateKey();
      const _a = yield this.db.fs().writeEncryptedBase64(data, key, mimeType), { hash, hashType } = _a, encryptionMetadata = __objRest(_a, ["hash", "hashType"]);
      yield this.add(__spreadProps(__spreadValues({}, encryptionMetadata), {
        key,
        filename: filename || hash,
        hash,
        hashType,
        mimeType: mimeType || "application/octet-stream"
      }));
      return hash;
    });
  }
  downloadMedia(noteId, hashesToLoad) {
    return __async(this, null, function* () {
      const attachments = this.ofNote(noteId, "images", "webclips").fields([
        "attachments.id",
        "attachments.hash",
        "attachments.chunkSize"
      ]);
      if (hashesToLoad)
        attachments.where((eb) => eb.and([eb("hash", "in", hashesToLoad)]));
      yield this.db.fs().queueDownloads(
        (yield attachments.items()).map((a) => ({
          filename: a.hash,
          chunkSize: a.chunkSize
        })),
        noteId,
        { readOnDownload: true }
      );
    });
  }
  cleanup() {
    return __async(this, null, function* () {
      const now = dayjs3().unix();
      const ids = [];
      try {
        for (var iter = __forAwait(this.deleted), more, temp, error; more = !(temp = yield iter.next()).done; more = false) {
          const attachment = temp.value;
          if (dayjs3(attachment.dateDeleted).add(7, "days").unix() < now) continue;
          const isDeleted2 = yield this.db.fs().deleteFile(attachment.hash);
          if (!isDeleted2) continue;
          ids.push(attachment.id);
        }
      } catch (temp) {
        error = [temp];
      } finally {
        try {
          more && (temp = iter.return) && (yield temp.call(iter));
        } finally {
          if (error)
            throw error[0];
        }
      }
      yield this.collection.softDelete(ids);
    });
  }
  get pending() {
    var _a;
    return this.collection.createFilter(
      (qb) => qb.where(isFalse("dateUploaded")).where(isFalse("deleted")),
      (_a = this.db.options) == null ? void 0 : _a.batchSize
    );
  }
  // get uploaded() {
  //   return this.all.filter((attachment) => !!attachment.dateUploaded);
  // }
  // get syncable() {
  //   return this.collection
  //     .raw()
  //     .filter(
  //       (attachment) => isDeleted(attachment) || !!attachment.dateUploaded
  //     );
  // }
  get deleted() {
    var _a;
    return this.collection.createFilter(
      (qb) => qb.where("dateDeleted", "is not", null),
      (_a = this.db.options) == null ? void 0 : _a.batchSize
    );
  }
  get images() {
    var _a;
    return this.collection.createFilter(
      (qb) => qb.where(isFalse("deleted")).where("mimeType", "like", `image/%`),
      (_a = this.db.options) == null ? void 0 : _a.batchSize
    );
  }
  get videos() {
    var _a;
    return this.collection.createFilter(
      (qb) => qb.where(isFalse("deleted")).where("mimeType", "like", `video/%`),
      (_a = this.db.options) == null ? void 0 : _a.batchSize
    );
  }
  get audios() {
    var _a;
    return this.collection.createFilter(
      (qb) => qb.where(isFalse("deleted")).where("mimeType", "like", `audio/%`),
      (_a = this.db.options) == null ? void 0 : _a.batchSize
    );
  }
  get documents() {
    var _a;
    return this.collection.createFilter(
      (qb) => qb.where(isFalse("deleted")).where("mimeType", "in", DocumentMimeTypes),
      (_a = this.db.options) == null ? void 0 : _a.batchSize
    );
  }
  get webclips() {
    var _a;
    return this.collection.createFilter(
      (qb) => qb.where(isFalse("deleted")).where("mimeType", "==", `application/vnd.notesnook.web-clip`),
      (_a = this.db.options) == null ? void 0 : _a.batchSize
    );
  }
  get orphaned() {
    var _a;
    return this.collection.createFilter(
      (qb) => qb.where(isFalse("deleted")).where(
        "id",
        "not in",
        (eb) => eb.selectFrom("relations").where("toType", "==", "attachment").select("toId as id").$narrowType()
      ),
      (_a = this.db.options) == null ? void 0 : _a.batchSize
    );
  }
  get linked() {
    var _a;
    return this.collection.createFilter(
      (qb) => qb.where(isFalse("deleted")).where(
        "id",
        "in",
        (eb) => eb.selectFrom("relations").where("toType", "==", "attachment").select("toId as id").$narrowType()
      ),
      (_a = this.db.options) == null ? void 0 : _a.batchSize
    );
  }
  // get media() {
  //   return this.all.filter(
  //     (attachment) =>
  //       isImage(attachment.metadata.type) || isWebClip(attachment.metadata.type)
  //   );
  // }
  // get files() {
  //   return this.all.filter(
  //     (attachment) =>
  //       !isImage(attachment.metadata.type) &&
  //       !isWebClip(attachment.metadata.type)
  //   );
  // }
  get all() {
    var _a;
    return this.collection.createFilter(
      (qb) => qb.where(isFalse("deleted")),
      (_a = this.db.options) == null ? void 0 : _a.batchSize
    );
  }
  totalSize() {
    return __async(this, arguments, function* (selector = this.all) {
      const result = yield selector.filter.select((eb) => eb.fn.sum(sql4.raw(`size + 17`)).as("totalSize")).executeTakeFirst();
      return result == null ? void 0 : result.totalSize;
    });
  }
  encryptKey(key) {
    return __async(this, null, function* () {
      const encryptionKey = yield this._getEncryptionKey();
      const encryptedKey = yield this.db.storage().encrypt(encryptionKey, JSON.stringify(key));
      return encryptedKey;
    });
  }
  _getEncryptionKey() {
    return __async(this, null, function* () {
      var _a;
      this.key = yield (_a = this.db.user) == null ? void 0 : _a.getAttachmentsKey();
      if (!this.key)
        throw new Error(
          "Failed to get user encryption key. Cannot cache attachments."
        );
      return this.key;
    });
  }
};
function getOutputType(attachment) {
  if (attachment.mimeType === "application/vnd.notesnook.web-clip")
    return "text";
  else if (attachment.mimeType.startsWith("image/")) return "base64";
  return "uint8array";
}
function getAttachmentType(attachment) {
  if (attachment.mimeType === "application/vnd.notesnook.web-clip")
    return "webclip";
  else if (attachment.mimeType.startsWith("image/")) return "image";
  else return "generic";
}

// src/migrations.ts
import { decodeHTML5 as decodeHTML52 } from "entities";

// src/collections/tags.ts
import { sql as sql5 } from "@streetwriters/kysely";
var Tags = class {
  constructor(db2) {
    this.db = db2;
    this.name = "tags";
    this.collection = new SQLCollection(
      db2.sql,
      db2.transaction,
      "tags",
      db2.eventManager,
      db2.sanitizer
    );
  }
  init() {
    return this.collection.init();
  }
  tag(id) {
    return this.collection.get(id);
  }
  find(title) {
    return this.all.find(sql5`title == ${title} COLLATE BINARY`);
  }
  add(item) {
    return __async(this, null, function* () {
      item.title = sanitizeTag(item.title);
      const oldTag = item.id ? yield this.tag(item.id) : void 0;
      if (oldTag && item.title === oldTag.title) return oldTag.id;
      if (yield this.find(item.title))
        throw new Error("Tag with this title already exists.");
      if (oldTag) {
        yield this.collection.update([oldTag.id], item);
        return oldTag.id;
      }
      if ((yield this.all.count()) >= 5 && !(yield checkIsUserPremium(CHECK_IDS.noteTag)))
        return;
      const id = item.id || getId();
      yield this.collection.upsert({
        id,
        dateCreated: item.dateCreated || Date.now(),
        dateModified: item.dateModified || Date.now(),
        title: item.title,
        type: "tag"
      });
      return id;
    });
  }
  // get raw() {
  //   return this.collection.raw();
  // }
  get all() {
    var _a;
    return this.collection.createFilter(
      (qb) => qb.where(isFalse("deleted")),
      (_a = this.db.options) == null ? void 0 : _a.batchSize
    );
  }
  remove(...ids) {
    return __async(this, null, function* () {
      yield this.db.transaction(() => __async(this, null, function* () {
        yield this.db.relations.unlinkOfType("tag", ids);
        yield this.collection.softDelete(ids);
      }));
    });
  }
  exists(id) {
    return this.collection.exists(id);
  }
};
function sanitizeTag(title) {
  return title.replace(/^\s+|\s+$/gm, "");
}

// src/collections/colors.ts
var DefaultColors = {
  red: "#f44336",
  orange: "#FF9800",
  yellow: "#FFD600",
  green: "#4CAF50",
  blue: "#2196F3",
  purple: "#673AB7",
  gray: "#9E9E9E"
};
var Colors = class {
  constructor(db2) {
    this.db = db2;
    this.name = "colors";
    this.collection = new SQLCollection(
      db2.sql,
      db2.transaction,
      "colors",
      db2.eventManager,
      db2.sanitizer
    );
  }
  init() {
    return this.collection.init();
  }
  color(id) {
    return this.collection.get(id);
  }
  find(colorCode) {
    return this.all.find((eb) => eb.and([eb("colorCode", "==", colorCode)]));
  }
  // async merge(remoteColor: MaybeDeletedItem<Color>) {
  //   if (!remoteColor) return;
  //   const localColor = this.collection.get(remoteColor.id);
  //   if (!localColor || remoteColor.dateModified > localColor.dateModified)
  //     await this.collection.add(remoteColor);
  // }
  add(item) {
    return __async(this, null, function* () {
      item.title = item.title ? sanitizeTag(item.title) : item.title;
      const oldColor = item.id ? yield this.color(item.id) : item.colorCode ? yield this.find(item.colorCode) : void 0;
      if (!item.title && !(oldColor == null ? void 0 : oldColor.title)) throw new Error("Title is required.");
      if (!item.colorCode && !(oldColor == null ? void 0 : oldColor.colorCode))
        throw new Error("Color code is required.");
      if (oldColor) {
        yield this.collection.update([oldColor.id], item);
        return oldColor.id;
      }
      if (!(yield checkIsUserPremium(CHECK_IDS.noteColor))) return;
      const id = item.id || getId(item.dateCreated);
      yield this.collection.upsert({
        id,
        dateCreated: item.dateCreated || Date.now(),
        dateModified: item.dateModified || Date.now(),
        title: item.title || "",
        colorCode: item.colorCode || "",
        type: "color",
        remote: false
      });
      return id;
    });
  }
  // get raw() {
  //   return this.collection.raw();
  // }
  get all() {
    var _a;
    return this.collection.createFilter(
      (qb) => qb.where(isFalse("deleted")),
      (_a = this.db.options) == null ? void 0 : _a.batchSize
    );
  }
  remove(...ids) {
    return __async(this, null, function* () {
      yield this.db.transaction(() => __async(this, null, function* () {
        yield this.db.relations.unlinkOfType("color", ids);
        yield this.collection.softDelete(ids);
      }));
    });
  }
  // async delete(id: string) {
  //   await this.collection.delete(id);
  //   await this.db.relations.cleanup();
  // }
  exists(id) {
    return this.collection.exists(id);
  }
  // find(idOrTitle: string) {
  //   return this.all.find((t) => t.title === idOrTitle || t.id === idOrTitle);
  // }
};

// src/database/kv.ts
var KEYS = [
  "v",
  "lastSynced",
  "user",
  "token",
  "monographs",
  "deviceId",
  "lastBackupTime",
  "fullOfflineMode"
];
var KVStorage = class {
  constructor(db2) {
    this.db = db2;
  }
  read(key) {
    return __async(this, null, function* () {
      const result = yield this.db().selectFrom("kv").where("key", "==", key).select("value").limit(1).executeTakeFirst();
      if (!(result == null ? void 0 : result.value)) return;
      return JSON.parse(result.value);
    });
  }
  write(key, value) {
    return __async(this, null, function* () {
      yield this.db().replaceInto("kv").values({
        key,
        value: JSON.stringify(value),
        dateModified: Date.now()
      }).execute();
    });
  }
  delete(key) {
    return __async(this, null, function* () {
      yield this.db().deleteFrom("kv").where("key", "==", key).execute();
    });
  }
  clear() {
    return __async(this, null, function* () {
      yield this.db().deleteFrom("kv").execute();
    });
  }
};

// src/migrations.ts
var migrations = [
  { version: 5, items: {} },
  { version: 5.1, items: {} },
  {
    version: 5.2,
    items: {
      note: replaceDateEditedWithDateModified(false),
      notebook: replaceDateEditedWithDateModified(false),
      tag: replaceDateEditedWithDateModified(true),
      attachment: replaceDateEditedWithDateModified(true),
      trash: replaceDateEditedWithDateModified(false),
      tiny: (item) => {
        replaceDateEditedWithDateModified(false)(item);
        if (!item.data || isCipher(item.data)) return true;
        item.data = removeToxClassFromChecklist(wrapTablesWithDiv(item.data));
        return true;
      },
      settings: replaceDateEditedWithDateModified(true)
    }
  },
  {
    version: 5.3,
    items: {
      tiny: (item) => {
        if (!item.data || isCipher(item.data)) return false;
        item.data = decodeWrappedTableHtml(item.data);
        return true;
      }
    }
  },
  {
    version: 5.4,
    items: {
      tiny: (item) => {
        if (!item.data || isCipher(item.data)) return false;
        item.type = "tiptap";
        item.data = tinyToTiptap(item.data);
        return true;
      }
    }
  },
  {
    version: 5.5,
    items: {}
  },
  {
    version: 5.6,
    items: {
      notebook: (item) => {
        if (!item.topics) return false;
        item.topics = item.topics.map((topic) => {
          delete topic.notes;
          return topic;
        });
        return item.topics.length > 0;
      },
      settings: (item, db2) => __async(void 0, null, function* () {
        if (!item.pins) return false;
        for (const pin of item.pins) {
          if (!pin.data) continue;
          yield db2.shortcuts.add({
            itemId: pin.data.id,
            itemType: pin.type === "topic" ? "notebook" : pin.type
          });
        }
        delete item.pins;
        return true;
      })
    }
  },
  {
    version: 5.7,
    items: {
      tiny: (item) => {
        item.type = "tiptap";
        return changeSessionContentType(item);
      },
      content: (item) => {
        const oldType = item.type;
        item.type = "tiptap";
        return oldType !== item.type;
      },
      shortcut: (item) => {
        if (!item.item || item.id === item.item.id) return false;
        item.id = item.item.id;
        return true;
      },
      tiptap: (item) => {
        return changeSessionContentType(item);
      },
      notehistory: (item) => {
        const oldType = item.type;
        item.type = "session";
        return oldType !== item.type;
      }
    },
    collection: (collection) => __async(void 0, null, function* () {
      yield collection.indexer.migrateIndices();
    })
  },
  {
    version: 5.8,
    items: {},
    all: (item, _db, migrationType) => {
      if (migrationType === "local") {
        delete item.remote;
        return true;
      }
    }
  },
  {
    version: 5.9,
    items: {
      trash: (item) => {
        if (!item.deletedBy) item.deletedBy = "user";
        delete item.itemId;
        return true;
      },
      color: (item, db2, migrationType) => __async(void 0, null, function* () {
        var _a, _b, _c;
        return (yield (_c = (_a = migrations.find((migration) => migration.version === 5.9)) == null ? void 0 : (_b = _a.items).tag) == null ? void 0 : _c.call(_b, item, db2, migrationType)) || false;
      }),
      tag: (item, db2) => __async(void 0, null, function* () {
        const oldTagId = makeId(item.title);
        const alias = db2.legacySettings.getAlias(item.id);
        if (!alias && (db2.legacyTags.items().find((t) => item.title === t.title && t.id !== oldTagId) || db2.legacyColors.items().find((t) => item.title === t.title && t.id !== oldTagId)))
          return "skip";
        const colorCode = DefaultColors[item.title];
        if (colorCode) {
          const newColor = yield db2.colors.all.find(
            (eb) => eb("title", "in", [alias, item.title])
          );
          if (newColor) return "skip";
          item.type = "color";
          item.colorCode = colorCode;
        } else {
          const newTag = yield db2.tags.all.find(
            (eb) => eb("title", "in", [alias, item.title])
          );
          if (newTag) return "skip";
        }
        item.dateCreated = item.dateCreated || Date.now();
        item.title = alias || item.title;
        item.id = makeId(item.title);
        delete item.localOnly;
        delete item.noteIds;
        delete item.alias;
        return true;
      }),
      note: (item, db2) => __async(void 0, null, function* () {
        for (const tag of item.tags || []) {
          if (!tag) continue;
          const oldTagId = makeId(tag);
          const oldTag = db2.legacyTags.get(oldTagId);
          const alias = db2.legacySettings.getAlias(oldTagId);
          const newTag = yield db2.tags.all.find(
            (eb) => eb("title", "in", [alias, tag])
          );
          const newTagId = (newTag == null ? void 0 : newTag.id) || (yield db2.tags.add({
            // IMPORTANT: the id must be deterministic to avoid creating
            // duplicate colors when migrating on different devices
            id: makeId(alias || tag),
            dateCreated: oldTag == null ? void 0 : oldTag.dateCreated,
            dateModified: oldTag == null ? void 0 : oldTag.dateModified,
            title: alias || tag,
            type: "tag"
          }));
          if (!newTagId) continue;
          yield db2.relations.add({ type: "tag", id: newTagId }, item);
          yield db2.legacyTags.delete(oldTagId);
        }
        if (item.color) {
          const oldColorId = makeId(item.color);
          const oldColor = db2.legacyColors.get(oldColorId);
          const alias = db2.legacySettings.getAlias(oldColorId);
          const newColor = yield db2.colors.all.find(
            (eb) => eb("title", "in", [alias, item.color])
          );
          const newColorId = (newColor == null ? void 0 : newColor.id) || (yield db2.colors.add({
            // IMPORTANT: the id must be deterministic to avoid creating
            // duplicate colors when migrating on different devices
            id: makeId(alias || item.color),
            dateCreated: oldColor == null ? void 0 : oldColor.dateCreated,
            dateModified: oldColor == null ? void 0 : oldColor.dateModified,
            title: alias || item.color,
            colorCode: DefaultColors[item.color],
            type: "color"
          }));
          if (newColorId) {
            yield db2.relations.add({ type: "color", id: newColorId }, item);
            yield db2.legacyColors.delete(oldColorId);
          }
        }
        if (item.notebooks) {
          for (const notebook of item.notebooks) {
            for (const topic of notebook.topics) {
              yield db2.relations.add({ type: "notebook", id: topic }, item);
            }
          }
        }
        if (item.locked) {
          const vault = yield db2.vaults.default();
          if (vault)
            yield db2.relations.add({ type: "vault", id: vault.id }, item);
        }
        delete item.locked;
        delete item.notebooks;
        delete item.tags;
        delete item.color;
        return true;
      }),
      attachment: (item, db2) => __async(void 0, null, function* () {
        for (const noteId of item.noteIds || []) {
          yield db2.relations.add(
            { type: "note", id: noteId },
            { type: "attachment", id: item.id }
          );
        }
        if (item.metadata) {
          item.hash = item.metadata.hash;
          item.mimeType = item.metadata.type;
          item.hashType = item.metadata.hashType;
          item.filename = item.metadata.filename;
        }
        if (item.length) item.size = item.length;
        delete item.length;
        delete item.metadata;
        delete item.noteIds;
        return true;
      }),
      notebook: (item, db2) => __async(void 0, null, function* () {
        for (const topic of item.topics || []) {
          const subNotebookId = yield db2.notebooks.add({
            id: topic.id,
            title: topic.title,
            dateCreated: topic.dateCreated,
            dateEdited: topic.dateEdited,
            dateModified: topic.dateModified
          });
          if (!subNotebookId) continue;
          yield db2.relations.add(item, { id: subNotebookId, type: "notebook" });
          if (item.dateDeleted) {
            yield db2.trash.add("notebook", [subNotebookId], "app");
          }
        }
        delete item.topics;
        delete item.totalNotes;
        delete item.topic;
        return true;
      }),
      shortcut: (item) => {
        var _a;
        if (((_a = item.item) == null ? void 0 : _a.type) === "topic") {
          item.item = { type: "notebook", id: item.item.id };
        }
        if (item.item) {
          item.itemId = item.item.id;
          item.itemType = item.item.type;
        }
        delete item.item;
        return true;
      },
      settings: (item, db2) => __async(void 0, null, function* () {
        if (item.trashCleanupInterval)
          yield db2.settings.setTrashCleanupInterval(item.trashCleanupInterval);
        if (item.defaultNotebook)
          yield db2.settings.setDefaultNotebook(
            item.defaultNotebook ? item.defaultNotebook.topic || item.defaultNotebook.id : void 0
          );
        if (item.titleFormat)
          yield db2.settings.setTitleFormat(item.titleFormat);
        if (item.dateFormat) yield db2.settings.setDateFormat(item.dateFormat);
        if (item.timeFormat) yield db2.settings.setTimeFormat(item.timeFormat);
        if (item.groupOptions) {
          for (const key in item.groupOptions) {
            if (!isGroupingKey(key)) continue;
            const value = item.groupOptions[key];
            if (!value) continue;
            if (key === "tags" && value.sortBy === "dateEdited")
              value.sortBy = "dateModified";
            yield db2.settings.setGroupOptions(key, value);
          }
        }
        if (item.toolbarConfig) {
          for (const key in item.toolbarConfig) {
            const value = item.toolbarConfig[key];
            if (!value) continue;
            yield db2.settings.setToolbarConfig(
              key,
              value
            );
          }
        }
        return true;
      }),
      relation: (item) => {
        item.fromId = item.from.id;
        item.fromType = item.from.type;
        item.toId = item.to.id;
        item.toType = item.to.type;
        delete item.to;
        delete item.from;
        return true;
      },
      tiptap: (item) => {
        item.locked = isCipher(item.data);
        delete item.resolved;
        return true;
      },
      tiny: (item) => {
        delete item.resolved;
        return true;
      },
      notehistory: (item) => {
        delete item.data;
        return true;
      }
    },
    all: (item) => {
      delete item.deleteReason;
      return true;
    },
    vaultKey(db2, key) {
      return __async(this, null, function* () {
        yield db2.vaults.add({ title: "Default", key });
        yield db2.storage().remove("vaultKey");
      });
    },
    kv(db2) {
      return __async(this, null, function* () {
        for (const key of KEYS) {
          const value = yield db2.storage().read(key);
          if (value === void 0 || value === null) continue;
          yield db2.kv().write(key, value);
          yield db2.storage().remove(key);
        }
      });
    }
  },
  {
    version: 6,
    items: {
      note: (item) => {
        delete item.locked;
        return true;
      }
    },
    all: (item) => {
      if (isDeleted(item)) {
        const allowedKeys = [
          "deleted",
          "dateModified",
          "id",
          "synced",
          "remote"
        ];
        for (const key in item) {
          if (allowedKeys.includes(key)) continue;
          delete item[key];
        }
        return true;
      }
    }
  },
  { version: 6.1, items: {} }
];
function migrateItem(item, itemVersion, databaseVersion, type, database, migrationType) {
  return __async(this, null, function* () {
    let migrationStartIndex = migrations.findIndex(
      (m) => m.version === itemVersion
    );
    if (migrationStartIndex <= -1) {
      throw new Error(
        itemVersion > databaseVersion ? `Please update the app to the latest version.` : `You seem to be on a very outdated version. Please update the app to the latest version.`
      );
    }
    let count = 0;
    for (; migrationStartIndex < migrations.length; ++migrationStartIndex) {
      const migration = migrations[migrationStartIndex];
      if (migration.version === databaseVersion) break;
      let result = !!migration.all && (yield migration.all(item, database, migrationType, type));
      if (result === "skip") return "skip";
      if (result) {
        if (!isDeleted(item) && item.type && item.type !== "trash" && item.type !== type)
          type = item.type;
        count++;
      }
      const itemMigrator = migration.items[type];
      if (isDeleted(item) || !itemMigrator) continue;
      result = yield itemMigrator(item, database, migrationType);
      if (result === "skip") return "skip";
      if (result) {
        if (item.type && item.type !== "trash" && item.type !== type)
          type = item.type;
        count++;
      }
    }
    return count > 0;
  });
}
function migrateCollection(collection, version) {
  return __async(this, null, function* () {
    let migrationStartIndex = migrations.findIndex((m) => m.version === version);
    if (migrationStartIndex <= -1) {
      throw new Error(
        version > CURRENT_DATABASE_VERSION ? `Please update the app to the latest version.` : `You seem to be on a very outdated version. Please update the app to the latest version.`
      );
    }
    for (; migrationStartIndex < migrations.length; ++migrationStartIndex) {
      const migration = migrations[migrationStartIndex];
      if (migration.version === CURRENT_DATABASE_VERSION) break;
      if (!migration.collection) continue;
      yield migration.collection(collection);
    }
  });
}
function migrateVaultKey(db2, vaultKey, version, databaseVersion) {
  return __async(this, null, function* () {
    let migrationStartIndex = migrations.findIndex((m) => m.version === version);
    if (migrationStartIndex <= -1) {
      throw new Error(
        version > databaseVersion ? `Please update the app to the latest version.` : `You seem to be on a very outdated version. Please update the app to the latest version.`
      );
    }
    for (; migrationStartIndex < migrations.length; ++migrationStartIndex) {
      const migration = migrations[migrationStartIndex];
      if (migration.version === databaseVersion) break;
      if (!migration.vaultKey) continue;
      yield migration.vaultKey(db2, vaultKey);
    }
  });
}
function migrateKV(db2, version, databaseVersion) {
  return __async(this, null, function* () {
    let migrationStartIndex = migrations.findIndex((m) => m.version === version);
    if (migrationStartIndex <= -1) {
      throw new Error(
        version > databaseVersion ? `Please update the app to the latest version.` : `You seem to be on a very outdated version. Please update the app to the latest version.`
      );
    }
    for (; migrationStartIndex < migrations.length; ++migrationStartIndex) {
      const migration = migrations[migrationStartIndex];
      if (migration.version === databaseVersion) break;
      if (!migration.kv) continue;
      yield migration.kv(db2);
    }
  });
}
function replaceDateEditedWithDateModified(removeDateEditedProperty = false) {
  return function(item) {
    item.dateModified = item.dateEdited;
    if (removeDateEditedProperty) delete item.dateEdited;
    delete item.persistDateEdited;
    return true;
  };
}
function wrapTablesWithDiv(html) {
  const document = parseHTML(html);
  const tables = document.getElementsByTagName("table");
  for (const table of tables) {
    table.setAttribute("contenteditable", "true");
    const div = document.createElement("div");
    div.setAttribute("contenteditable", "false");
    div.innerHTML = table.outerHTML;
    div.classList.add("table-container");
    table.replaceWith(div);
  }
  return "outerHTML" in document ? document.outerHTML : document.body.innerHTML;
}
function removeToxClassFromChecklist(html) {
  const document = parseHTML(html);
  const checklists = document.querySelectorAll(
    ".tox-checklist,.tox-checklist--checked"
  );
  for (const item of checklists) {
    if (item.classList.contains("tox-checklist--checked"))
      item.classList.replace("tox-checklist--checked", "checked");
    else if (item.classList.contains("tox-checklist"))
      item.classList.replace("tox-checklist", "checklist");
  }
  return "outerHTML" in document ? document.outerHTML : document.body.innerHTML;
}
var regex = /&lt;div class="table-container".*&lt;\/table&gt;&lt;\/div&gt;/gm;
function decodeWrappedTableHtml(html) {
  return html.replace(regex, (match2) => {
    const html2 = decodeHTML52(match2);
    return html2;
  });
}
var NEWLINE_REPLACEMENT_REGEX = /\n|<br>|<br\/>/gm;
var PREBLOCK_REGEX = /(<pre.*?>)(.*?)(<\/pre>)/gm;
var SPAN_REGEX = /<span class=.*?>(.*?)<\/span>/gm;
function tinyToTiptap(html) {
  var _a;
  if (typeof html !== "string") return html;
  html = html.replace(/\n/gm, "<br/>").replace(
    PREBLOCK_REGEX,
    (_pre, start, inner, end) => {
      let codeblock = start;
      codeblock += inner.replace(NEWLINE_REPLACEMENT_REGEX, "<br/>").replace(SPAN_REGEX, (_span, inner2) => inner2);
      codeblock += end;
      return codeblock;
    }
  );
  const document = parseHTML(html);
  const tables = document.querySelectorAll("table");
  for (const table of tables) {
    table.removeAttribute("contenteditable");
    if (table.parentElement && table.parentElement.nodeName.toLowerCase() === "div") {
      table.parentElement.replaceWith(table);
    }
  }
  const images = document.querySelectorAll("p > img");
  for (const image of images) {
    (_a = image.parentElement) == null ? void 0 : _a.replaceWith(image.cloneNode());
  }
  const bogus = document.querySelectorAll("[data-mce-bogus]");
  for (const element of bogus) {
    element.remove();
  }
  const attributes = document.querySelectorAll(
    "[data-mce-href], [data-mce-flag]"
  );
  for (const element of attributes) {
    element.removeAttribute("data-mce-href");
    element.removeAttribute("data-mce-flag");
  }
  return document.body.innerHTML;
}
function changeSessionContentType(item) {
  if (item.id.endsWith("_content")) {
    item.contentType = item.type;
    item.type = "sessioncontent";
    return true;
  }
  return false;
}

// src/collections/content.ts
var EMPTY_CONTENT = (noteId) => ({
  noteId,
  dateCreated: Date.now(),
  dateEdited: Date.now(),
  dateModified: Date.now(),
  id: getId(),
  localOnly: true,
  type: "tiptap",
  data: "<p></p>",
  locked: false
});
var Content = class {
  constructor(db2) {
    this.db = db2;
    this.name = "content";
    this.collection = new SQLCollection(
      db2.sql,
      db2.transaction,
      "content",
      db2.eventManager,
      db2.sanitizer
    );
  }
  init() {
    return __async(this, null, function* () {
      yield this.collection.init();
    });
  }
  add(content) {
    return __async(this, null, function* () {
      var _a;
      if (typeof content.data === "object") {
        if ("data" in content.data && typeof content.data.data === "string")
          content.data = content.data.data;
        else if (!content.data.iv && !content.data.cipher)
          content.data = `<p>Content is invalid: ${JSON.stringify(
            content.data
          )}</p>`;
      }
      if (content.remote)
        throw new Error(
          "Please use db.content.merge for merging remote content."
        );
      if (content.noteId && !content.id) {
        logger.debug("finding content id", { id: content.noteId });
        content.id = (_a = yield this.db.sql().selectFrom("content").where("noteId", "==", content.noteId).select("id").executeTakeFirst()) == null ? void 0 : _a.id;
      }
      const id = content.id || getId();
      const encryptedData = isCipher(content.data) ? content.data : void 0;
      let unencryptedData = typeof content.data === "string" ? content.data : void 0;
      if (unencryptedData && content.type && content.noteId)
        unencryptedData = yield this.postProcess({
          type: content.type,
          data: unencryptedData,
          noteId: content.noteId
        });
      if (content.id && (yield this.exists(content.id))) {
        const contentData = encryptedData ? { locked: true, data: encryptedData } : unencryptedData ? { locked: false, data: unencryptedData } : void 0;
        if (contentData)
          logger.debug("updating content", {
            id: content.noteId,
            contentId: content.id,
            length: JSON.stringify(contentData.data).length
          });
        yield this.collection.update([content.id], __spreadValues({
          dateEdited: content.dateEdited,
          localOnly: content.localOnly,
          conflicted: content.dateResolved ? null : content.conflicted,
          dateResolved: content.dateResolved,
          noteId: content.noteId
        }, contentData));
        if (content.sessionId && contentData && content.type && content.noteId) {
          yield this.db.noteHistory.add(content.sessionId, __spreadValues({
            noteId: content.noteId,
            type: content.type
          }, contentData));
        }
      } else if (content.noteId) {
        const contentItem = __spreadValues({
          type: "tiptap",
          noteId: content.noteId,
          id,
          dateEdited: content.dateEdited || Date.now(),
          dateCreated: content.dateCreated || Date.now(),
          dateModified: Date.now(),
          localOnly: !!content.localOnly,
          conflicted: content.conflicted,
          dateResolved: content.dateResolved
        }, encryptedData ? { locked: true, data: encryptedData } : { locked: false, data: unencryptedData || "<p></p>" });
        logger.debug("inserting content", {
          id: content.noteId,
          contentId: content.id,
          length: JSON.stringify(contentItem.data).length
        });
        yield this.collection.upsert(contentItem);
        if (content.sessionId)
          yield this.db.noteHistory.add(content.sessionId, contentItem);
      } else return;
      return id;
    });
  }
  get(id) {
    return __async(this, null, function* () {
      const content = yield this.collection.get(id);
      if (!content || isDeleted(content)) return;
      if (!content.locked && this.preProcess(content)) {
        yield this.collection.update([content.id], content, { modify: false });
      }
      return content;
    });
  }
  // async raw(id: string) {
  //   const content = await this.collection.get(id);
  //   if (!content) return;
  //   return content;
  // }
  remove(...ids) {
    return this.collection.softDelete(ids);
  }
  removeByNoteId(...ids) {
    return __async(this, null, function* () {
      yield this.db.sql().replaceInto("content").columns(["id", "dateModified", "deleted", "synced"]).expression(
        (eb) => eb.selectFrom("content").where("noteId", "in", ids).select((eb2) => [
          "content.id",
          eb2.lit(Date.now()).as("dateModified"),
          eb2.lit(1).as("deleted"),
          eb2.lit(0).as("synced")
        ])
      ).execute();
      this.db.eventManager.publish(EVENTS.databaseUpdated, {
        collection: "content",
        type: "softDelete",
        ids
      });
    });
  }
  updateByNoteId(partial, ...ids) {
    return __async(this, null, function* () {
      yield this.db.sql().updateTable("content").where("noteId", "in", ids).set(__spreadProps(__spreadValues({}, partial), {
        dateModified: Date.now()
      })).execute();
      this.db.eventManager.publish(EVENTS.databaseUpdated, {
        collection: "content",
        type: "update",
        ids,
        item: partial
      });
    });
  }
  findByNoteId(noteId) {
    return __async(this, null, function* () {
      const content = yield this.db.sql().selectFrom("content").where("noteId", "==", noteId).selectAll().executeTakeFirst();
      if (!content || isDeleted(content)) return;
      if (!content.locked && this.preProcess(content)) {
        yield this.collection.update([content.id], content, { modify: false });
      }
      return content;
    });
  }
  // multi(ids: string[]) {
  //   return this.collection.getItems(ids);
  // }
  exists(id) {
    return this.collection.exists(id);
  }
  // async all() {
  //   return Object.values(
  //     await this.collection.getItems(this.collection.indexer.indices)
  //   );
  // }
  downloadMedia(groupId, contentItem, notify = true) {
    return __async(this, null, function* () {
      const content = getContentFromData(contentItem.type, contentItem.data);
      if (!content) return contentItem;
      contentItem.data = yield content.insertMedia((hashes) => __async(this, null, function* () {
        const attachments = yield this.db.attachments.all.where((eb) => eb("attachments.hash", "in", hashes)).items();
        yield this.db.fs().queueDownloads(
          attachments.map((a) => ({
            filename: a.hash,
            chunkSize: a.chunkSize
          })),
          groupId,
          notify ? { readOnDownload: false } : void 0
        );
        const sources = {};
        for (const attachment of attachments) {
          const src = yield this.db.attachments.read(
            attachment.hash,
            getOutputType(attachment)
          );
          if (!src || typeof src !== "string") continue;
          sources[attachment.hash] = src;
        }
        return sources;
      }));
      return contentItem;
    });
  }
  removeAttachments(id, hashes) {
    return __async(this, null, function* () {
      const contentItem = yield this.get(id);
      if (!contentItem || isCipher(contentItem.data)) return;
      const content = getContentFromData(contentItem.type, contentItem.data);
      if (!content) return;
      contentItem.data = content.removeAttachments(hashes);
      yield this.add(contentItem);
    });
  }
  preProcess(content) {
    let changed = false;
    if (content.type === "tiny") {
      content.type = "tiptap";
      content.data = tinyToTiptap(content.data);
      changed = true;
    }
    if (!content.data.includes("data-block-id")) {
      content.data = getContentFromData(
        content.type,
        content.data
      ).insertBlockIds();
      changed = true;
    }
    return changed;
  }
  postProcess(contentItem) {
    return __async(this, null, function* () {
      const content = getContentFromData(contentItem.type, contentItem.data);
      if (!content) return contentItem.data;
      const { data, hashes, internalLinks } = yield content.postProcess(
        this.db.attachments.save.bind(this.db.attachments)
      );
      yield this.processInternalLinks(contentItem.noteId, internalLinks);
      yield this.processLinkedAttachments(contentItem.noteId, hashes);
      return data;
    });
  }
  processLinkedAttachments(noteId, hashes) {
    return __async(this, null, function* () {
      const noteAttachments = yield this.db.relations.from({ type: "note", id: noteId }, "attachment").selector.filter.select(["id", "hash"]).execute();
      const toDelete = noteAttachments.filter((attachment) => {
        return hashes.every((hash) => hash !== attachment.hash);
      });
      for (const attachment of toDelete) {
        yield this.db.relations.unlink(
          {
            id: noteId,
            type: "note"
          },
          { id: attachment.id, type: "attachment" }
        );
      }
      const toAdd = hashes.filter((hash) => {
        return hash && noteAttachments.every((a) => hash !== a.hash);
      });
      const attachments = yield this.db.attachments.all.fields(["attachments.id"]).where((eb) => eb("hash", "in", toAdd)).items();
      for (const attachment of attachments) {
        yield this.db.relations.add(
          {
            id: noteId,
            type: "note"
          },
          { id: attachment.id, type: "attachment" }
        );
      }
    });
  }
  processInternalLinks(noteId, internalLinks) {
    return __async(this, null, function* () {
      const links = yield this.db.relations.from({ type: "note", id: noteId }, "note").get();
      const toDelete = links.filter((link) => {
        return internalLinks.every((l) => l.id !== link.toId);
      });
      const toAdd = internalLinks.filter((link) => {
        return links.every((l) => link.id !== l.toId);
      });
      for (const link of toDelete) {
        yield this.db.relations.unlink(
          {
            id: noteId,
            type: "note"
          },
          { id: link.toId, type: link.toType }
        );
      }
      for (const link of toAdd) {
        const note = yield this.db.notes.exists(link.id);
        if (!note) continue;
        yield this.db.relations.add(
          {
            id: noteId,
            type: "note"
          },
          link
        );
      }
    });
  }
};

// src/utils/templates/html/languages/index.ts
function loadLanguage(language) {
  return __async(this, null, function* () {
    switch (language) {
      case "html":
      case "xml":
      case "svg":
      case "mathml":
      case "ssml":
      case "atom":
      case "rss":
      case "markup":
        return yield import("refractor/lang/markup.js");
      case "css":
        return yield import("refractor/lang/css.js");
      case "clike":
        return yield import("refractor/lang/clike.js");
      case "js":
      case "javascript":
        return yield import("refractor/lang/javascript.js");
      case "abap":
        return yield import("refractor/lang/abap.js");
      case "abnf":
        return yield import("refractor/lang/abnf.js");
      case "actionscript":
        return yield import("refractor/lang/actionscript.js");
      case "ada":
        return yield import("refractor/lang/ada.js");
      case "agda":
        return yield import("refractor/lang/agda.js");
      case "al":
        return yield import("refractor/lang/al.js");
      case "g4":
      case "antlr4":
        return yield import("refractor/lang/antlr4.js");
      case "apacheconf":
        return yield import("refractor/lang/apacheconf.js");
      case "apex":
        return yield import("refractor/lang/apex.js");
      case "apl":
        return yield import("refractor/lang/apl.js");
      case "applescript":
        return yield import("refractor/lang/applescript.js");
      case "aql":
        return yield import("refractor/lang/aql.js");
      case "ino":
      case "arduino":
        return yield import("refractor/lang/arduino.js");
      case "arff":
        return yield import("refractor/lang/arff.js");
      case "arm-asm":
      case "armasm":
        return yield import("refractor/lang/armasm.js");
      case "art":
      case "arturo":
        return yield import("refractor/lang/arturo.js");
      case "adoc":
      case "asciidoc":
        return yield import("refractor/lang/asciidoc.js");
      case "aspnet":
        return yield import("refractor/lang/aspnet.js");
      case "asm6502":
        return yield import("refractor/lang/asm6502.js");
      case "asmatmel":
        return yield import("refractor/lang/asmatmel.js");
      case "autohotkey":
        return yield import("refractor/lang/autohotkey.js");
      case "autoit":
        return yield import("refractor/lang/autoit.js");
      case "avs":
      case "avisynth":
        return yield import("refractor/lang/avisynth.js");
      case "avdl":
      case "avro-idl":
        return yield import("refractor/lang/avro-idl.js");
      case "gawk":
      case "awk":
        return yield import("refractor/lang/awk.js");
      case "sh":
      case "shell":
      case "bash":
        return yield import("refractor/lang/bash.js");
      case "basic":
        return yield import("refractor/lang/basic.js");
      case "batch":
        return yield import("refractor/lang/batch.js");
      case "shortcode":
      case "bbcode":
        return yield import("refractor/lang/bbcode.js");
      case "bbj":
        return yield import("refractor/lang/bbj.js");
      case "bicep":
        return yield import("refractor/lang/bicep.js");
      case "birb":
        return yield import("refractor/lang/birb.js");
      case "bison":
        return yield import("refractor/lang/bison.js");
      case "rbnf":
      case "bnf":
        return yield import("refractor/lang/bnf.js");
      case "bqn":
        return yield import("refractor/lang/bqn.js");
      case "brainfuck":
        return yield import("refractor/lang/brainfuck.js");
      case "brightscript":
        return yield import("refractor/lang/brightscript.js");
      case "bro":
        return yield import("refractor/lang/bro.js");
      case "oscript":
      case "bsl":
        return yield import("refractor/lang/bsl.js");
      case "c":
        return yield import("refractor/lang/c.js");
      case "cs":
      case "dotnet":
      case "csharp":
        return yield import("refractor/lang/csharp.js");
      case "cpp":
        return yield import("refractor/lang/cpp.js");
      case "cfc":
      case "cfscript":
        return yield import("refractor/lang/cfscript.js");
      case "chaiscript":
        return yield import("refractor/lang/chaiscript.js");
      case "cil":
        return yield import("refractor/lang/cil.js");
      case "cilk-c":
      case "cilkc":
        return yield import("refractor/lang/cilkc.js");
      case "cilk-cpp":
      case "cilk":
      case "cilkcpp":
        return yield import("refractor/lang/cilkcpp.js");
      case "clojure":
        return yield import("refractor/lang/clojure.js");
      case "cmake":
        return yield import("refractor/lang/cmake.js");
      case "cobol":
        return yield import("refractor/lang/cobol.js");
      case "coffee":
      case "coffeescript":
        return yield import("refractor/lang/coffeescript.js");
      case "conc":
      case "concurnas":
        return yield import("refractor/lang/concurnas.js");
      case "csp":
        return yield import("refractor/lang/csp.js");
      case "cooklang":
        return yield import("refractor/lang/cooklang.js");
      case "coq":
        return yield import("refractor/lang/coq.js");
      case "crystal":
        return yield import("refractor/lang/crystal.js");
      case "css-extras":
        return yield import("refractor/lang/css-extras.js");
      case "csv":
        return yield import("refractor/lang/csv.js");
      case "cue":
        return yield import("refractor/lang/cue.js");
      case "cypher":
        return yield import("refractor/lang/cypher.js");
      case "d":
        return yield import("refractor/lang/d.js");
      case "dart":
        return yield import("refractor/lang/dart.js");
      case "dataweave":
        return yield import("refractor/lang/dataweave.js");
      case "dax":
        return yield import("refractor/lang/dax.js");
      case "dhall":
        return yield import("refractor/lang/dhall.js");
      case "diff":
        return yield import("refractor/lang/diff.js");
      case "jinja2":
      case "django":
        return yield import("refractor/lang/django.js");
      case "dns-zone":
      case "dns-zone-file":
        return yield import("refractor/lang/dns-zone-file.js");
      case "dockerfile":
      case "docker":
        return yield import("refractor/lang/docker.js");
      case "gv":
      case "dot":
        return yield import("refractor/lang/dot.js");
      case "ebnf":
        return yield import("refractor/lang/ebnf.js");
      case "editorconfig":
        return yield import("refractor/lang/editorconfig.js");
      case "eiffel":
        return yield import("refractor/lang/eiffel.js");
      case "eta":
      case "ejs":
        return yield import("refractor/lang/ejs.js");
      case "elixir":
        return yield import("refractor/lang/elixir.js");
      case "elm":
        return yield import("refractor/lang/elm.js");
      case "etlua":
        return yield import("refractor/lang/etlua.js");
      case "erb":
        return yield import("refractor/lang/erb.js");
      case "erlang":
        return yield import("refractor/lang/erlang.js");
      case "xlsx":
      case "xls":
      case "excel-formula":
        return yield import("refractor/lang/excel-formula.js");
      case "fsharp":
        return yield import("refractor/lang/fsharp.js");
      case "factor":
        return yield import("refractor/lang/factor.js");
      case "false":
        return yield import("refractor/lang/false.js");
      case "firestore-security-rules":
        return yield import("refractor/lang/firestore-security-rules.js");
      case "flow":
        return yield import("refractor/lang/flow.js");
      case "fortran":
        return yield import("refractor/lang/fortran.js");
      case "ftl":
        return yield import("refractor/lang/ftl.js");
      case "gamemakerlanguage":
      case "gml":
        return yield import("refractor/lang/gml.js");
      case "gap":
        return yield import("refractor/lang/gap.js");
      case "gcode":
        return yield import("refractor/lang/gcode.js");
      case "gdscript":
        return yield import("refractor/lang/gdscript.js");
      case "gedcom":
        return yield import("refractor/lang/gedcom.js");
      case "po":
      case "gettext":
        return yield import("refractor/lang/gettext.js");
      case "gherkin":
        return yield import("refractor/lang/gherkin.js");
      case "git":
        return yield import("refractor/lang/git.js");
      case "glsl":
        return yield import("refractor/lang/glsl.js");
      case "gni":
      case "gn":
        return yield import("refractor/lang/gn.js");
      case "ld":
      case "linker-script":
        return yield import("refractor/lang/linker-script.js");
      case "go":
        return yield import("refractor/lang/go.js");
      case "go-mod":
      case "go-module":
        return yield import("refractor/lang/go-module.js");
      case "gradle":
        return yield import("refractor/lang/gradle.js");
      case "graphql":
        return yield import("refractor/lang/graphql.js");
      case "groovy":
        return yield import("refractor/lang/groovy.js");
      case "haml":
        return yield import("refractor/lang/haml.js");
      case "hbs":
      case "mustache":
      case "handlebars":
        return yield import("refractor/lang/handlebars.js");
      case "hs":
      case "haskell":
        return yield import("refractor/lang/haskell.js");
      case "haxe":
        return yield import("refractor/lang/haxe.js");
      case "hcl":
        return yield import("refractor/lang/hcl.js");
      case "hlsl":
        return yield import("refractor/lang/hlsl.js");
      case "hoon":
        return yield import("refractor/lang/hoon.js");
      case "http":
        return yield import("refractor/lang/http.js");
      case "hpkp":
        return yield import("refractor/lang/hpkp.js");
      case "hsts":
        return yield import("refractor/lang/hsts.js");
      case "ichigojam":
        return yield import("refractor/lang/ichigojam.js");
      case "icon":
        return yield import("refractor/lang/icon.js");
      case "icu-message-format":
        return yield import("refractor/lang/icu-message-format.js");
      case "idr":
      case "idris":
        return yield import("refractor/lang/idris.js");
      case "gitignore":
      case "hgignore":
      case "npmignore":
      case "ignore":
        return yield import("refractor/lang/ignore.js");
      case "inform7":
        return yield import("refractor/lang/inform7.js");
      case "ini":
        return yield import("refractor/lang/ini.js");
      case "io":
        return yield import("refractor/lang/io.js");
      case "j":
        return yield import("refractor/lang/j.js");
      case "java":
        return yield import("refractor/lang/java.js");
      case "javadoc":
        return yield import("refractor/lang/javadoc.js");
      case "javadoclike":
        return yield import("refractor/lang/javadoclike.js");
      case "javastacktrace":
        return yield import("refractor/lang/javastacktrace.js");
      case "jexl":
        return yield import("refractor/lang/jexl.js");
      case "jolie":
        return yield import("refractor/lang/jolie.js");
      case "jq":
        return yield import("refractor/lang/jq.js");
      case "jsdoc":
        return yield import("refractor/lang/jsdoc.js");
      case "js-extras":
        return yield import("refractor/lang/js-extras.js");
      case "webmanifest":
      case "json":
        return yield import("refractor/lang/json.js");
      case "json5":
        return yield import("refractor/lang/json5.js");
      case "jsonp":
        return yield import("refractor/lang/jsonp.js");
      case "jsstacktrace":
        return yield import("refractor/lang/jsstacktrace.js");
      case "js-templates":
        return yield import("refractor/lang/js-templates.js");
      case "julia":
        return yield import("refractor/lang/julia.js");
      case "keepalived":
        return yield import("refractor/lang/keepalived.js");
      case "keyman":
        return yield import("refractor/lang/keyman.js");
      case "kt":
      case "kts":
      case "kotlin":
        return yield import("refractor/lang/kotlin.js");
      case "kum":
      case "kumir":
        return yield import("refractor/lang/kumir.js");
      case "kusto":
        return yield import("refractor/lang/kusto.js");
      case "tex":
      case "context":
      case "latex":
        return yield import("refractor/lang/latex.js");
      case "latte":
        return yield import("refractor/lang/latte.js");
      case "less":
        return yield import("refractor/lang/less.js");
      case "ly":
      case "lilypond":
        return yield import("refractor/lang/lilypond.js");
      case "liquid":
        return yield import("refractor/lang/liquid.js");
      case "emacs":
      case "elisp":
      case "emacs-lisp":
      case "lisp":
        return yield import("refractor/lang/lisp.js");
      case "livescript":
        return yield import("refractor/lang/livescript.js");
      case "llvm":
        return yield import("refractor/lang/llvm.js");
      case "log":
        return yield import("refractor/lang/log.js");
      case "lolcode":
        return yield import("refractor/lang/lolcode.js");
      case "lua":
        return yield import("refractor/lang/lua.js");
      case "magma":
        return yield import("refractor/lang/magma.js");
      case "makefile":
        return yield import("refractor/lang/makefile.js");
      case "md":
      case "markdown":
        return yield import("refractor/lang/markdown.js");
      case "markup-templating":
        return yield import("refractor/lang/markup-templating.js");
      case "mata":
        return yield import("refractor/lang/mata.js");
      case "matlab":
        return yield import("refractor/lang/matlab.js");
      case "maxscript":
        return yield import("refractor/lang/maxscript.js");
      case "mel":
        return yield import("refractor/lang/mel.js");
      case "mermaid":
        return yield import("refractor/lang/mermaid.js");
      case "metafont":
        return yield import("refractor/lang/metafont.js");
      case "mizar":
        return yield import("refractor/lang/mizar.js");
      case "mongodb":
        return yield import("refractor/lang/mongodb.js");
      case "monkey":
        return yield import("refractor/lang/monkey.js");
      case "moon":
      case "moonscript":
        return yield import("refractor/lang/moonscript.js");
      case "n1ql":
        return yield import("refractor/lang/n1ql.js");
      case "n4jsd":
      case "n4js":
        return yield import("refractor/lang/n4js.js");
      case "nand2tetris-hdl":
        return yield import("refractor/lang/nand2tetris-hdl.js");
      case "nani":
      case "naniscript":
        return yield import("refractor/lang/naniscript.js");
      case "nasm":
        return yield import("refractor/lang/nasm.js");
      case "neon":
        return yield import("refractor/lang/neon.js");
      case "nevod":
        return yield import("refractor/lang/nevod.js");
      case "nginx":
        return yield import("refractor/lang/nginx.js");
      case "nim":
        return yield import("refractor/lang/nim.js");
      case "nix":
        return yield import("refractor/lang/nix.js");
      case "nsis":
        return yield import("refractor/lang/nsis.js");
      case "objc":
      case "objectivec":
        return yield import("refractor/lang/objectivec.js");
      case "ocaml":
        return yield import("refractor/lang/ocaml.js");
      case "odin":
        return yield import("refractor/lang/odin.js");
      case "opencl":
        return yield import("refractor/lang/opencl.js");
      case "qasm":
      case "openqasm":
        return yield import("refractor/lang/openqasm.js");
      case "oz":
        return yield import("refractor/lang/oz.js");
      case "parigp":
        return yield import("refractor/lang/parigp.js");
      case "parser":
        return yield import("refractor/lang/parser.js");
      case "objectpascal":
      case "pascal":
        return yield import("refractor/lang/pascal.js");
      case "pascaligo":
        return yield import("refractor/lang/pascaligo.js");
      case "psl":
        return yield import("refractor/lang/psl.js");
      case "px":
      case "pcaxis":
        return yield import("refractor/lang/pcaxis.js");
      case "pcode":
      case "peoplecode":
        return yield import("refractor/lang/peoplecode.js");
      case "perl":
        return yield import("refractor/lang/perl.js");
      case "php":
        return yield import("refractor/lang/php.js");
      case "phpdoc":
        return yield import("refractor/lang/phpdoc.js");
      case "php-extras":
        return yield import("refractor/lang/php-extras.js");
      case "plantuml":
      case "plant-uml":
        return yield import("refractor/lang/plant-uml.js");
      case "plsql":
        return yield import("refractor/lang/plsql.js");
      case "pq":
      case "mscript":
      case "powerquery":
        return yield import("refractor/lang/powerquery.js");
      case "powershell":
        return yield import("refractor/lang/powershell.js");
      case "processing":
        return yield import("refractor/lang/processing.js");
      case "prolog":
        return yield import("refractor/lang/prolog.js");
      case "promql":
        return yield import("refractor/lang/promql.js");
      case "properties":
        return yield import("refractor/lang/properties.js");
      case "protobuf":
        return yield import("refractor/lang/protobuf.js");
      case "pug":
        return yield import("refractor/lang/pug.js");
      case "puppet":
        return yield import("refractor/lang/puppet.js");
      case "pure":
        return yield import("refractor/lang/pure.js");
      case "pbfasm":
      case "purebasic":
        return yield import("refractor/lang/purebasic.js");
      case "purs":
      case "purescript":
        return yield import("refractor/lang/purescript.js");
      case "py":
      case "python":
        return yield import("refractor/lang/python.js");
      case "qs":
      case "qsharp":
        return yield import("refractor/lang/qsharp.js");
      case "q":
        return yield import("refractor/lang/q.js");
      case "qml":
        return yield import("refractor/lang/qml.js");
      case "qore":
        return yield import("refractor/lang/qore.js");
      case "r":
        return yield import("refractor/lang/r.js");
      case "rkt":
      case "racket":
        return yield import("refractor/lang/racket.js");
      case "razor":
      case "cshtml":
        return yield import("refractor/lang/cshtml.js");
      case "jsx":
        return yield import("refractor/lang/jsx.js");
      case "tsx":
        return yield import("refractor/lang/tsx.js");
      case "reason":
        return yield import("refractor/lang/reason.js");
      case "regex":
        return yield import("refractor/lang/regex.js");
      case "rego":
        return yield import("refractor/lang/rego.js");
      case "rpy":
      case "renpy":
        return yield import("refractor/lang/renpy.js");
      case "res":
      case "rescript":
        return yield import("refractor/lang/rescript.js");
      case "rest":
        return yield import("refractor/lang/rest.js");
      case "rip":
        return yield import("refractor/lang/rip.js");
      case "roboconf":
        return yield import("refractor/lang/roboconf.js");
      case "robot":
      case "robotframework":
        return yield import("refractor/lang/robotframework.js");
      case "rb":
      case "ruby":
        return yield import("refractor/lang/ruby.js");
      case "rust":
        return yield import("refractor/lang/rust.js");
      case "sas":
        return yield import("refractor/lang/sas.js");
      case "sass":
        return yield import("refractor/lang/sass.js");
      case "scss":
        return yield import("refractor/lang/scss.js");
      case "scala":
        return yield import("refractor/lang/scala.js");
      case "scheme":
        return yield import("refractor/lang/scheme.js");
      case "sh-session":
      case "shellsession":
      case "shell-session":
        return yield import("refractor/lang/shell-session.js");
      case "smali":
        return yield import("refractor/lang/smali.js");
      case "smalltalk":
        return yield import("refractor/lang/smalltalk.js");
      case "smarty":
        return yield import("refractor/lang/smarty.js");
      case "smlnj":
      case "sml":
        return yield import("refractor/lang/sml.js");
      case "sol":
      case "solidity":
        return yield import("refractor/lang/solidity.js");
      case "sln":
      case "solution-file":
        return yield import("refractor/lang/solution-file.js");
      case "soy":
        return yield import("refractor/lang/soy.js");
      case "rq":
      case "sparql":
        return yield import("refractor/lang/sparql.js");
      case "splunk-spl":
        return yield import("refractor/lang/splunk-spl.js");
      case "sqf":
        return yield import("refractor/lang/sqf.js");
      case "sql":
        return yield import("refractor/lang/sql.js");
      case "squirrel":
        return yield import("refractor/lang/squirrel.js");
      case "stan":
        return yield import("refractor/lang/stan.js");
      case "stata":
        return yield import("refractor/lang/stata.js");
      case "iecst":
        return yield import("refractor/lang/iecst.js");
      case "stylus":
        return yield import("refractor/lang/stylus.js");
      case "sclang":
      case "supercollider":
        return yield import("refractor/lang/supercollider.js");
      case "swift":
        return yield import("refractor/lang/swift.js");
      case "systemd":
        return yield import("refractor/lang/systemd.js");
      case "t4-templating":
        return yield import("refractor/lang/t4-templating.js");
      case "t4":
      case "t4-cs":
        return yield import("refractor/lang/t4-cs.js");
      case "t4-vb":
        return yield import("refractor/lang/t4-vb.js");
      case "tap":
        return yield import("refractor/lang/tap.js");
      case "tcl":
        return yield import("refractor/lang/tcl.js");
      case "tt2":
        return yield import("refractor/lang/tt2.js");
      case "textile":
        return yield import("refractor/lang/textile.js");
      case "toml":
        return yield import("refractor/lang/toml.js");
      case "trickle":
      case "troy":
      case "tremor":
        return yield import("refractor/lang/tremor.js");
      case "trig":
      case "turtle":
        return yield import("refractor/lang/turtle.js");
      case "twig":
        return yield import("refractor/lang/twig.js");
      case "ts":
      case "typescript":
        return yield import("refractor/lang/typescript.js");
      case "tsconfig":
      case "typoscript":
        return yield import("refractor/lang/typoscript.js");
      case "uscript":
      case "uc":
      case "unrealscript":
        return yield import("refractor/lang/unrealscript.js");
      case "uorazor":
        return yield import("refractor/lang/uorazor.js");
      case "url":
      case "uri":
        return yield import("refractor/lang/uri.js");
      case "v":
        return yield import("refractor/lang/v.js");
      case "vala":
        return yield import("refractor/lang/vala.js");
      case "vbnet":
        return yield import("refractor/lang/vbnet.js");
      case "velocity":
        return yield import("refractor/lang/velocity.js");
      case "verilog":
        return yield import("refractor/lang/verilog.js");
      case "vhdl":
        return yield import("refractor/lang/vhdl.js");
      case "vim":
        return yield import("refractor/lang/vim.js");
      case "vb":
      case "vba":
      case "visual-basic":
        return yield import("refractor/lang/visual-basic.js");
      case "warpscript":
        return yield import("refractor/lang/warpscript.js");
      case "wasm":
        return yield import("refractor/lang/wasm.js");
      case "webidl":
      case "web-idl":
        return yield import("refractor/lang/web-idl.js");
      case "wgsl":
        return yield import("refractor/lang/wgsl.js");
      case "wiki":
        return yield import("refractor/lang/wiki.js");
      case "mathematica":
      case "nb":
      case "wl":
      case "wolfram":
        return yield import("refractor/lang/wolfram.js");
      case "wren":
        return yield import("refractor/lang/wren.js");
      case "xeoracube":
      case "xeora":
        return yield import("refractor/lang/xeora.js");
      case "xml-doc":
        return yield import("refractor/lang/xml-doc.js");
      case "xojo":
        return yield import("refractor/lang/xojo.js");
      case "xquery":
        return yield import("refractor/lang/xquery.js");
      case "yml":
      case "yaml":
        return yield import("refractor/lang/yaml.js");
      case "yang":
        return yield import("refractor/lang/yang.js");
      case "zig":
        return yield import("refractor/lang/zig.js");
    }
  });
}

// src/utils/templates/html/template.ts
function template(data) {
  return `<!DOCTYPE html>
<html lang="en-US">
  <head>
    <meta charset="utf-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=Edge,chrome=1" />
    <meta
      name="description"
      content="${data.headline}"
    />
    <title>${data.title}</title>
    <meta name="created-at" content="${formatDate(data.dateCreated)}" />
    <meta name="updated-at" content="${formatDate(data.dateEdited)}" />
    ${data.pinned ? `<meta name="pinned" content="${data.pinned}" />` : ""}
    ${data.favorite ? `<meta name="favorite" content="${data.favorite}" />` : ""}
    ${data.color ? `<meta name="color" content="${data.color}" />` : ""}
    ${data.tags && data.tags.length ? `<meta name="tags" content="${data.tags.join(", ")}" />` : ""}
    <link rel="stylesheet" href="https://app.notesnook.com/assets/editor-styles.css?d=1690887574068">

    <style>

    .image-container {
      display: block;
    }
    .image-container.align-right {
      display: flex;
      justify-content: end;
    }
    .image-container.align-center {
      display: flex;
      justify-content: center;
    }
    .image-container.float {
      float: left;
    }
    .image-container.float.align-right {
      float: right;
    }

    body {
      background-color: transparent !important;
      color: #202124;
      font-family: "Open Sans", "Noto Sans", Frutiger, Calibri, Myriad, Arial, Ubuntu, Helvetica, -apple-system, BlinkMacSystemFont, sans-serif;
    }

    .math-block {
      padding-top: 20px;
      padding-bottom: 20px;
    }
    
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: #212121;
    }

    p {
      margin-bottom: 0px;
    }
    
    p[data-spacing="double"] {
      margin-top: 1em;
    }
    
    p[data-spacing="single"] {
      margin-top: 0px;
    }

    p[data-spacing="single"]:empty {
      margin-top: 1em;
    }
    
    pre.codeblock {
      overflow-x: auto;
    }
    
    iframe {
      max-width: 100% !important;
      background-color: transparent !important;
    }
    
    li > p {
      margin-top: 0px;
      margin-bottom: 10px;
    }
    
    .checklist > li {
      list-style: none;
      margin: 0.25em 0;
    }
    
    .checklist > li::before {
      content: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%3E%3Cg%20id%3D%22checklist-unchecked%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Crect%20id%3D%22Rectangle%22%20width%3D%2215%22%20height%3D%2215%22%20x%3D%22.5%22%20y%3D%22.5%22%20fill-rule%3D%22nonzero%22%20stroke%3D%22%234C4C4C%22%20rx%3D%222%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E%0A");
      cursor: pointer;
      height: 1.1em;
      margin-left: -2.5em;
      margin-top: 0em;
      position: absolute;
      width: 1.5em;
      padding-left: 1em;
    }
    
    .checklist li.checked::before {
      content: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%3E%3Cg%20id%3D%22checklist-checked%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Crect%20id%3D%22Rectangle%22%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22%23008837%22%20fill-rule%3D%22nonzero%22%20rx%3D%222%22%2F%3E%3Cpath%20id%3D%22Path%22%20fill%3D%22%23FFF%22%20fill-rule%3D%22nonzero%22%20d%3D%22M11.5703186%2C3.14417309%20C11.8516238%2C2.73724603%2012.4164781%2C2.62829933%2012.83558%2C2.89774797%20C13.260121%2C3.17069355%2013.3759736%2C3.72932262%2013.0909105%2C4.14168582%20L7.7580587%2C11.8560195%20C7.43776896%2C12.3193404%206.76483983%2C12.3852142%206.35607322%2C11.9948725%20L3.02491697%2C8.8138662%20C2.66090143%2C8.46625845%202.65798871%2C7.89594698%203.01850234%2C7.54483354%20C3.373942%2C7.19866177%203.94940006%2C7.19592841%204.30829608%2C7.5386474%20L6.85276923%2C9.9684299%20L11.5703186%2C3.14417309%20Z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E%0A");
    }
    
    .checklist li.checked {
      color: #505050;
    }
    
    [dir="rtl"] .checklist > li::before {
      margin-left: 0;
      margin-right: -1.5em;
    }
    
    blockquote {
      border-left: 5px solid #e5e5e5;
      padding-left: 10px;
      margin-top: 0px;
    }
    
    code:not(pre code) {
      background-color: #f7f7f7;
      border: 1px solid #e5e5e5;
      border-radius: 5px;
      padding: 3px 5px 0px 5px;
      font-family: Hack, Consolas, "Andale Mono", "Lucida Console", "Liberation Mono", "Courier New", Courier, monospace !important;
      font-size: 10pt !important;
    }
    
    .ProseMirror code > span {
      font-family: Hack, Consolas, "Andale Mono", "Lucida Console", "Liberation Mono", "Courier New", Courier, monospace !important;
    }
    
    pre {
      padding: 10px;
      background-color: #e5e5e5;
      border-radius: 5px;
      font-family: Hack, Consolas, "Andale Mono", "Lucida Console", "Liberation Mono", "Courier New", Courier, monospace !important;
        margin-bottom: 16px !important;
    }
    
    table {
      border-collapse: collapse;
      margin: 0;
      overflow: hidden;
      table-layout: fixed;
    }
    
    table td,
    table th {
      border: 1px solid #e5e5e5;
      box-sizing: border-box;
      min-width: 1em;
      padding: 3px 5px;
      position: relative;
      vertical-align: top;
    }
    
    table td > *,
    table th > * {
      margin-bottom: 0;
    }
    
    table th {
      background-color: #f7f7f7;
      font-weight: bold;
      text-align: left;
    }
    table p {
      margin: 0;
    }
    </style>
    <style>
      code[class*=language-],pre[class*=language-]{color:#f8f8f2;background:0 0;text-shadow:0 1px rgba(0,0,0,.3);font-family:Consolas,Monaco,'Andale Mono','Ubuntu Mono',monospace;text-align:left;white-space:pre;word-spacing:normal;word-break:normal;word-wrap:normal;line-height:1.5;-moz-tab-size:4;-o-tab-size:4;tab-size:4;-webkit-hyphens:none;-moz-hyphens:none;-ms-hyphens:none;hyphens:none}pre[class*=language-]{padding:1em;margin:.5em 0;overflow:auto;border-radius:.3em}:not(pre)>code[class*=language-],pre[class*=language-]{background:#282a36}:not(pre)>code[class*=language-]{padding:.1em;border-radius:.3em;white-space:normal}.token.cdata,.token.comment,.token.doctype,.token.prolog{color:#6272a4}.token.punctuation{color:#f8f8f2}.namespace{opacity:.7}.token.constant,.token.deleted,.token.property,.token.symbol,.token.tag{color:#ff79c6}.token.boolean,.token.number{color:#bd93f9}.token.attr-name,.token.builtin,.token.char,.token.inserted,.token.selector,.token.string{color:#50fa7b}.language-css .token.string,.style .token.string,.token.entity,.token.operator,.token.url,.token.variable{color:#f8f8f2}.token.atrule,.token.attr-value,.token.class-name,.token.function{color:#f1fa8c}.token.keyword{color:#8be9fd}.token.important,.token.regex{color:#ffb86c}.token.bold,.token.important{font-weight:700}.token.italic{font-style:italic}.token.entity{cursor:help}
    </style>
  </head>
  <body>
    <h1>${data.title}</h1>
    ${data.content}
  </body>
</html>
`;
}

// src/utils/templates/html/index.ts
var replaceableAttributes = {
  'data-float="true" data-align="right"': 'align="right"',
  'data-float="true" data-align="left"': 'align="left"',
  'data-align="left"': 'style="margin-right:auto;margin-left:0;display: block;"',
  'data-align="right"': 'style="margin-left:auto;margin-right:0;display: block;"',
  'data-align="center"': 'style="margin-left:auto;margin-right:auto;display: block;"'
};
var LANGUAGE_REGEX = /(?:^|\s)lang(?:uage)?-([\w-]+)(?=\s|$)/i;
function buildHTML(templateData) {
  return __async(this, null, function* () {
    return template(yield preprocessHTML(templateData));
  });
}
function preprocessHTML(templateData) {
  return __async(this, null, function* () {
    var _a;
    const { content } = templateData;
    let html = content.replace(/<p([^>]*)><\/p>/gm, "<p$1><br/></p>");
    for (const attribute in replaceableAttributes) {
      const value = replaceableAttributes[attribute];
      while (html.includes(attribute)) html = html.replace(attribute, value);
    }
    const doc = parseHTML(html);
    if (!doc) throw new Error("Could not parse HTML to DOM.");
    const images = doc.querySelectorAll("img");
    for (const image of images) {
      const container = doc.createElement("span");
      container.append(image.cloneNode());
      for (const attr of image.attributes) {
        if (attr.name === "src" || attr.name === "height" || attr.name === "width")
          continue;
        container.setAttribute(attr.name, attr.value);
      }
      container.classList.add("image-container");
      image.replaceWith(container);
    }
    const mathBlocks = doc.querySelectorAll(".math-block.math-node");
    const mathInlines = doc.querySelectorAll(".math-inline.math-node");
    if (mathBlocks.length || mathInlines.length) {
      const katex = (yield import("katex")).default;
      yield import("katex/contrib/mhchem/mhchem.js");
      for (const mathBlock of mathBlocks) {
        const text = mathBlock.textContent || "";
        mathBlock.innerHTML = katex.renderToString(text, {
          displayMode: true,
          throwOnError: false
        });
      }
      for (const mathInline of mathInlines) {
        const text = mathInline.textContent || "";
        mathInline.innerHTML = katex.renderToString(text, {
          throwOnError: false,
          displayMode: false
        });
      }
    }
    const codeblocks = doc.querySelectorAll("pre > code");
    if (codeblocks.length) {
      const { default: prismjs } = yield import("prismjs");
      prismjs.register = (syntax) => {
        if (typeof syntax === "function") syntax(prismjs);
      };
      for (const codeblock of codeblocks) {
        if (!codeblock.parentElement) continue;
        const language = (_a = LANGUAGE_REGEX.exec(
          codeblock.parentElement.className
        )) == null ? void 0 : _a[1];
        if (!language) continue;
        const { default: grammar } = (yield loadLanguage(language)) || {};
        if (!grammar) continue;
        grammar(prismjs);
        if (!prismjs.languages[language]) continue;
        codeblock.innerHTML = prismjs.highlight(
          codeblock.textContent || "",
          prismjs.languages[language],
          language
        );
      }
    }
    templateData.content = doc.body.innerHTML;
    return templateData;
  });
}

// src/utils/templates/md.ts
var buildMarkdown = (data) => `# ${data.title}

${data.content}`;
var templateWithFrontmatter = (data) => `---
${buildFrontmatter(data)}
---

# ${data.title}

${data.content}`;
function buildFrontmatter(data) {
  const lines = [
    `title: ${JSON.stringify(data.title || "")}`,
    `created_at: ${formatDate(data.dateCreated)}`,
    `updated_at: ${formatDate(data.dateEdited)}`
  ];
  if (data.pinned) lines.push(`pinned: ${data.pinned}`);
  if (data.favorite) lines.push(`favorite: ${data.favorite}`);
  if (data.color) lines.push(`color: ${data.color}`);
  if (data.tags) lines.push(`tags: ${data.tags.join(", ")}`);
  return lines.join("\n");
}

// src/utils/templates/text.ts
function buildText(data) {
  return `${data.title}

  ${data.content}`;
}

// src/utils/templates/index.ts
function buildFromTemplate(format2, data) {
  return __async(this, null, function* () {
    switch (format2) {
      case "html":
        return buildHTML(data);
      case "md":
        return buildMarkdown(data);
      case "md-frontmatter":
        return templateWithFrontmatter(data);
      case "txt":
        return buildText(data);
      default:
        throw new Error("Unsupported format.");
    }
  });
}

// src/collections/notes.ts
var Notes = class {
  constructor(db2) {
    this.db = db2;
    this.name = "notes";
    this.totalNotes = 0;
    this.collection = new SQLCollection(
      db2.sql,
      db2.transaction,
      "notes",
      db2.eventManager,
      db2.sanitizer
    );
  }
  init() {
    return __async(this, null, function* () {
      yield this.collection.init();
      this.totalNotes = yield this.collection.count();
    });
  }
  add(item) {
    return __async(this, null, function* () {
      if (item.remote)
        throw new Error("Please use db.notes.merge to merge remote notes.");
      const id = item.id || getId();
      const isUpdating = item.id && (yield this.exists(item.id));
      if (!isUpdating && !item.content && !item.contentId && !item.title)
        throw new Error("Note must have a title or content.");
      yield this.db.transaction(() => __async(this, null, function* () {
        let contentId = item.contentId;
        let dateEdited = item.dateEdited;
        let headline = item.headline;
        if (item.content && item.content.data && item.content.type) {
          logger.debug("saving content", { id });
          const { type, data } = item.content;
          const content = getContentFromData(type, data);
          if (!content) throw new Error("Invalid content type.");
          headline = getNoteHeadline(content);
          dateEdited = Date.now();
          contentId = yield this.db.content.add(__spreadValues({
            noteId: id,
            sessionId: item.sessionId,
            id: contentId,
            dateEdited,
            type,
            data
          }, item.localOnly !== void 0 ? { localOnly: item.localOnly } : {}));
        } else if (contentId && item.localOnly !== void 0) {
          yield this.db.content.add({
            id: contentId,
            localOnly: !!item.localOnly
          });
        }
        if (typeof item.title !== "undefined") {
          item.title = item.title.replace(NEWLINE_STRIP_REGEX, " ");
          dateEdited = Date.now();
        }
        if (!isUpdating || item.title === "") {
          item.title = item.title || formatTitle(
            this.db.settings.getTitleFormat(),
            this.db.settings.getDateFormat(),
            this.db.settings.getTimeFormat(),
            (headline == null ? void 0 : headline.split(" ").splice(0, 10).join(" ")) || "",
            this.totalNotes
          );
        }
        if (isUpdating) {
          yield this.collection.update([id], {
            title: item.title,
            headline,
            contentId,
            pinned: item.pinned,
            favorite: item.favorite,
            localOnly: item.localOnly,
            conflicted: item.conflicted,
            readonly: item.readonly,
            dateEdited: item.dateEdited || dateEdited
          });
        } else {
          yield this.collection.upsert({
            id,
            type: "note",
            contentId,
            title: item.title,
            headline,
            pinned: item.pinned,
            favorite: item.favorite,
            localOnly: item.localOnly,
            conflicted: item.conflicted,
            readonly: item.readonly,
            dateCreated: item.dateCreated || Date.now(),
            dateEdited: item.dateEdited || dateEdited || Date.now()
          });
          this.totalNotes++;
        }
      }));
      return id;
    });
  }
  note(id) {
    return __async(this, null, function* () {
      const note = yield this.collection.get(id);
      if (!note || isTrashItem(note) || isDeleted(note)) return;
      return note;
    });
  }
  trashed(id) {
    return __async(this, null, function* () {
      const note = yield this.collection.get(id);
      if (note && (!isTrashItem(note) || isDeleted(note))) return;
      return note;
    });
  }
  // note(idOrNote: string | Note) {
  //   if (!idOrNote) return;
  //   const note =
  //     typeof idOrNote === "object" ? idOrNote : this.collection.get(idOrNote);
  //   if (!note || isTrashItem(note)) return;
  //   return createNoteModel(note, this.db);
  // }
  // get raw() {
  //   return this.collection.raw();
  // }
  get all() {
    var _a;
    return this.collection.createFilter(
      (qb) => qb.where(isFalse("dateDeleted")).where(isFalse("deleted")),
      (_a = this.db.options) == null ? void 0 : _a.batchSize
    );
  }
  // isTrashed(id: string) {
  //   return this.raw.find((item) => item.id === id && isTrashItem(item));
  // }
  // get trashed() {
  //   return this.raw.filter((item) =>
  //     isTrashItem(item)
  //   ) as BaseTrashItem<Note>[];
  // }
  get pinned() {
    var _a;
    return this.collection.createFilter(
      (qb) => qb.where(isFalse("dateDeleted")).where(isFalse("deleted")).where("pinned", "==", true),
      (_a = this.db.options) == null ? void 0 : _a.batchSize
    );
  }
  get conflicted() {
    var _a;
    return this.collection.createFilter(
      (qb) => qb.where(isFalse("dateDeleted")).where(isFalse("deleted")).where("conflicted", "==", true),
      (_a = this.db.options) == null ? void 0 : _a.batchSize
    );
  }
  get favorites() {
    var _a;
    return this.collection.createFilter(
      (qb) => qb.where(isFalse("dateDeleted")).where(isFalse("deleted")).where("favorite", "==", true),
      (_a = this.db.options) == null ? void 0 : _a.batchSize
    );
  }
  exists(id) {
    return this.collection.exists(id);
  }
  moveToTrash(...ids) {
    return this._delete(true, ...ids);
  }
  remove(...ids) {
    return this._delete(false, ...ids);
  }
  pin(state, ...ids) {
    return this.collection.update(ids, { pinned: state });
  }
  favorite(state, ...ids) {
    return this.collection.update(ids, { favorite: state });
  }
  readonly(state, ...ids) {
    return this.collection.update(ids, { readonly: state });
  }
  localOnly(state, ...ids) {
    return __async(this, null, function* () {
      yield this.db.transaction(() => __async(this, null, function* () {
        yield this.collection.update(ids, { localOnly: state });
        yield this.db.content.updateByNoteId({ localOnly: state }, ...ids);
      }));
    });
  }
  export(noteOrId, options) {
    return __async(this, null, function* () {
      var _a;
      const note = typeof noteOrId === "string" ? yield this.note(noteOrId) : noteOrId;
      if (!note) return false;
      const { format: format2, rawContent } = options;
      const contentString = rawContent || (yield (() => __async(this, null, function* () {
        let contentItem = options.contentItem;
        if (!contentItem) {
          const rawContent2 = yield this.db.content.findByNoteId(note.id);
          if (rawContent2 && rawContent2.locked) return false;
          contentItem = rawContent2 || EMPTY_CONTENT(note.id);
        }
        const { data, type } = (options == null ? void 0 : options.embedMedia) && format2 !== "txt" ? yield this.db.content.downloadMedia(
          `export-${note.id}`,
          contentItem,
          false
        ) : contentItem;
        const content = getContentFromData(type, data);
        return format2 === "html" ? content.toHTML() : format2 === "md" ? content.toMD() : content.toTXT();
      }))());
      if (!contentString) return false;
      const tags = (yield this.db.relations.to(note, "tag").resolve()).map(
        (tag) => tag.title
      );
      const color = (_a = (yield this.db.relations.to(note, "color").resolve(1)).at(
        0
      )) == null ? void 0 : _a.title;
      return (options == null ? void 0 : options.disableTemplate) ? contentString : buildFromTemplate(format2, __spreadProps(__spreadValues({}, note), {
        tags,
        color,
        content: contentString
      }));
    });
  }
  duplicate(...ids) {
    return __async(this, null, function* () {
      for (const id of ids) {
        const note = yield this.note(id);
        if (!note) continue;
        const content = note.contentId ? yield this.db.content.get(note.contentId) : void 0;
        const duplicateId = yield this.db.notes.add(__spreadProps(__spreadValues({}, clone(note)), {
          id: void 0,
          readonly: false,
          favorite: false,
          pinned: false,
          contentId: void 0,
          title: note.title + " (Copy)",
          dateEdited: void 0,
          dateCreated: void 0,
          dateModified: void 0
        }));
        const contentId = yield this.db.content.add(__spreadProps(__spreadValues({}, clone(content)), {
          id: void 0,
          noteId: duplicateId,
          dateResolved: void 0,
          dateEdited: void 0,
          dateCreated: void 0,
          dateModified: void 0
        }));
        yield this.db.notes.add({ id: duplicateId, contentId });
        for (const relation of yield this.db.relations.to(note).get()) {
          yield this.db.relations.add(
            { type: relation.fromType, id: relation.fromId },
            {
              id: duplicateId,
              type: "note"
            }
          );
        }
        for (const relation of yield this.db.relations.from(note).get()) {
          yield this.db.relations.add(
            {
              id: duplicateId,
              type: "note"
            },
            { type: relation.toType, id: relation.toId }
          );
        }
      }
    });
  }
  _delete(moveToTrash = true, ...ids) {
    return __async(this, null, function* () {
      if (ids.length <= 0) return;
      if (moveToTrash) {
        yield this.db.trash.add("note", ids);
      } else {
        yield this.db.transaction(() => __async(this, null, function* () {
          yield this.db.relations.unlinkOfType("note", ids);
          yield this.collection.softDelete(ids);
          yield this.db.content.removeByNoteId(...ids);
        }));
      }
      this.totalNotes = Math.max(0, this.totalNotes - ids.length);
    });
  }
  addToNotebook(notebookId, ...noteIds) {
    return __async(this, null, function* () {
      for (const noteId of noteIds) {
        yield this.db.relations.add(
          { id: notebookId, type: "notebook" },
          { type: "note", id: noteId }
        );
      }
    });
  }
  removeFromNotebook(notebookId, ...noteIds) {
    return __async(this, null, function* () {
      yield this.db.transaction(() => __async(this, null, function* () {
        for (const noteId of noteIds) {
          yield this.db.relations.unlink(
            { id: notebookId, type: "notebook" },
            { type: "note", id: noteId }
          );
        }
      }));
    });
  }
  removeFromAllNotebooks(...noteIds) {
    return __async(this, null, function* () {
      yield this.db.relations.to({ type: "note", ids: noteIds }, "notebook").unlink();
    });
  }
  contentBlocks(id) {
    return __async(this, null, function* () {
      const content = yield this.db.content.findByNoteId(id);
      if (!content || content.locked) return [];
      return getContentFromData(content.type, content.data).extract("blocks").blocks;
    });
  }
  contentBlocksWithLinks(id) {
    return __async(this, null, function* () {
      const content = yield this.db.content.findByNoteId(id);
      if (!content || content.locked) return [];
      return getContentFromData(content.type, content.data).extract(
        "blocksWithLink"
      ).blocks;
    });
  }
  internalLinks(id) {
    return __async(this, null, function* () {
      const content = yield this.db.content.findByNoteId(id);
      if (!content || content.locked) return [];
      return getContentFromData(content.type, content.data).extract(
        "internalLinks"
      ).internalLinks;
    });
  }
};
function getNoteHeadline(content) {
  return content.toHeadline();
}

// src/database/fs.ts
var FileStorage = class {
  constructor(fs, tokenManager) {
    this.fs = fs;
    this.tokenManager = tokenManager;
    this.id = Date.now();
    this.downloads = /* @__PURE__ */ new Map();
    this.uploads = /* @__PURE__ */ new Map();
    this.groups = {
      downloads: /* @__PURE__ */ new Map(),
      uploads: /* @__PURE__ */ new Map()
    };
  }
  queueDownloads(files, groupId, eventData) {
    return __async(this, null, function* () {
      const newFiles = yield this.fs.bulkExists(files.map((f) => f.filename));
      files = files.filter((f) => newFiles.includes(f.filename));
      if (files.length <= 0) return;
      let current = 0;
      const token = yield this.tokenManager.getAccessToken();
      const total = files.length;
      const group = this.groups.downloads.get(groupId) || /* @__PURE__ */ new Set();
      files.forEach((f) => group.add(f.filename));
      this.groups.downloads.set(groupId, group);
      for (const file of files) {
        current++;
        if (!group.has(file.filename)) {
          EV.publish(EVENTS.fileDownloaded, {
            success: false,
            groupId,
            filename: file.filename,
            eventData,
            current,
            total
          });
          continue;
        }
        const download = this.downloads.get(file.filename);
        if (download && download.operation) {
          logger.debug("[queueDownloads] duplicate download", {
            filename: file.filename,
            groupId
          });
          yield download.operation;
          continue;
        }
        const { filename, chunkSize } = file;
        if (yield this.exists(filename)) {
          EV.publish(EVENTS.fileDownloaded, {
            success: true,
            groupId,
            filename,
            eventData,
            current,
            total
          });
          continue;
        }
        EV.publish(EVENTS.fileDownload, {
          total,
          current,
          groupId,
          filename
        });
        const url = `${constants_default.API_HOST}/s3?name=${filename}`;
        const { execute, cancel } = this.fs.downloadFile(filename, {
          url,
          chunkSize,
          headers: { Authorization: `Bearer ${token}` }
        });
        file.cancel = cancel;
        file.operation = execute().catch(() => false).finally(() => {
          this.downloads.delete(filename);
          group.delete(filename);
        });
        this.downloads.set(filename, file);
        const result = yield file.operation;
        if (eventData)
          EV.publish(EVENTS.fileDownloaded, {
            success: result,
            total,
            current,
            groupId,
            filename,
            eventData
          });
      }
    });
  }
  queueUploads(files, groupId) {
    return __async(this, null, function* () {
      let current = 0;
      const token = yield this.tokenManager.getAccessToken();
      const total = files.length;
      const group = this.groups.uploads.get(groupId) || /* @__PURE__ */ new Set();
      files.forEach((f) => group.add(f.filename));
      this.groups.uploads.set(groupId, group);
      for (const file of files) {
        if (!group.has(file.filename)) continue;
        const upload = this.uploads.get(file.filename);
        if (upload && upload.operation) {
          logger.debug("[queueUploads] duplicate upload", {
            filename: file.filename,
            groupId
          });
          yield file.operation;
          continue;
        }
        const { filename, chunkSize } = file;
        let error = null;
        const url = `${constants_default.API_HOST}/s3?name=${filename}`;
        const { execute, cancel } = this.fs.uploadFile(filename, {
          chunkSize,
          url,
          headers: { Authorization: `Bearer ${token}` }
        });
        file.cancel = cancel;
        file.operation = execute().catch((e) => {
          logger.error(e, "failed to upload attachment", { hash: filename });
          error = e;
          return false;
        }).finally(() => {
          this.uploads.delete(filename);
          group.delete(filename);
        });
        EV.publish(EVENTS.fileUpload, {
          total,
          current,
          groupId,
          filename
        });
        this.uploads.set(filename, file);
        const result = yield file.operation;
        EV.publish(EVENTS.fileUploaded, {
          error,
          success: result,
          total,
          current: ++current,
          groupId,
          filename
        });
      }
    });
  }
  downloadFile(groupId, filename, chunkSize) {
    return __async(this, null, function* () {
      if (yield this.exists(filename)) return true;
      const download = this.downloads.get(filename);
      if (download && download.operation) {
        logger.debug("[downloadFile] duplicate download", { filename, groupId });
        return yield download.operation;
      }
      logger.debug("[downloadFile] downloading", { filename, groupId });
      const url = `${constants_default.API_HOST}/s3?name=${filename}`;
      const file = { filename, chunkSize };
      const token = yield this.tokenManager.getAccessToken();
      const group = this.groups.downloads.get(groupId) || /* @__PURE__ */ new Set();
      const { execute, cancel } = this.fs.downloadFile(filename, {
        url,
        chunkSize,
        headers: { Authorization: `Bearer ${token}` }
      });
      file.cancel = cancel;
      file.operation = execute().finally(() => {
        this.downloads.delete(filename);
        group.delete(filename);
      });
      this.downloads.set(filename, file);
      this.groups.downloads.set(groupId, group.add(filename));
      return yield file.operation;
    });
  }
  cancel(groupId) {
    return __async(this, null, function* () {
      const queues = [
        {
          type: "download",
          ids: this.groups.downloads.get(groupId),
          files: this.downloads
        },
        {
          type: "upload",
          ids: this.groups.uploads.get(groupId),
          files: this.uploads
        }
      ].filter((a) => !!a.ids);
      for (const queue of queues) {
        if (!queue.ids) continue;
        for (const filename of queue.ids) {
          const file = queue.files.get(filename);
          if (file == null ? void 0 : file.cancel) yield file.cancel("Operation canceled.");
          queue.ids.delete(filename);
        }
        if (queue.type === "download") {
          this.groups.downloads.delete(groupId);
          EV.publish(EVENTS.downloadCanceled, { groupId, canceled: true });
        } else if (queue.type === "upload") {
          this.groups.uploads.delete(groupId);
          EV.publish(EVENTS.uploadCanceled, { groupId, canceled: true });
        }
      }
    });
  }
  readEncrypted(filename, encryptionKey, cipherData) {
    return this.fs.readEncrypted(filename, encryptionKey, cipherData);
  }
  writeEncryptedBase64(data, encryptionKey, mimeType) {
    return this.fs.writeEncryptedBase64(data, encryptionKey, mimeType);
  }
  deleteFile(filename, localOnly = false) {
    return __async(this, null, function* () {
      if (localOnly) return yield this.fs.deleteFile(filename);
      const token = yield this.tokenManager.getAccessToken();
      const url = `${constants_default.API_HOST}/s3?name=${filename}`;
      return yield this.fs.deleteFile(filename, {
        url,
        headers: { Authorization: `Bearer ${token}` },
        chunkSize: 0
      });
    });
  }
  exists(filename) {
    return this.fs.exists(filename);
  }
  clear() {
    return this.fs.clearFileStorage();
  }
  hashBase64(data) {
    return this.fs.hashBase64(data);
  }
  getUploadedFileSize(filename) {
    return this.fs.getUploadedFileSize(filename);
  }
};

// src/collections/notebooks.ts
import { sql as sql6 } from "@streetwriters/kysely";
var Notebooks = class {
  constructor(db2) {
    this.db = db2;
    this.name = "notebooks";
    this.collection = new SQLCollection(
      db2.sql,
      db2.transaction,
      "notebooks",
      db2.eventManager,
      db2.sanitizer
    );
  }
  init() {
    return this.collection.init();
  }
  add(notebookArg) {
    return __async(this, null, function* () {
      if (!notebookArg) throw new Error("Notebook cannot be undefined or null.");
      if (notebookArg.remote)
        throw new Error(
          "Please use db.notebooks.merge to merge remote notebooks"
        );
      const id = notebookArg.id || getId();
      const oldNotebook = yield this.notebook(id);
      if (oldNotebook && isTrashItem(oldNotebook))
        throw new Error("Cannot modify trashed notebooks.");
      if (!oldNotebook && (yield this.all.count()) >= 20 && !(yield checkIsUserPremium(CHECK_IDS.notebookAdd)))
        return;
      const mergedNotebook = __spreadValues(__spreadValues({}, oldNotebook), notebookArg);
      if (!mergedNotebook.title)
        throw new Error("Notebook must contain a title.");
      yield this.collection.upsert({
        id,
        type: "notebook",
        title: mergedNotebook.title,
        description: mergedNotebook.description,
        pinned: !!mergedNotebook.pinned,
        dateCreated: mergedNotebook.dateCreated || Date.now(),
        dateModified: mergedNotebook.dateModified || Date.now(),
        dateEdited: Date.now()
      });
      return id;
    });
  }
  // get raw() {
  //   return this.collection.raw();
  // }
  get all() {
    var _a;
    return this.collection.createFilter(
      (qb) => qb.where(isFalse("dateDeleted")).where(isFalse("deleted")),
      (_a = this.db.options) == null ? void 0 : _a.batchSize
    );
  }
  get pinned() {
    var _a;
    return this.collection.createFilter(
      (qb) => qb.where(isFalse("dateDeleted")).where(isFalse("deleted")).where("pinned", "==", true),
      (_a = this.db.options) == null ? void 0 : _a.batchSize
    );
  }
  // get trashed() {
  //   return this.raw.filter((item) =>
  //     isTrashItem(item)
  //   ) as BaseTrashItem<Notebook>[];
  // }
  pin(state, ...ids) {
    return __async(this, null, function* () {
      yield this.collection.update(ids, { pinned: state });
    });
  }
  totalNotes(id) {
    return __async(this, null, function* () {
      const result = yield this.db.sql().withRecursive(
        `subNotebooks(id)`,
        (eb) => eb.selectNoFrom((eb2) => eb2.val(id).as("id")).unionAll(
          (eb2) => eb2.selectFrom(["relations", "subNotebooks"]).select("relations.toId as id").where("toType", "==", "notebook").where("fromType", "==", "notebook").whereRef("fromId", "==", "subNotebooks.id").where("toId", "not in", this.db.trash.cache.notebooks).$narrowType()
        )
      ).selectFrom("relations").where("toType", "==", "note").where("fromType", "==", "notebook").where(
        "fromId",
        "in",
        (eb) => eb.selectFrom("subNotebooks").select("subNotebooks.id")
      ).where("toId", "not in", this.db.trash.cache.notes).select((eb) => eb.fn.count("relations.toId").as("totalNotes")).executeTakeFirst();
      if (!result) return 0;
      return result.totalNotes;
    });
  }
  notes(id) {
    return __async(this, null, function* () {
      const result = yield this.db.sql().withRecursive(
        `subNotebooks(id)`,
        (eb) => eb.selectNoFrom((eb2) => eb2.val(id).as("id")).unionAll(
          (eb2) => eb2.selectFrom(["relations", "subNotebooks"]).select("relations.toId as id").where("toType", "==", "notebook").where("fromType", "==", "notebook").whereRef("fromId", "==", "subNotebooks.id").where("toId", "not in", this.db.trash.cache.notebooks).$narrowType()
        )
      ).selectFrom("relations").where("toType", "==", "note").where("fromType", "==", "notebook").where(
        "fromId",
        "in",
        (eb) => eb.selectFrom("subNotebooks").select("subNotebooks.id")
      ).where("toId", "not in", this.db.trash.cache.notes).select("relations.toId as id").$narrowType().execute();
      return result.map((i) => i.id);
    });
  }
  get roots() {
    var _a;
    return this.collection.createFilter(
      (qb) => qb.where(
        "id",
        "not in",
        (eb) => eb.selectFrom("relations").where("toType", "==", "notebook").where("fromType", "==", "notebook").select("relations.toId as id").$narrowType()
      ).where(isFalse("dateDeleted")).where(isFalse("deleted")),
      (_a = this.db.options) == null ? void 0 : _a.batchSize
    );
  }
  breadcrumbs(id) {
    return __async(this, null, function* () {
      const ids = yield this.db.sql().withRecursive(
        `subNotebooks(id)`,
        (eb) => eb.selectNoFrom((eb2) => eb2.val(id).as("id")).unionAll(
          (eb2) => eb2.selectFrom(["relations", "subNotebooks"]).select("relations.fromId as id").where("toType", "==", "notebook").where("fromType", "==", "notebook").whereRef("toId", "==", "subNotebooks.id").where("fromId", "not in", this.db.trash.cache.notebooks).$narrowType()
        )
      ).selectFrom("subNotebooks").select("id").execute();
      const records = yield this.all.fields(["notebooks.id", "notebooks.title"]).records(ids.map((i) => i.id));
      return ids.reverse().map((id2) => records[id2.id]).filter(Boolean);
    });
  }
  notebook(id) {
    return __async(this, null, function* () {
      const notebook = yield this.collection.get(id);
      if (!notebook || isTrashItem(notebook)) return;
      return notebook;
    });
  }
  find(title) {
    return this.all.find((eb) => eb("notebooks.title", "==", title));
  }
  exists(id) {
    return this.all.has(id);
  }
  moveToTrash(...ids) {
    return __async(this, null, function* () {
      yield this.db.transaction((tr) => __async(this, null, function* () {
        const query = tr.withRecursive(
          `subNotebooks(id)`,
          (eb) => eb.selectFrom(
            () => sql6`(VALUES ${sql6.join(
              ids.map((id) => sql6.raw(`('${id}')`))
            )})`.as("roots")
          ).selectAll().unionAll(
            (eb2) => eb2.selectFrom(["relations", "subNotebooks"]).select("relations.toId as id").where("toType", "==", "notebook").where("fromType", "==", "notebook").whereRef("fromId", "==", "subNotebooks.id").where("toId", "not in", this.db.trash.cache.notebooks).$narrowType()
          )
        ).selectFrom("subNotebooks").select("id");
        const subNotebookIds = (yield query.execute()).map((ref) => ref.id);
        deleteItems(subNotebookIds, ...ids);
        if (subNotebookIds.length > 0)
          yield this.db.trash.add("notebook", subNotebookIds, "app");
        yield this.db.trash.add("notebook", ids, "user");
      }));
    });
  }
  remove(...ids) {
    return __async(this, null, function* () {
      yield this.db.transaction(() => __async(this, null, function* () {
        yield this.db.relations.unlinkOfType("notebook", ids);
        yield this.collection.softDelete(ids);
      }));
    });
  }
};

// src/collections/trash.ts
import dayjs4 from "dayjs";
import { sql as sql7 } from "@streetwriters/kysely";
var Trash = class {
  constructor(db2) {
    this.db = db2;
    this.collections = ["notes", "notebooks"];
    this.cache = {
      notebooks: [],
      notes: []
    };
    this.userDeletedCache = {
      notebooks: [],
      notes: []
    };
  }
  init() {
    return __async(this, null, function* () {
      yield this.buildCache();
      yield this.cleanup();
    });
  }
  buildCache() {
    return __async(this, null, function* () {
      this.cache.notes = [];
      this.cache.notebooks = [];
      this.userDeletedCache.notes = [];
      this.userDeletedCache.notebooks = [];
      const result = yield this.db.sql().selectFrom("notes").where("type", "==", "trash").select(["id", sql7`'note'`.as("itemType"), "deletedBy"]).unionAll(
        (eb) => eb.selectFrom("notebooks").where("type", "==", "trash").select(["id", sql7`'notebook'`.as("itemType"), "deletedBy"])
      ).execute();
      for (const { id, itemType, deletedBy } of result) {
        if (itemType === "note") {
          this.cache.notes.push(id);
          if (deletedBy === "user") this.userDeletedCache.notes.push(id);
        } else if (itemType === "notebook") {
          this.cache.notebooks.push(id);
          if (deletedBy === "user") this.userDeletedCache.notebooks.push(id);
        }
      }
    });
  }
  cleanup() {
    return __async(this, null, function* () {
      const duration = this.db.settings.getTrashCleanupInterval();
      if (duration === -1 || !duration) return;
      const maxMs = dayjs4().subtract(duration, "days").toDate().getTime();
      const expiredItems = yield this.db.sql().selectNoFrom((eb) => [
        eb.selectFrom("notes").where("type", "==", "trash").where("dateDeleted", "<=", maxMs).select("id").as("noteId"),
        eb.selectFrom("notebooks").where("type", "==", "trash").where("dateDeleted", "<=", maxMs).select("id").as("notebookId")
      ]).execute();
      const { noteIds, notebookIds } = expiredItems.reduce(
        (ids, item) => {
          if (item.noteId) ids.noteIds.push(item.noteId);
          if (item.notebookId) ids.notebookIds.push(item.notebookId);
          return ids;
        },
        { noteIds: [], notebookIds: [] }
      );
      yield this._delete(noteIds, notebookIds);
    });
  }
  add(type, ids, deletedBy = "user") {
    return __async(this, null, function* () {
      if (type === "note") {
        yield this.db.notes.collection.update(ids, {
          type: "trash",
          itemType: "note",
          dateDeleted: Date.now(),
          deletedBy
        });
        this.cache.notes.push(...ids);
        if (deletedBy === "user") this.userDeletedCache.notes.push(...ids);
      } else if (type === "notebook") {
        yield this.db.notebooks.collection.update(ids, {
          type: "trash",
          itemType: "notebook",
          dateDeleted: Date.now(),
          deletedBy
        });
        this.cache.notebooks.push(...ids);
        if (deletedBy === "user") this.userDeletedCache.notebooks.push(...ids);
      }
    });
  }
  delete(...ids) {
    return __async(this, null, function* () {
      if (ids.length <= 0) return;
      const noteIds = ids.filter((id) => this.cache.notes.includes(id));
      const notebookIds = ids.filter((id) => this.cache.notebooks.includes(id));
      yield this._delete(noteIds, notebookIds);
    });
  }
  _delete(noteIds, notebookIds) {
    return __async(this, null, function* () {
      if (noteIds.length > 0) {
        for (const chunk of toChunks(noteIds, MAX_SQL_PARAMETERS)) {
          yield this.db.content.removeByNoteId(...chunk);
          yield this.db.noteHistory.clearSessions(...chunk);
          yield this.db.notes.remove(...chunk);
          deleteItems(this.cache.notes, ...chunk);
          deleteItems(this.userDeletedCache.notes, ...chunk);
        }
      }
      if (notebookIds.length > 0) {
        const ids = [...notebookIds, ...yield this.subNotebooks(notebookIds)];
        for (const chunk of toChunks(ids, MAX_SQL_PARAMETERS)) {
          yield this.db.notebooks.remove(...chunk);
          yield this.db.relations.unlinkOfType("notebook", chunk);
          deleteItems(this.cache.notebooks, ...chunk);
          deleteItems(this.userDeletedCache.notebooks, ...chunk);
        }
      }
    });
  }
  restore(...ids) {
    return __async(this, null, function* () {
      if (ids.length <= 0) return;
      const noteIds = ids.filter((id) => this.cache.notes.includes(id));
      const notebookIds = ids.filter((id) => this.cache.notebooks.includes(id));
      if (noteIds.length > 0) {
        yield this.db.notes.collection.update(noteIds, {
          type: "note",
          dateDeleted: null,
          itemType: null,
          deletedBy: null
        });
        deleteItems(this.cache.notes, ...noteIds);
        deleteItems(this.userDeletedCache.notes, ...noteIds);
      }
      if (notebookIds.length > 0) {
        const ids2 = [...notebookIds, ...yield this.subNotebooks(notebookIds)];
        yield this.db.notebooks.collection.update(ids2, {
          type: "notebook",
          dateDeleted: null,
          itemType: null,
          deletedBy: null
        });
        deleteItems(this.cache.notebooks, ...ids2);
        deleteItems(this.userDeletedCache.notebooks, ...ids2);
      }
    });
  }
  clear() {
    return __async(this, null, function* () {
      yield this._delete(this.cache.notes, this.cache.notebooks);
      this.cache = { notebooks: [], notes: [] };
      this.userDeletedCache = { notebooks: [], notes: [] };
    });
  }
  // synced(id: string) {
  //   // const [item] = this.getItem(id);
  //   if (item && item.itemType === "note") {
  //     const { contentId } = item;
  //     return !contentId || this.db.content.exists(contentId);
  //   } else return true;
  // }
  all(deletedBy) {
    return __async(this, null, function* () {
      return [
        ...yield this.trashedNotes(this.cache.notes, deletedBy),
        ...yield this.trashedNotebooks(this.cache.notebooks, deletedBy)
      ];
    });
  }
  trashedNotes(ids, deletedBy) {
    return __async(this, null, function* () {
      if (ids.length <= 0) return [];
      return yield this.db.sql().selectFrom("notes").where("type", "==", "trash").where("id", "in", ids).$if(!!deletedBy, (eb) => eb.where("deletedBy", "==", deletedBy)).selectAll().execute();
    });
  }
  trashedNotebooks(ids, deletedBy) {
    return __async(this, null, function* () {
      if (ids.length <= 0) return [];
      return yield this.db.sql().selectFrom("notebooks").where("type", "==", "trash").where("id", "in", ids).$if(!!deletedBy, (eb) => eb.where("deletedBy", "==", deletedBy)).selectAll().execute();
    });
  }
  grouped(options) {
    return __async(this, null, function* () {
      const ids = [
        ...this.userDeletedCache.notes,
        ...this.userDeletedCache.notebooks
      ];
      const selector = getSortSelectors(options)[options.sortDirection];
      return new VirtualizedGrouping(
        ids.length,
        this.db.options.batchSize,
        () => Promise.resolve(ids),
        (start, end) => __async(this, null, function* () {
          const slicedIds = ids.slice(start, end);
          const noteIds = slicedIds.filter(
            (id) => this.userDeletedCache.notes.includes(id)
          );
          const notebookIds = slicedIds.filter(
            (id) => this.userDeletedCache.notebooks.includes(id)
          );
          const items = [
            ...yield this.trashedNotes(noteIds),
            ...yield this.trashedNotebooks(notebookIds)
          ];
          items.sort(selector);
          return {
            ids: slicedIds,
            items
          };
        }),
        (items) => groupArray(items, createKeySelector(options)),
        () => __async(this, null, function* () {
          const items = yield this.all();
          items.sort(selector);
          return Array.from(
            groupArray(items, createKeySelector(options)).values()
          );
        })
      );
    });
  }
  /**
   *
   * @param {string} id
   */
  exists(id) {
    return this.cache.notebooks.includes(id) || this.cache.notes.includes(id);
  }
  subNotebooks(notebookIds) {
    return __async(this, null, function* () {
      const ids = yield this.db.sql().withRecursive(
        `subNotebooks(id)`,
        (eb) => eb.selectFrom(
          (eb2) => sql7`(VALUES ${sql7.join(
            notebookIds.map((id) => eb2.parens(sql7`${id}`))
          )})`.as("notebookIds")
        ).selectAll().unionAll(
          (eb2) => eb2.selectFrom(["relations", "subNotebooks"]).select("relations.toId as id").where("toType", "==", "notebook").where("fromType", "==", "notebook").whereRef("fromId", "==", "subNotebooks.id").where("toId", "not in", this.userDeletedCache.notebooks).$narrowType()
        )
      ).selectFrom("subNotebooks").select("id").where("id", "not in", notebookIds).execute();
      return ids.map((ref) => ref.id);
    });
  }
};

// src/api/token-manager.ts
import { withTimeout, Mutex } from "async-mutex";
var ENDPOINTS = {
  token: "/connect/token",
  revoke: "/connect/revocation",
  temporaryToken: "/account/token",
  logout: "/account/logout"
};
var REFRESH_TOKEN_MUTEX = withTimeout(
  new Mutex(),
  10 * 1e3,
  new Error("Timed out while refreshing access token.")
);
var TokenManager = class {
  constructor(storage) {
    this.storage = storage;
    this.logger = logger.scope("TokenManager");
  }
  getToken(renew = true, forceRenew = false) {
    return __async(this, null, function* () {
      const token = yield this.storage().read("token");
      if (!token || !token.access_token) return;
      this.logger.info("Access token requested", {
        accessToken: token.access_token.slice(0, 10)
      });
      const isExpired = renew && this._isTokenExpired(token);
      if (this._isTokenRefreshable(token) && (forceRenew || isExpired)) {
        yield this._refreshToken(forceRenew);
        return yield this.getToken(false, false);
      }
      return token;
    });
  }
  _isTokenExpired(token) {
    const { t, expires_in } = token;
    const expiryMs = t + expires_in * 1e3;
    return Date.now() >= expiryMs;
  }
  _isTokenRefreshable(token) {
    const { scope, refresh_token } = token;
    if (!refresh_token || !scope) return false;
    const scopes = scope.split(" ");
    return scopes.includes("offline_access") && Boolean(refresh_token);
  }
  getAccessToken() {
    return __async(this, arguments, function* (scopes = ["notesnook.sync", "IdentityServerApi"], forceRenew = false) {
      return yield getSafeToken(() => __async(this, null, function* () {
        const token = yield this.getToken(true, forceRenew);
        if (!token || !token.scope) return;
        if (!scopes.some((s) => token.scope.includes(s))) return;
        return token.access_token;
      }), "Error getting access token:");
    });
  }
  _refreshToken(forceRenew = false) {
    return __async(this, null, function* () {
      yield REFRESH_TOKEN_MUTEX.runExclusive(() => __async(this, null, function* () {
        this.logger.info("Refreshing access token");
        const token = yield this.getToken(false, false);
        if (!token) throw new Error("No access token found to refresh.");
        if (!forceRenew && !this._isTokenExpired(token)) {
          return;
        }
        const { refresh_token, scope } = token;
        if (!refresh_token || !scope) {
          EV.publish(EVENTS.userSessionExpired);
          this.logger.error(new Error("Token not found."));
          return;
        }
        const refreshTokenResponse = yield http_default.post(
          `${constants_default.AUTH_HOST}${ENDPOINTS.token}`,
          {
            refresh_token,
            grant_type: "refresh_token",
            scope,
            client_id: "notesnook"
          }
        );
        yield this.saveToken(refreshTokenResponse);
        EV.publish(EVENTS.tokenRefreshed);
      }));
    });
  }
  revokeToken() {
    return __async(this, null, function* () {
      const token = yield this.getToken();
      if (!token) return;
      const { access_token } = token;
      yield this.storage().delete("token");
      yield http_default.post(
        `${constants_default.AUTH_HOST}${ENDPOINTS.logout}`,
        null,
        access_token
      );
    });
  }
  saveToken(tokenResponse) {
    this.logger.info("Saving new token", tokenResponse);
    if (!tokenResponse || !tokenResponse.access_token) return;
    const token = __spreadProps(__spreadValues({}, tokenResponse), { t: Date.now() });
    return this.storage().write("token", token);
  }
  getAccessTokenFromAuthorizationCode(userId, authCode) {
    return __async(this, null, function* () {
      return yield this.saveToken(
        yield http_default.post(`${constants_default.AUTH_HOST}${ENDPOINTS.temporaryToken}`, {
          authorization_code: authCode,
          user_id: userId,
          client_id: "notesnook"
        })
      );
    });
  }
};
var token_manager_default = TokenManager;
function getSafeToken(action, errorMessage) {
  return __async(this, null, function* () {
    try {
      return yield action();
    } catch (e) {
      logger.error(e, errorMessage);
      if (e instanceof Error && (e.message === "invalid_grant" || e.message === "invalid_client")) {
        EV.publish(EVENTS.userSessionExpired);
      }
      throw e;
    }
  });
}

// src/api/sync/types.ts
var SYNC_COLLECTIONS_MAP = {
  settingitem: "settings",
  attachment: "attachments",
  content: "content",
  notebook: "notebooks",
  shortcut: "shortcuts",
  reminder: "reminders",
  relation: "relations",
  tag: "tags",
  color: "colors",
  note: "notes",
  vault: "vaults"
};
var SYNC_ITEM_TYPES = Object.keys(
  SYNC_COLLECTIONS_MAP
);

// src/api/sync/collector.ts
var Collector = class {
  constructor(db2) {
    this.db = db2;
    this.logger = logger.scope("SyncCollector");
  }
  hasUnsyncedChanges() {
    return __async(this, null, function* () {
      for (const itemType of SYNC_ITEM_TYPES) {
        const collectionKey = SYNC_COLLECTIONS_MAP[itemType];
        const collection = this.db[collectionKey].collection;
        if ((yield collection.unsyncedCount()) > 0) return true;
      }
      return false;
    });
  }
  collect(chunkSize, isForceSync = false) {
    return __asyncGenerator(this, null, function* () {
      const key = yield new __await(this.db.user.getEncryptionKey());
      if (!key || !key.key || !key.salt) {
        EV.publish(EVENTS.userSessionExpired);
        throw new Error("User encryption key not generated. Please relogin.");
      }
      for (const itemType of SYNC_ITEM_TYPES) {
        const collectionKey = SYNC_COLLECTIONS_MAP[itemType];
        const collection = this.db[collectionKey].collection;
        let pushTimestamp = Date.now();
        try {
          for (var iter = __forAwait(collection.unsynced(chunkSize, isForceSync)), more, temp, error; more = !(temp = yield new __await(iter.next())).done; more = false) {
            const chunk = temp.value;
            const { ids, items: syncableItems } = filterSyncableItems(chunk);
            if (!ids.length) continue;
            const ciphers = yield new __await(this.db.storage().encryptMulti(key, syncableItems));
            const items = toPushItem(ids, ciphers);
            if (!items) continue;
            yield { items, type: itemType };
            yield new __await(this.db.sql().updateTable(collection.type).where("id", "in", ids).where("dateModified", "<=", pushTimestamp).set({ synced: true }).execute());
            pushTimestamp = Date.now();
          }
        } catch (temp) {
          error = [temp];
        } finally {
          try {
            more && (temp = iter.return) && (yield new __await(temp.call(iter)));
          } finally {
            if (error)
              throw error[0];
          }
        }
      }
    });
  }
};
var collector_default = Collector;
function toPushItem(ids, ciphers) {
  if (ids.length !== ciphers.length)
    throw new Error("ids.length must be equal to ciphers.length");
  const items = [];
  for (let i = 0; i < ids.length; ++i) {
    const id = ids[i];
    const cipher = ciphers[i];
    items.push(__spreadProps(__spreadValues({}, cipher), { v: CURRENT_DATABASE_VERSION, id }));
  }
  return items;
}
function filterSyncableItems(items) {
  if (!items || !items.length) return { items: [], ids: [] };
  const ids = [];
  const syncableItems = [];
  for (const item of items) {
    delete item.synced;
    ids.push(item.id);
    syncableItems.push(
      JSON.stringify(
        "localOnly" in item && item.localOnly ? {
          id: item.id,
          deleted: true,
          dateModified: item.dateModified
        } : item
      )
    );
  }
  return { items: syncableItems, ids };
}

// src/api/sync/index.ts
import * as signalr from "@microsoft/signalr";

// src/api/sync/merger.ts
var THRESHOLD = process.env.NODE_ENV === "test" ? 6 * 1e3 : 60 * 1e3;
var Merger = class {
  constructor(db2) {
    this.db = db2;
    this.logger = logger.scope("Merger");
  }
  // isSyncCollection(type: string): type is keyof typeof SYNC_COLLECTIONS_MAP {
  //   return type in SYNC_COLLECTIONS_MAP;
  // }
  mergeItem(remoteItem, localItem) {
    if (!localItem || remoteItem.dateModified > localItem.dateModified) {
      return remoteItem;
    }
  }
  mergeContent(remoteItem, localItem) {
    if (localItem && "localOnly" in localItem && localItem.localOnly) return;
    if (!localItem || isDeleted(localItem) || isDeleted(remoteItem) || remoteItem.type !== "tiptap" || localItem.type !== "tiptap" || localItem.locked || remoteItem.locked || !localItem.data || !remoteItem.data) {
      return this.mergeItem(remoteItem, localItem);
    } else {
      const conflicted = localItem.conflicted ? "conflict" : isContentConflicted(localItem, remoteItem, THRESHOLD);
      if (conflicted === "merge") return remoteItem;
      else if (!conflicted) return;
      this.logger.info("conflict marked", { id: localItem.noteId });
      localItem.conflicted = remoteItem;
      return localItem;
    }
  }
  mergeAttachment(remoteItem, localItem) {
    return __async(this, null, function* () {
      if (!localItem || isDeleted(localItem) || isDeleted(remoteItem) || !localItem.dateUploaded || !remoteItem.dateUploaded || localItem.dateUploaded === remoteItem.dateUploaded) {
        return this.mergeItem(remoteItem, localItem);
      }
      if (localItem.dateUploaded > remoteItem.dateUploaded) return;
      logger.debug("Removing local attachment file due to conflict", {
        hash: localItem.hash
      });
      const isRemoved = yield this.db.fs().deleteFile(localItem.hash, true);
      if (!isRemoved)
        throw new Error(
          "Conflict could not be resolved in one of the attachments."
        );
      return remoteItem;
    });
  }
};
var merger_default = Merger;
function isContentConflicted(localItem, remoteItem, conflictThreshold) {
  const isResolved = localItem.dateResolved && remoteItem.dateModified && localItem.dateResolved === remoteItem.dateModified;
  const isEdited = (
    // the local item is edited if it wasn't synced yet.
    !localItem.synced
  );
  if (isEdited && !isResolved) {
    const timeDiff = Math.max(remoteItem.dateEdited, localItem.dateEdited) - Math.min(remoteItem.dateEdited, localItem.dateEdited);
    if (timeDiff < conflictThreshold || isHTMLEqual(localItem.data, remoteItem.data)) {
      if (remoteItem.dateModified > localItem.dateModified) {
        return "merge";
      }
      return;
    }
    return "conflict";
  } else if (!isResolved) {
    return "merge";
  }
}

// src/api/sync/auto-sync.ts
var AutoSync = class {
  constructor(db2, interval) {
    this.db = db2;
    this.interval = interval;
    this.timeout = 0;
    this.isAutoSyncing = false;
    this.logger = logger.scope("AutoSync");
  }
  start() {
    return __async(this, null, function* () {
      this.logger.info(`Auto sync requested`);
      if (this.isAutoSyncing) return;
      if (this.databaseUpdatedEvent) this.databaseUpdatedEvent.unsubscribe();
      this.isAutoSyncing = true;
      this.databaseUpdatedEvent = this.db.eventManager.subscribe(
        EVENTS.databaseUpdated,
        this.schedule.bind(this)
      );
      this.logger.info(`Auto sync started`);
    });
  }
  stop() {
    this.isAutoSyncing = false;
    clearTimeout(this.timeout);
    if (this.databaseUpdatedEvent) this.databaseUpdatedEvent.unsubscribe();
    this.logger.info(`Auto sync stopped`);
  }
  schedule(event) {
    if (event.collection === "notehistory" || event.collection === "sessioncontent" || (event.type === "upsert" || event.type === "update") && (event.item.remote || "localOnly" in event.item && event.item.localOnly || "failed" in event.item && event.item.failed || "dateUploaded" in event.item && event.item.dateUploaded))
      return;
    clearTimeout(this.timeout);
    const interval = (event.type === "update" || event.type === "upsert") && event.collection === "content" ? 100 : this.interval;
    this.timeout = setTimeout(() => {
      this.logger.info(
        `Sync requested (type=${event.type} collection=${event.collection})`
      );
      this.db.eventManager.publish(EVENTS.databaseSyncRequested, false, false);
    }, interval);
  }
};

// src/api/sync/index.ts
import { Mutex as Mutex2 } from "async-mutex";

// src/api/sync/devices.ts
var SyncDevices = class {
  constructor(kv, tokenManager) {
    this.kv = kv;
    this.tokenManager = tokenManager;
  }
  register() {
    return __async(this, null, function* () {
      const deviceId = getId();
      const url = `${constants_default.API_HOST}/devices?deviceId=${deviceId}`;
      const token = yield this.tokenManager.getAccessToken();
      return http_default.post(url, null, token).then(() => this.kv().write("deviceId", deviceId));
    });
  }
  unregister() {
    return __async(this, null, function* () {
      const deviceId = yield this.kv().read("deviceId");
      if (!deviceId) return;
      const url = `${constants_default.API_HOST}/devices?deviceId=${deviceId}`;
      const token = yield this.tokenManager.getAccessToken();
      return http_default.delete(url, token).then(() => this.kv().delete("deviceId"));
    });
  }
  get() {
    return this.kv().read("deviceId");
  }
};

// src/api/sync/index.ts
var SyncManager = class {
  constructor(db2) {
    this.db = db2;
    this.sync = new Sync(db2);
    this.devices = this.sync.devices;
  }
  start(options) {
    return __async(this, null, function* () {
      var _a;
      try {
        if (yield checkSyncStatus(SYNC_CHECK_IDS.autoSync))
          yield this.sync.autoSync.start();
        yield this.sync.start(options);
        return true;
      } catch (e) {
        const isHubException = e.message.includes("HubException:");
        if (isHubException) {
          const actualError = /HubException: (.*)/gm.exec(e.message);
          const errorText = actualError && actualError.length > 1 ? actualError[1] : e.message;
          if ((errorText.includes("Please confirm your email ") || errorText.includes("Invalid token.")) && ((_a = yield this.db.user.getUser()) == null ? void 0 : _a.isEmailConfirmed)) {
            yield this.db.tokenManager._refreshToken(true);
            return false;
          }
          throw new Error(errorText);
        }
        throw e;
      }
    });
  }
  acquireLock(callback) {
    return __async(this, null, function* () {
      try {
        this.sync.autoSync.stop();
        yield callback();
      } finally {
        yield this.sync.autoSync.start();
      }
    });
  }
  stop() {
    return __async(this, null, function* () {
      yield this.sync.cancel();
    });
  }
};
var Sync = class {
  constructor(db2) {
    this.db = db2;
    this.logger = logger.scope("Sync");
    this.syncConnectionMutex = new Mutex2();
    this.conflictedNoteIds = [];
    this.uncachedAttachments = [];
    this.collector = new collector_default(db2);
    this.merger = new merger_default(db2);
    this.autoSync = new AutoSync(db2, 1e3);
    this.devices = new SyncDevices(db2.kv, db2.tokenManager);
    EV.subscribe(EVENTS.userLoggedOut, () => __async(this, null, function* () {
      var _a;
      yield (_a = this.connection) == null ? void 0 : _a.stop();
      this.autoSync.stop();
    }));
  }
  start(options) {
    return __async(this, null, function* () {
      this.createConnection(options);
      if (!this.connection) return;
      if (!(yield checkSyncStatus(SYNC_CHECK_IDS.sync))) {
        yield this.connection.stop();
        return;
      }
      if (!(yield this.db.user.getUser())) return;
      this.logger.info("Starting sync", options);
      this.connection.onclose((error = new Error("Connection closed.")) => {
        this.db.eventManager.publish(EVENTS.syncAborted);
        this.logger.error(error);
        throw new Error("Connection closed.");
      });
      const { deviceId } = yield this.init(options.force);
      this.logger.info("Initialized sync", { deviceId });
      if (options.type === "fetch" || options.type === "full") {
        yield this.fetch(deviceId, options);
        this.logger.info("Data fetched");
      }
      if ((options.type === "send" || options.type === "full") && (yield this.send(deviceId, options.force)))
        this.logger.info("New data sent");
      yield this.stop(options);
      if (!(yield checkSyncStatus(SYNC_CHECK_IDS.autoSync))) {
        yield this.connection.stop();
        this.autoSync.stop();
      }
    });
  }
  init(isForceSync) {
    return __async(this, null, function* () {
      yield this.checkConnection();
      if (isForceSync) {
        yield this.devices.unregister();
        yield this.devices.register();
      }
      let deviceId = yield this.devices.get();
      if (!deviceId) {
        yield this.devices.register();
        deviceId = yield this.devices.get();
      }
      if (!deviceId) throw new Error("Sync device not registered.");
      return { deviceId };
    });
  }
  fetch(deviceId, options) {
    return __async(this, null, function* () {
      var _a;
      yield this.checkConnection();
      yield (_a = this.connection) == null ? void 0 : _a.invoke("RequestFetch", deviceId);
      if (this.conflictedNoteIds.length > 0) {
        yield this.db.sql().updateTable("notes").where("id", "in", this.conflictedNoteIds).set({ conflicted: true }).execute();
        this.conflictedNoteIds = [];
      }
      if (this.uncachedAttachments.length > 0 && options.offlineMode) {
        yield this.db.fs().queueDownloads(this.uncachedAttachments, "offline-mode", {
          readOnDownload: false
        });
        this.uncachedAttachments = [];
      }
    });
  }
  send(deviceId, isForceSync) {
    return __async(this, null, function* () {
      var _a;
      yield this.uploadAttachments();
      let done = 0;
      try {
        for (var iter = __forAwait(this.collector.collect(100, isForceSync)), more, temp, error; more = !(temp = yield iter.next()).done; more = false) {
          const item = temp.value;
          const result = yield this.pushItem(deviceId, item);
          if (result) {
            done += item.items.length;
            sendSyncProgressEvent(this.db.eventManager, "upload", done);
            this.logger.info(`Batch sent (${done})`);
          } else {
            this.logger.error(
              new Error(`Failed to send batch. Server returned falsy response.`)
            );
          }
        }
      } catch (temp) {
        error = [temp];
      } finally {
        try {
          more && (temp = iter.return) && (yield temp.call(iter));
        } finally {
          if (error)
            throw error[0];
        }
      }
      if (done > 0) yield (_a = this.connection) == null ? void 0 : _a.send("PushCompleted");
      return true;
    });
  }
  stop(options) {
    return __async(this, null, function* () {
      if ((options.type === "send" || options.type === "full") && (yield this.collector.hasUnsyncedChanges())) {
        this.logger.info("Changes made during last sync. Syncing again...");
        yield this.start({ type: "send" });
        return;
      }
      yield this.db.monographs.refresh().catch(this.logger.error);
      yield this.db.trash.buildCache();
      this.logger.info("Stopping sync");
      yield this.db.setLastSynced(Date.now());
      this.db.eventManager.publish(EVENTS.syncCompleted);
    });
  }
  cancel() {
    return __async(this, null, function* () {
      var _a;
      this.logger.info("Sync canceled");
      yield (_a = this.connection) == null ? void 0 : _a.stop();
    });
  }
  /**
   * @private
   */
  uploadAttachments() {
    return __async(this, null, function* () {
      const attachments = yield this.db.attachments.pending.items();
      this.logger.info("Uploading attachments...", { total: attachments.length });
      yield this.db.fs().queueUploads(
        attachments.map((a) => ({
          filename: a.hash,
          chunkSize: a.chunkSize
        })),
        "sync-uploads"
      );
    });
  }
  /**
   * @private
   */
  onPushCompleted() {
    return __async(this, null, function* () {
      this.db.eventManager.publish(EVENTS.databaseSyncRequested, true, false);
    });
  }
  processChunk(chunk, key, options) {
    return __async(this, null, function* () {
      const itemType = chunk.type;
      const decrypted = yield this.db.storage().decryptMulti(key, chunk.items);
      const deserialized = [];
      for (let i = 0; i < decrypted.length; ++i) {
        const decryptedItem = decrypted[i];
        const version = chunk.items[i].v;
        const item = yield deserializeItem(
          decryptedItem,
          itemType,
          version,
          this.db
        );
        if (item) deserialized.push(item);
      }
      const collectionType = SYNC_COLLECTIONS_MAP[itemType];
      const collection = this.db[collectionType].collection;
      const localItems = yield collection.records(chunk.items.map((i) => i.id));
      let items = [];
      if (itemType === "content") {
        items = deserialized.map(
          (item) => this.merger.mergeContent(item, localItems[item.id])
        );
      } else {
        items = itemType === "attachment" ? yield Promise.all(
          deserialized.map(
            (item) => this.merger.mergeAttachment(
              item,
              localItems[item.id]
            )
          )
        ) : deserialized.map(
          (item) => this.merger.mergeItem(item, localItems[item.id])
        );
      }
      if (itemType === "note" || itemType === "content") {
        items.forEach(
          (item) => this.db.eventManager.publish(EVENTS.syncItemMerged, item)
        );
        for (const item of items)
          if (!(item == null ? void 0 : item.deleted) && (item == null ? void 0 : item.type) === "tiptap" && !!item.conflicted)
            this.conflictedNoteIds.push(item.noteId);
      }
      if (itemType === "attachment" && options.offlineMode) {
        for (const item of items)
          if (!(item == null ? void 0 : item.deleted) && (item == null ? void 0 : item.type) === "attachment")
            this.uncachedAttachments.push({
              filename: item.hash,
              chunkSize: item.chunkSize
            });
      }
      yield collection.put(items);
    });
  }
  pushItem(deviceId, item) {
    return __async(this, null, function* () {
      var _a;
      yield this.checkConnection();
      return (yield (_a = this.connection) == null ? void 0 : _a.invoke("PushItems", deviceId, item)) === 1;
    });
  }
  createConnection(options) {
    if (this.connection) return;
    const tokenManager = new token_manager_default(this.db.kv);
    this.connection = new signalr.HubConnectionBuilder().withUrl(`${constants_default.API_HOST}/hubs/sync/v2`, {
      accessTokenFactory: () => __async(this, null, function* () {
        const token = yield tokenManager.getAccessToken();
        if (!token) throw new Error("Failed to get access token.");
        return token;
      }),
      skipNegotiation: true,
      transport: signalr.HttpTransportType.WebSockets,
      logger: {
        log: (level, message) => {
          const scopedLogger = logger.scope("SignalR::SyncHub");
          switch (level) {
            case signalr.LogLevel.Critical:
              return scopedLogger.fatal(new Error(message));
            case signalr.LogLevel.Error: {
              this.db.eventManager.publish(EVENTS.syncAborted, message);
              return scopedLogger.error(new Error(message));
            }
            case signalr.LogLevel.Warning:
              return scopedLogger.warn(message);
          }
        }
      }
    }).withHubProtocol(new signalr.JsonHubProtocol()).build();
    this.connection.serverTimeoutInMilliseconds = 60 * 1e3 * 5;
    this.connection.on("PushCompleted", () => this.onPushCompleted());
    this.connection.on("SendVaultKey", (vaultKey) => __async(this, null, function* () {
      var _a;
      if (((_a = this.connection) == null ? void 0 : _a.state) !== signalr.HubConnectionState.Connected)
        return false;
      if (vaultKey && vaultKey.cipher !== null && vaultKey.iv !== null && vaultKey.salt !== null && vaultKey.length > 0) {
        const vault = yield this.db.vaults.default();
        if (!vault)
          yield migrateVaultKey(
            this.db,
            vaultKey,
            5.9,
            CURRENT_DATABASE_VERSION
          );
      }
      return true;
    }));
    this.connection.on("SendItems", (chunk) => __async(this, null, function* () {
      var _a;
      if (((_a = this.connection) == null ? void 0 : _a.state) !== signalr.HubConnectionState.Connected)
        return false;
      const key = yield this.getKey();
      if (!key) return false;
      yield this.processChunk(chunk, key, options);
      sendSyncProgressEvent(this.db.eventManager, `download`, chunk.count);
      return true;
    }));
  }
  getKey() {
    return __async(this, null, function* () {
      const key = yield this.db.user.getEncryptionKey();
      if (!key || !key || !key) {
        this.logger.error(
          new Error("User encryption key not generated. Please relogin.")
        );
        EV.publish(EVENTS.userSessionExpired);
        return;
      }
      return key;
    });
  }
  checkConnection() {
    return __async(this, null, function* () {
      yield this.syncConnectionMutex.runExclusive(() => __async(this, null, function* () {
        try {
          if (this.connection && this.connection.state !== signalr.HubConnectionState.Connected) {
            if (this.connection.state !== signalr.HubConnectionState.Disconnected) {
              yield this.connection.stop();
            }
            yield promiseTimeout(3e4, this.connection.start());
          }
        } catch (e) {
          this.logger.error(e, "Could not connect to the Sync server.");
          if (e instanceof Error) {
            this.logger.warn(e.message);
            throw new Error(
              "Could not connect to the Sync server. Please try again."
            );
          }
        }
      }));
    });
  }
};
function promiseTimeout(ms, promise) {
  const timeout = new Promise((resolve, reject) => {
    const id = setTimeout(() => {
      clearTimeout(id);
      reject(new Error("Sync timed out in " + ms + "ms."));
    }, ms);
  });
  return Promise.race([promise, timeout]);
}
function deserializeItem(decryptedItem, type, version, database) {
  return __async(this, null, function* () {
    const item = JSON.parse(decryptedItem);
    item.remote = true;
    item.synced = true;
    let migrationResult = yield migrateItem(
      item,
      version,
      CURRENT_DATABASE_VERSION,
      isDeleted(item) ? type : item.type,
      database,
      "sync"
    );
    if (migrationResult === "skip") return;
    if (isTrashItem(item)) {
      migrationResult = yield migrateItem(
        item,
        version,
        CURRENT_DATABASE_VERSION,
        item.itemType,
        database,
        "sync"
      );
      if (migrationResult === "skip") return;
    }
    const itemType = isDeleted(item) ? type : (
      // colors are naively of type "tag" instead of "color" so we have to fix that.
      item.type === "tag" && DefaultColors[item.title.toLowerCase()] ? "color" : item.type === "trash" && "itemType" in item && item.itemType ? item.itemType : item.type
    );
    if (!itemType || itemType === "topic" || itemType === "settings") return;
    if (migrationResult) item.synced = false;
    return item;
  });
}

// src/api/vault.ts
var VAULT_ERRORS = {
  noVault: "ERR_NO_VAULT",
  vaultLocked: "ERR_VAULT_LOCKED",
  wrongPassword: "ERR_WRONG_PASSWORD"
};
var Vault2 = class {
  constructor(db2) {
    this.db = db2;
    this.eraseTime = 1e3 * 60 * 30;
    this.erasureTimeout = 0;
    this.key = "svvaads1212#2123";
    this.password = void 0;
    EV.subscribe(EVENTS.userLoggedOut, () => {
      this.password = void 0;
    });
  }
  get password() {
    return this.vaultPassword;
  }
  set password(value) {
    this.vaultPassword = value;
    if (value) {
      this.startEraser();
    }
  }
  startEraser() {
    clearTimeout(this.erasureTimeout);
    this.erasureTimeout = setTimeout(() => {
      this.password = void 0;
      EV.publish(EVENTS.vaultLocked);
    }, this.eraseTime);
  }
  get unlocked() {
    return !!this.vaultPassword;
  }
  create(password) {
    return __async(this, null, function* () {
      if (!(yield checkIsUserPremium(CHECK_IDS.vaultAdd))) return;
      const vaultKey = yield this.getKey();
      if (!vaultKey || !isCipher(vaultKey)) {
        const encryptedData = yield this.db.storage().encrypt({ password }, this.key);
        yield this.setKey(encryptedData);
        this.password = password;
      }
      return true;
    });
  }
  unlock(password) {
    return __async(this, null, function* () {
      const vaultKey = yield this.getKey();
      if (!vaultKey || !(yield this.exists(vaultKey)))
        throw new Error(VAULT_ERRORS.noVault);
      try {
        yield this.db.storage().decrypt({ password }, vaultKey);
      } catch (e) {
        throw new Error(VAULT_ERRORS.wrongPassword);
      }
      this.password = password;
      return true;
    });
  }
  changePassword(oldPassword, newPassword) {
    return __async(this, null, function* () {
      const vault = yield this.db.vaults.default();
      if (!vault) throw new Error(VAULT_ERRORS.noVault);
      if (yield this.unlock(oldPassword)) {
        const relations = yield this.db.relations.from(vault, "note").get();
        for (const { toId: noteId } of relations) {
          const content = yield this.db.content.findByNoteId(noteId);
          if (!content || !content.locked) {
            yield this.db.relations.unlink(vault, { id: noteId, type: "note" });
            continue;
          }
          try {
            const decryptedContent = yield this.decryptContent(
              content,
              oldPassword
            );
            yield this.encryptContent(
              decryptedContent,
              noteId,
              newPassword,
              `${Date.now()}`
            );
          } catch (e) {
            logger.error(e, `Could not decrypt content of note ${noteId}`);
            throw new Error(
              `Could not decrypt content of note ${noteId}. Error: ${e.message}`
            );
          }
        }
        yield this.db.vaults.add({
          id: vault.id,
          key: yield this.db.storage().encrypt({ password: newPassword }, this.key)
        });
      }
    });
  }
  clear(password) {
    return __async(this, null, function* () {
      const vault = yield this.db.vaults.default();
      if (!vault) return;
      if (yield this.unlock(password)) {
        const relations = yield this.db.relations.from(vault, "note").get();
        for (const { toId: noteId } of relations) {
          yield this.unlockNote(noteId, password, true);
          yield this.db.relations.unlink(vault, { id: noteId, type: "note" });
        }
      }
    });
  }
  delete(deleteAllLockedNotes = false) {
    return __async(this, null, function* () {
      const vault = yield this.db.vaults.default();
      if (!vault) return;
      if (deleteAllLockedNotes) {
        const relations = yield this.db.relations.from(vault, "note").get();
        const lockedIds = relations.map((r) => r.toId);
        yield this.db.notes.remove(...lockedIds);
      }
      yield this.db.vaults.remove(vault.id);
      this.password = void 0;
    });
  }
  /**
   * Locks (add to vault) a note
   */
  add(noteId) {
    return __async(this, null, function* () {
      if (!(yield checkIsUserPremium(CHECK_IDS.vaultAdd))) return;
      yield this.lockNote({ id: noteId }, yield this.getVaultPassword());
      yield this.db.noteHistory.clearSessions(noteId);
    });
  }
  /**
   * Permanently unlocks (remove from vault) a note
   */
  remove(noteId, password) {
    return __async(this, null, function* () {
      yield this.unlockNote(noteId, password, true);
      if (!(yield this.exists())) yield this.create(password);
      yield this.db.relations.to({ id: noteId, type: "note" }, "vault").unlink();
    });
  }
  /**
   * Temporarily unlock (open) a note
   */
  open(noteId, password) {
    return __async(this, null, function* () {
      const note = yield this.db.notes.note(noteId);
      if (!note) return;
      const content = yield this.unlockNote(
        noteId,
        password || this.password,
        false
      );
      if (password) {
        this.password = password;
        if (!(yield this.exists())) yield this.create(password);
      }
      return __spreadProps(__spreadValues({}, note), { content });
    });
  }
  /**
   * Saves a note in the vault
   */
  save(note) {
    return __async(this, null, function* () {
      if (!note) return;
      this.startEraser();
      return yield this.lockNote(note, yield this.getVaultPassword());
    });
  }
  exists(vaultKey) {
    return __async(this, null, function* () {
      if (!vaultKey) vaultKey = yield this.getKey();
      return !!vaultKey && isCipher(vaultKey);
    });
  }
  // Private & internal methods
  getVaultPassword() {
    return __async(this, null, function* () {
      if (!(yield this.exists())) {
        throw new Error(VAULT_ERRORS.noVault);
      }
      if (!this.password || !this.password.length) {
        throw new Error(VAULT_ERRORS.vaultLocked);
      }
      return this.password;
    });
  }
  encryptContent(content, noteId, password, sessionId) {
    return __async(this, null, function* () {
      const encryptedContent = yield this.db.storage().encrypt({ password }, JSON.stringify(content.data));
      return yield this.db.content.add({
        noteId,
        sessionId,
        data: encryptedContent,
        dateEdited: Date.now(),
        type: content.type
      });
    });
  }
  decryptContent(encryptedContent, password) {
    return __async(this, null, function* () {
      if (!password) password = yield this.getVaultPassword();
      if (!isCipher(encryptedContent.data))
        return encryptedContent;
      const decryptedContent = yield this.db.storage().decrypt({ password }, encryptedContent.data);
      return {
        type: encryptedContent.type,
        data: JSON.parse(decryptedContent)
      };
    });
  }
  lockNote(item, password) {
    return __async(this, null, function* () {
      const vault = yield this.db.vaults.default();
      if (!vault) throw new Error(VAULT_ERRORS.noVault);
      const { id, content, sessionId } = item;
      let { type, data } = content || {};
      const locked = yield this.db.relations.from(vault, "note").has(id);
      if (!locked && (!data || !type)) {
        const rawContent = yield this.db.content.findByNoteId(id);
        if (rawContent == null ? void 0 : rawContent.locked) {
          yield this.db.relations.add(vault, {
            id,
            type: "note"
          });
          return id;
        }
        data = (rawContent == null ? void 0 : rawContent.data) || "<p></p>";
        type = (rawContent == null ? void 0 : rawContent.type) || "tiptap";
      } else if (data && type) {
        data = yield this.db.content.postProcess({
          data,
          type,
          noteId: id
        });
      }
      yield this.db.notes.add({
        id,
        headline: "",
        dateEdited: Date.now(),
        contentId: data && type ? yield this.encryptContent({ data, type }, id, password, sessionId) : void 0
      });
      yield this.db.relations.add(vault, {
        id,
        type: "note"
      });
      return id;
    });
  }
  unlockNote(noteId, password, perm = false) {
    return __async(this, null, function* () {
      const content = yield this.db.content.findByNoteId(noteId);
      if (!content || !content.locked) {
        yield this.db.relations.to({ id: noteId, type: "note" }, "vault").unlink();
        return content ? { data: content.data, type: content.type } : void 0;
      }
      const decryptedContent = yield this.decryptContent(content, password);
      if (this.db.content.preProcess(decryptedContent)) {
        if (!password) password = yield this.getVaultPassword();
        yield this.encryptContent(
          decryptedContent,
          noteId,
          password,
          `${Date.now}`
        );
      }
      if (perm) {
        yield this.db.relations.to({ id: noteId, type: "note" }, "vault").unlink();
        yield this.db.notes.add({
          id: noteId,
          contentId: content.id,
          content: decryptedContent
        });
        return;
      }
      return decryptedContent;
    });
  }
  getKey() {
    return __async(this, null, function* () {
      const vault = yield this.db.vaults.default();
      return vault == null ? void 0 : vault.key;
    });
  }
  setKey(vaultKey) {
    return __async(this, null, function* () {
      const vault = yield this.db.vaults.default();
      if (vault) return;
      yield this.db.vaults.add({ title: "Default", key: vaultKey });
    });
  }
};

// src/api/lookup.ts
import { match } from "fuzzyjs";
import { sql as sql9 } from "@streetwriters/kysely";

// src/database/fts.ts
import { sql as sql8 } from "@streetwriters/kysely";
function rebuildSearchIndex(db2) {
  return __async(this, null, function* () {
    yield db2.transaction().execute((tx) => __async(this, null, function* () {
      for (const query of [
        sql8`INSERT INTO content_fts(content_fts) VALUES('delete-all')`,
        sql8`INSERT INTO notes_fts(notes_fts) VALUES('delete-all')`
      ]) {
        yield query.execute(tx);
      }
      yield tx.insertInto("content_fts").columns(["rowid", "id", "data", "noteId"]).expression(
        (eb) => eb.selectFrom("content").where(
          (eb2) => eb2.and([
            eb2("noteId", "is not", null),
            eb2("data", "is not", null),
            eb2("deleted", "is not", true)
          ])
        ).select([
          "rowid",
          "id",
          sql8`IIF(locked == 1, '', data)`.as("data"),
          "noteId"
        ])
      ).execute();
      yield tx.insertInto("notes_fts").columns(["rowid", "id", "title"]).expression(
        (eb) => eb.selectFrom("notes").where(
          (eb2) => eb2.and([eb2("title", "is not", null), eb2("deleted", "is not", true)])
        ).select(["rowid", "id", "title"])
      ).execute();
      for (const query of [
        sql8`INSERT INTO content_fts(content_fts) VALUES('optimize')`,
        sql8`INSERT INTO notes_fts(notes_fts) VALUES('optimize')`
      ]) {
        yield query.execute(tx);
      }
    }));
  });
}

// src/api/lookup.ts
var Lookup = class {
  constructor(db2) {
    this.db = db2;
  }
  notes(query, notes) {
    return this.toSearchResults((limit) => __async(this, null, function* () {
      const db2 = this.db.sql();
      const excludedIds = this.db.trash.cache.notes;
      if (query.length <= 2) {
        const results2 = yield db2.selectFrom(
          (eb) => eb.selectFrom("notes").$if(
            !!notes,
            (eb2) => eb2.where("id", "in", notes.filter.select("id"))
          ).$if(
            excludedIds.length > 0,
            (eb2) => eb2.where("id", "not in", excludedIds)
          ).where("title", "like", `%${query}%`).select(["id"]).unionAll(
            (eb2) => eb2.selectFrom("content").$if(
              !!notes,
              (eb3) => eb3.where("id", "in", notes.filter.select("id"))
            ).$if(
              excludedIds.length > 0,
              (eb3) => eb3.where("id", "not in", excludedIds)
            ).where("locked", "!=", true).where("data", "like", `%${query}%`).select(["noteId as id"]).$castTo()
          ).as("results")
        ).select(["results.id"]).groupBy("results.id").$if(!!limit, (eb) => eb.limit(limit)).where(
          "results.id",
          "in",
          (notes || this.db.notes.all).filter.select("id")
        ).execute().catch((e) => {
          logger.error(e, `Error while searching`, { query });
          return [];
        });
        return results2.map((r) => r.id);
      }
      query = transformQuery(query);
      const results = yield db2.selectFrom(
        (eb) => eb.selectFrom("notes_fts").$if(
          !!notes,
          (eb2) => eb2.where("id", "in", notes.filter.select("id"))
        ).$if(
          excludedIds.length > 0,
          (eb2) => eb2.where("id", "not in", excludedIds)
        ).where("title", "match", query).select(["id", sql9`rank * 10`.as("rank")]).unionAll(
          (eb2) => eb2.selectFrom("content_fts").$if(
            !!notes,
            (eb3) => eb3.where("id", "in", notes.filter.select("id"))
          ).$if(
            excludedIds.length > 0,
            (eb3) => eb3.where("id", "not in", excludedIds)
          ).where("data", "match", query).select(["noteId as id", "rank"]).$castTo()
        ).as("results")
      ).select(["results.id"]).groupBy("results.id").orderBy(sql9`SUM(results.rank)`, "desc").$if(!!limit, (eb) => eb.limit(limit)).where(
        "results.id",
        "in",
        (notes || this.db.notes.all).filter.select("id")
      ).execute().catch((e) => {
        logger.error(e, `Error while searching`, { query });
        return [];
      });
      return results.map((r) => r.id);
    }), notes || this.db.notes.all);
  }
  notebooks(query) {
    return this.search(this.db.notebooks.all, query, [
      { name: "id", column: "notebooks.id", weight: -100 },
      { name: "title", column: "notebooks.title", weight: 10 },
      { name: "description", column: "notebooks.description" }
    ]);
  }
  tags(query) {
    return this.search(this.db.tags.all, query, [
      { name: "id", column: "tags.id", weight: -100 },
      { name: "title", column: "tags.title" }
    ]);
  }
  reminders(query) {
    return this.search(this.db.reminders.all, query, [
      { name: "id", column: "reminders.id", weight: -100 },
      { name: "title", column: "reminders.title", weight: 10 },
      { name: "description", column: "reminders.description" }
    ]);
  }
  trash(query) {
    return {
      sorted: (limit) => __async(this, null, function* () {
        const { ids, items } = yield this.filterTrash(query, limit);
        return new VirtualizedGrouping(
          ids.length,
          this.db.options.batchSize,
          () => Promise.resolve(ids),
          (start, end) => __async(this, null, function* () {
            return {
              ids: ids.slice(start, end),
              items: items.slice(start, end)
            };
          })
        );
      }),
      items: (limit) => __async(this, null, function* () {
        const { items } = yield this.filterTrash(query, limit);
        return items;
      }),
      ids: () => this.filterTrash(query).then(({ ids }) => ids)
    };
  }
  attachments(query) {
    return this.search(this.db.attachments.all, query, [
      { name: "id", column: "attachments.id", weight: -100 },
      { name: "filename", column: "attachments.filename", weight: 5 },
      { name: "mimeType", column: "attachments.mimeType" },
      { name: "hash", column: "attachments.hash" }
    ]);
  }
  search(selector, query, fields) {
    return this.toSearchResults(
      (limit) => this.filter(selector, query, fields, limit),
      selector
    );
  }
  filter(selector, query, fields, limit) {
    return __async(this, null, function* () {
      const results = /* @__PURE__ */ new Map();
      const columns = fields.map((f) => f.column);
      try {
        for (var iter = __forAwait(selector.fields(columns)), more, temp, error; more = !(temp = yield iter.next()).done; more = false) {
          const item = temp.value;
          if (limit && results.size >= limit) break;
          for (const field of fields) {
            const result = match(query, `${item[field.name]}`);
            if (result.match) {
              const oldScore = results.get(item.id) || 0;
              results.set(item.id, oldScore + result.score * (field.weight || 1));
            }
          }
        }
      } catch (temp) {
        error = [temp];
      } finally {
        try {
          more && (temp = iter.return) && (yield temp.call(iter));
        } finally {
          if (error)
            throw error[0];
        }
      }
      selector.fields([]);
      return Array.from(results.entries()).sort((a, b) => a[1] - b[1]).map((a) => a[0]);
    });
  }
  toSearchResults(ids, selector) {
    return {
      sorted: (limit) => __async(this, null, function* () {
        return this.toVirtualizedGrouping(yield ids(limit), selector);
      }),
      items: (limit) => __async(this, null, function* () {
        return this.toItems(yield ids(limit), selector);
      }),
      ids
    };
  }
  filterTrash(query, limit) {
    return __async(this, null, function* () {
      const items = yield this.db.trash.all();
      const results = /* @__PURE__ */ new Map();
      for (const item of items) {
        if (limit && results.size >= limit) break;
        const result = match(query, item.title);
        if (result.match) {
          results.set(item.id, { rank: result.score, item });
        }
      }
      const sorted = Array.from(results.entries()).sort(
        (a, b) => a[1].rank - b[1].rank
      );
      return {
        ids: sorted.map((a) => a[0]),
        items: sorted.map((a) => a[1].item)
      };
    });
  }
  toVirtualizedGrouping(ids, selector) {
    return new VirtualizedGrouping(
      ids.length,
      this.db.options.batchSize,
      () => Promise.resolve(ids),
      (start, end) => __async(this, null, function* () {
        const items = yield selector.records(ids);
        return {
          ids: ids.slice(start, end),
          items: Object.values(items).slice(start, end)
        };
      })
    );
  }
  toItems(ids, selector) {
    if (!ids.length) return [];
    return selector.items(ids);
  }
  rebuild() {
    return __async(this, null, function* () {
      const db2 = this.db.sql();
      yield rebuildSearchIndex(db2);
    });
  }
};

// src/database/backup.ts
import SparkMD52 from "spark-md5";

// src/database/indexer.ts
var Indexer = class {
  constructor(storage, type) {
    this.storage = storage;
    this.type = type;
    this._indices = [];
  }
  init() {
    return __async(this, null, function* () {
      this._indices = (yield this.storage().read(this.type, true)) || [];
    });
  }
  exists(key) {
    return this.indices.includes(key);
  }
  index(key) {
    return __async(this, null, function* () {
      if (this.exists(key)) return;
      this.indices.push(key);
      yield this.storage().write(this.type, this.indices);
    });
  }
  get indices() {
    return this._indices;
  }
  deindex(key) {
    return __async(this, null, function* () {
      if (!this.exists(key)) return;
      this.indices.splice(this.indices.indexOf(key), 1);
      yield this.storage().write(this.type, this.indices);
    });
  }
  clear() {
    return __async(this, null, function* () {
      yield this.storage().removeMulti(
        this._indices.map((key) => this.makeId(key))
      );
      this._indices = [];
      yield this.storage().write(this.type, this._indices);
    });
  }
  read(key, isArray = false) {
    return __async(this, null, function* () {
      return yield this.storage().read(this.makeId(key), isArray);
    });
  }
  write(key, data) {
    return this.storage().write(this.makeId(key), data);
  }
  remove(key) {
    return this.storage().remove(this.makeId(key));
  }
  readMulti(keys) {
    return __async(this, null, function* () {
      const entries = yield this.storage().readMulti(
        keys.map(this.makeId, this)
      );
      entries.forEach((entry) => {
        entry[0] = entry[0].replace(`_${this.type}`, "");
      });
      return entries;
    });
  }
  /**
   *
   * @param {any[]} items
   * @returns
   */
  writeMulti(items) {
    return __async(this, null, function* () {
      const entries = items.map(
        ([id, item]) => {
          if (!this.indices.includes(id)) this.indices.push(id);
          return [this.makeId(id), item];
        }
      );
      entries.push([this.type, this.indices]);
      yield this.storage().writeMulti(entries);
    });
  }
  migrateIndices() {
    return __async(this, null, function* () {
      const keys = (yield this.storage().getAllKeys()).filter(
        (key) => !key.endsWith(`_${this.type}`) && this.exists(key)
      );
      for (const id of keys) {
        const item = yield this.storage().read(id);
        if (!item) continue;
        yield this.write(id, item);
      }
      for (const id of keys) {
        yield this.storage().remove(id);
      }
    });
  }
  makeId(id) {
    return `${id}_${this.type}`;
  }
};

// src/database/indexed-collection.ts
var IndexedCollection = class {
  constructor(storage, type) {
    this.indexer = new Indexer(storage, type);
  }
  clear() {
    return this.indexer.clear();
  }
  deleteItem(id) {
    return __async(this, null, function* () {
      yield this.indexer.deindex(id);
      return yield this.indexer.remove(id);
    });
  }
  init() {
    return __async(this, null, function* () {
      yield this.indexer.init();
    });
  }
  addItem(item) {
    return __async(this, null, function* () {
      yield this.indexer.write(item.id, item);
      yield this.indexer.index(item.id);
    });
  }
  exists(id) {
    return this.indexer.exists(id);
  }
  iterate(chunkSize) {
    return __asyncGenerator(this, null, function* () {
      const chunks = toChunks(this.indexer.indices, chunkSize);
      for (const chunk of chunks) {
        yield yield new __await(this.indexer.readMulti(chunk));
      }
    });
  }
};

// src/database/migrator.ts
var Migrator2 = class {
  migrate(db2, collections2, version) {
    return __async(this, null, function* () {
      if (version <= 5.9) {
        const vaultKey = yield db2.storage().read("vaultKey");
        if (vaultKey)
          yield migrateVaultKey(db2, vaultKey, version, CURRENT_DATABASE_VERSION);
        yield migrateKV(db2, version, CURRENT_DATABASE_VERSION);
      }
      for (const collection of collections2) {
        sendMigrationProgressEvent(db2.eventManager, collection.name, 0, 0);
        const table = new SQLCollection(
          db2.sql,
          db2.transaction,
          collection.table,
          db2.eventManager,
          db2.sanitizer
        );
        if (version <= 5.9) {
          if (collection.name === "settings") {
            const settings = yield db2.storage().read("settings");
            if (!settings) continue;
            yield migrateItem(
              settings,
              version,
              CURRENT_DATABASE_VERSION,
              "settings",
              db2,
              "local"
            );
            yield db2.storage().remove("settings");
          } else {
            const indexedCollection = new IndexedCollection(
              db2.storage,
              collection.name
            );
            yield migrateCollection(indexedCollection, version);
            yield indexedCollection.init();
            yield table.init();
            let count = 0;
            try {
              for (var iter = __forAwait(indexedCollection.iterate(100)), more, temp, error; more = !(temp = yield iter.next()).done; more = false) {
                const entries = temp.value;
                yield this.migrateToSQLite(
                  db2,
                  table,
                  entries.map((i) => i[1]),
                  version
                );
                sendMigrationProgressEvent(
                  db2.eventManager,
                  collection.name,
                  indexedCollection.indexer.indices.length,
                  count += 100
                );
              }
            } catch (temp) {
              error = [temp];
            } finally {
              try {
                more && (temp = iter.return) && (yield temp.call(iter));
              } finally {
                if (error)
                  throw error[0];
              }
            }
            yield indexedCollection.clear();
          }
        } else {
          yield table.init();
          yield this.migrateItems(db2, table, collection.name, version);
        }
      }
      yield db2.initCollections();
      return true;
    });
  }
  migrateToSQLite(db2, table, items, version) {
    return __async(this, null, function* () {
      const toAdd = [];
      for (let i = 0; i < items.length; ++i) {
        const item = items[i];
        if (Array.isArray(item)) {
          logger.debug("Skipping item during migration to SQLite", {
            table,
            version,
            item
          });
          continue;
        }
        if (!item) continue;
        let migrated = yield migrateItem(
          item,
          version,
          CURRENT_DATABASE_VERSION,
          isDeleted(item) ? "never" : item.type,
          db2,
          "local"
        );
        if (isTrashItem(item)) {
          migrated = yield migrateItem(
            item,
            version,
            CURRENT_DATABASE_VERSION,
            item.itemType,
            db2,
            "local"
          );
        }
        if (migrated !== "skip") toAdd.push(item);
      }
      if (toAdd.length > 0) {
        yield table.put(toAdd);
      }
    });
  }
  migrateItems(db2, table, type, version) {
    return __async(this, null, function* () {
      let progress = 0;
      let toAdd = [];
      let toDelete = [];
      try {
        for (var iter = __forAwait(table.stream(100)), more, temp, error; more = !(temp = yield iter.next()).done; more = false) {
          const item = temp.value;
          if (toAdd.length >= 500) {
            yield table.put(toAdd);
            yield table.delete(toDelete);
            progress += toAdd.length;
            sendMigrationProgressEvent(db2.eventManager, type, progress, progress);
            toAdd = [];
            toDelete = [];
          }
          const itemId = item.id;
          let migrated = yield migrateItem(
            item,
            version,
            CURRENT_DATABASE_VERSION,
            isDeleted(item) ? "never" : item.type || "never",
            db2,
            "local"
          );
          if (isTrashItem(item)) {
            migrated = yield migrateItem(
              item,
              version,
              CURRENT_DATABASE_VERSION,
              item.itemType,
              db2,
              "local"
            );
          }
          if (!migrated || migrated === "skip") continue;
          toAdd.push(item);
          if (item.id !== itemId) {
            toDelete.push(itemId);
          }
        }
      } catch (temp) {
        error = [temp];
      } finally {
        try {
          more && (temp = iter.return) && (yield temp.call(iter));
        } finally {
          if (error)
            throw error[0];
        }
      }
      yield table.put(toAdd);
      yield table.delete(toDelete);
      progress += toAdd.length;
      sendMigrationProgressEvent(db2.eventManager, type, progress, progress);
      toAdd = [];
      toDelete = [];
    });
  }
};
var migrator_default = Migrator2;

// src/database/backup.ts
function isEncryptedBackup(backup) {
  return "encrypted" in backup ? backup.encrypted : isCipher(backup.data);
}
function isLegacyBackup(data) {
  const note = data.find(
    (c) => !isDeleted(c) && !Array.isArray(c) && c.type === "note"
  );
  if (note)
    return "color" in note || "notebooks" in note || "tags" in note || "locked" in note;
  const notebook = data.find(
    (c) => !isDeleted(c) && !Array.isArray(c) && c.type === "notebook"
  );
  if (notebook) return "topics" in notebook;
  const attachment = data.find(
    (c) => !isDeleted(c) && !Array.isArray(c) && c.type === "attachment"
  );
  if (attachment) return "noteIds" in attachment;
  const relation = data.find(
    (c) => !isDeleted(c) && !Array.isArray(c) && c.type === "relation"
  );
  if (relation) return "from" in relation || "to" in relation;
  return false;
}
var MAX_CHUNK_SIZE = 10 * 1024 * 1024;
var invalidKeys = [
  "user",
  "t",
  "v",
  "lastBackupTime",
  "lastSynced",
  // all indexes
  "notes",
  "notebooks",
  "content",
  "tags",
  "colors",
  "attachments",
  "relations",
  "reminders",
  "sessioncontent",
  "notehistory",
  "shortcuts",
  "vaultKey",
  "hasConflict",
  "token",
  "monographs"
];
var itemTypeToCollectionKey = {
  note: "notes",
  notebook: "notebooks",
  tiptap: "content",
  tiny: "content",
  tag: "tags",
  color: "colors",
  attachment: "attachments",
  relation: "relations",
  reminder: "reminders",
  sessioncontent: "sessioncontent",
  session: "noteHistory",
  notehistory: "noteHistory",
  content: "content",
  shortcut: "shortcuts",
  settingitem: "settings",
  settings: "settings",
  vault: "vaults"
};
var validTypes = ["mobile", "web", "node"];
var Backup = class {
  constructor(db2) {
    this.db = db2;
    this.migrator = new migrator_default();
  }
  lastBackupTime() {
    return this.db.kv().read("lastBackupTime");
  }
  updateBackupTime() {
    return __async(this, null, function* () {
      yield this.db.kv().write("lastBackupTime", Date.now());
    });
  }
  /**
   * @deprecated
   */
  exportLegacy(type, encrypt = false) {
    return __asyncGenerator(this, null, function* () {
      if (!validTypes.some((t) => t === type))
        throw new Error("Invalid type. It must be one of 'mobile' or 'web'.");
      if (encrypt && !(yield new __await(this.db.user.getLegacyUser()))) encrypt = false;
      const key = yield new __await(this.db.user.getLegacyEncryptionKey());
      if (encrypt && !key) encrypt = false;
      const keys = yield new __await(this.db.storage().getAllKeys());
      const chunks = toChunks(keys, 20);
      let buffer = [];
      let bufferLength = 0;
      const MAX_CHUNK_SIZE2 = 10 * 1024 * 1024;
      let chunkIndex = 0;
      while (chunks.length > 0) {
        const chunk = chunks.pop();
        if (!chunk) break;
        const items = yield new __await(this.db.storage().readMulti(chunk));
        items.forEach(([id, item]) => {
          const isDeleted2 = item && typeof item === "object" && "deleted" in item && !("type" in item);
          if (!item || invalidKeys.includes(id) || isDeleted2 || id.startsWith("_uk_"))
            return;
          const data = JSON.stringify(item);
          buffer.push(data);
          bufferLength += data.length;
        });
        if (bufferLength >= MAX_CHUNK_SIZE2 || chunks.length === 0) {
          let itemsJSON = `[${buffer.join(",")}]`;
          buffer = [];
          bufferLength = 0;
          itemsJSON = yield new __await(this.db.compressor().compress(itemsJSON));
          const hash = SparkMD52.hash(itemsJSON);
          if (encrypt && key)
            itemsJSON = JSON.stringify(
              yield new __await(this.db.storage().encrypt(key, itemsJSON))
            );
          else itemsJSON = JSON.stringify(itemsJSON);
          yield {
            type: "file",
            path: `${chunkIndex++}-${encrypt ? "encrypted" : "plain"}-${hash}`,
            data: `{
"version": 5.9,
"type": "${type}",
"date": ${Date.now()},
"data": ${itemsJSON},
"hash": "${hash}",
"hash_type": "md5",
"compressed": true,
"encrypted": ${encrypt ? "true" : "false"}
}`
          };
        }
      }
      if (bufferLength > 0 || buffer.length > 0)
        throw new Error("Buffer not empty.");
      yield new __await(this.updateBackupTime());
    });
  }
  export(options) {
    return __asyncGenerator(this, null, function* () {
      const { encrypt = false, type, mode = "partial" } = options;
      if (this.db.migrations.version === 5.9) {
        yield* __yieldStar(this.exportLegacy(type, encrypt));
        return;
      }
      if (!validTypes.some((t) => t === type))
        throw new Error("Invalid type. It must be one of 'mobile' or 'web'.");
      const user = yield new __await(this.db.user.getUser());
      if (encrypt && !user)
        throw new Error("Please login to create encrypted backups.");
      const key = yield new __await(this.db.user.getEncryptionKey());
      if (encrypt && !key) throw new Error("No encryption key found.");
      yield {
        type: "file",
        path: ".nnbackup",
        data: ""
      };
      const backupState = {
        buffer: [],
        bufferLength: 0,
        chunkIndex: 0,
        key,
        encrypt,
        type
      };
      yield* __yieldStar(this.backupCollection(this.db.notes.collection, backupState));
      yield* __yieldStar(this.backupCollection(this.db.notebooks.collection, backupState));
      yield* __yieldStar(this.backupCollection(this.db.content.collection, backupState));
      yield* __yieldStar(this.backupCollection(this.db.noteHistory.collection, backupState));
      yield* __yieldStar(this.backupCollection(
        this.db.noteHistory.sessionContent.collection,
        backupState
      ));
      yield* __yieldStar(this.backupCollection(this.db.colors.collection, backupState));
      yield* __yieldStar(this.backupCollection(this.db.tags.collection, backupState));
      yield* __yieldStar(this.backupCollection(this.db.settings.collection, backupState));
      yield* __yieldStar(this.backupCollection(this.db.shortcuts.collection, backupState));
      yield* __yieldStar(this.backupCollection(this.db.reminders.collection, backupState));
      yield* __yieldStar(this.backupCollection(this.db.relations.collection, backupState));
      yield* __yieldStar(this.backupCollection(this.db.attachments.collection, backupState));
      yield* __yieldStar(this.backupCollection(this.db.vaults.collection, backupState));
      if (backupState.buffer.length > 0) yield* __yieldStar(this.bufferToFile(backupState));
      if (mode === "partial") {
        yield new __await(this.updateBackupTime());
        return;
      }
      const total = yield new __await(this.db.attachments.all.count());
      if (total > 0 && user && user.attachmentsKey) {
        yield {
          type: "file",
          path: `attachments/.attachments_key`,
          data: backupState.encrypt ? JSON.stringify(user.attachmentsKey) : JSON.stringify((yield new __await(this.db.user.getAttachmentsKey())) || {})
        };
        let current = 0;
        try {
          for (var iter = __forAwait(this.db.attachments.all), more, temp, error; more = !(temp = yield new __await(iter.next())).done; more = false) {
            const attachment = temp.value;
            current++;
            if (!(yield new __await(this.db.fs().downloadFile("backup", attachment.hash, attachment.chunkSize))))
              continue;
            yield {
              type: "attachment",
              hash: attachment.hash,
              path: `attachments/${attachment.hash}`,
              total,
              current
            };
          }
        } catch (temp) {
          error = [temp];
        } finally {
          try {
            more && (temp = iter.return) && (yield new __await(temp.call(iter)));
          } finally {
            if (error)
              throw error[0];
          }
        }
      }
      yield new __await(this.updateBackupTime());
    });
  }
  backupCollection(collection, state) {
    return __asyncGenerator(this, null, function* () {
      try {
        for (var iter = __forAwait(collection.stream(
          this.db.options.batchSize
        )), more, temp, error; more = !(temp = yield new __await(iter.next())).done; more = false) {
          const item = temp.value;
          const data = JSON.stringify(item);
          state.buffer.push(data);
          state.bufferLength += data.length;
          if (state.bufferLength >= MAX_CHUNK_SIZE) {
            yield* __yieldStar(this.bufferToFile(state));
          }
        }
      } catch (temp) {
        error = [temp];
      } finally {
        try {
          more && (temp = iter.return) && (yield new __await(temp.call(iter)));
        } finally {
          if (error)
            throw error[0];
        }
      }
    });
  }
  bufferToFile(state) {
    return __asyncGenerator(this, null, function* () {
      let itemsJSON = `[${state.buffer.join(",")}]`;
      state.buffer = [];
      state.bufferLength = 0;
      itemsJSON = yield new __await(this.db.compressor().compress(itemsJSON));
      const hash = SparkMD52.hash(itemsJSON);
      if (state.encrypt && state.key)
        itemsJSON = JSON.stringify(
          yield new __await(this.db.storage().encrypt(state.key, itemsJSON))
        );
      else itemsJSON = JSON.stringify(itemsJSON);
      yield {
        type: "file",
        path: `${state.chunkIndex++}-${state.encrypt ? "encrypted" : "plain"}-${hash}`,
        data: `{
"version": ${CURRENT_DATABASE_VERSION},
"type": "${state.type}",
"date": ${Date.now()},
"data": ${itemsJSON},
"hash": "${hash}",
"hash_type": "md5",
"compressed": true,
"encrypted": ${state.encrypt ? "true" : "false"}
}`
      };
    });
  }
  import(_0) {
    return __async(this, arguments, function* (backup, options = {}) {
      if (!this.validate(backup)) throw new Error("Invalid backup.");
      const { encryptionKey, password, attachmentsKey } = options;
      backup = this.migrateBackup(backup);
      let decryptedData = void 0;
      let decryptedAttachmentsKey = void 0;
      if (isEncryptedBackup(backup)) {
        if (!password && !encryptionKey)
          throw new Error(
            "Please provide a password to decrypt this backup & restore it."
          );
        const key = encryptionKey ? { key: encryptionKey, salt: backup.data.salt } : password ? yield this.db.storage().generateCryptoKey(password, backup.data.salt) : void 0;
        if (!key)
          throw new Error("Could not generate encryption key for backup.");
        decryptedAttachmentsKey = isCipher(attachmentsKey) ? JSON.parse(
          yield this.db.storage().decrypt(key, attachmentsKey)
        ) : attachmentsKey;
        try {
          decryptedData = yield this.db.storage().decrypt(key, backup.data);
        } catch (e) {
          logger.error(e, "Failed to import backup");
          if (e instanceof Error) {
            if (e.message.includes("ciphertext cannot be decrypted") || e.message === "FAILURE")
              throw new Error("Incorrect password.");
            throw new Error(`Could not decrypt backup: ${e.message}`);
          }
        }
      } else {
        if (!isCipher(attachmentsKey)) decryptedAttachmentsKey = attachmentsKey;
        decryptedData = backup.data;
      }
      if (!decryptedData) return;
      if ("hash" in backup && !this.verify(backup, decryptedData))
        throw new Error("Backup file has been tempered, aborting...");
      if ("compressed" in backup && typeof decryptedData === "string")
        decryptedData = yield this.db.compressor().decompress(decryptedData);
      const data = typeof decryptedData === "string" ? JSON.parse(decryptedData) : Object.values(decryptedData);
      if (!data) throw new Error("No data found.");
      const normalizedData = Array.isArray(data) ? data : typeof data === "object" ? Object.values(data) : [];
      yield this.migrateData(
        normalizedData,
        backup.version === 6.1 && isLegacyBackup(normalizedData) ? 5.9 : backup.version,
        decryptedAttachmentsKey
      );
    });
  }
  migrateBackup(backup) {
    const { version = 0 } = backup;
    if (version > CURRENT_DATABASE_VERSION)
      throw new Error(
        "This backup was made from a newer version of Notesnook. Cannot migrate."
      );
    switch (version) {
      case CURRENT_DATABASE_VERSION:
      case 6:
      case 5.9:
      case 5.8:
      case 5.7:
      case 5.6:
      case 5.5:
      case 5.4:
      case 5.3:
      case 5.2:
      case 5.1:
      case 5: {
        return backup;
      }
      default:
        throw new Error("Unknown backup version.");
    }
  }
  migrateData(data, version, attachmentsKey) {
    return __async(this, null, function* () {
      var _a, _b, _c, _d, _e, _f;
      const queue = {};
      for (let item of data) {
        if (!item || typeof item !== "object" || Array.isArray(item) || isDeleted(item))
          continue;
        if ("sessionContentId" in item && item.type !== "session")
          item.type = "notehistory";
        if ((yield migrateItem(
          item,
          version,
          CURRENT_DATABASE_VERSION,
          item.type,
          this.db,
          "backup"
        )) === "skip")
          continue;
        if (item.type === "trash" && item.itemType) {
          if ((yield migrateItem(
            item,
            version,
            CURRENT_DATABASE_VERSION,
            item.itemType,
            this.db,
            "backup"
          )) === "skip")
            continue;
        }
        const itemType = (
          // colors are naively of type "tag" instead of "color" so we have to fix that.
          item.type === "tag" && DefaultColors[item.title.toLowerCase()] ? "color" : item.type === "trash" && "itemType" in item && item.itemType ? item.itemType : item.type
        );
        if (!itemType || itemType === "topic" || itemType === "settings")
          continue;
        if (item.type === "attachment" && (((_a = item.metadata) == null ? void 0 : _a.hash) || item.hash)) {
          const attachment = yield this.db.attachments.attachment(
            ((_b = item.metadata) == null ? void 0 : _b.hash) || item.hash
          );
          const isSameKey = !!attachment && attachment.key.iv === item.key.iv && attachment.key.cipher === item.key.cipher && attachment.key.salt === item.key.salt;
          if (attachmentsKey && !isSameKey) {
            const newKey = yield this.db.attachments.encryptKey(
              JSON.parse(
                yield this.db.storage().decrypt(attachmentsKey, item.key)
              )
            );
            if (attachment) {
              attachment.key = newKey;
              attachment.iv = item.iv;
              attachment.salt = item.salt;
              attachment.size = item.size;
            } else item.key = newKey;
            delete item.dateUploaded;
            delete item.failed;
          }
          if (attachment) {
            if (attachmentsKey && isSameKey && !(yield this.db.fs().exists(attachment.hash)) && (yield this.db.fs().getUploadedFileSize(attachment.hash)) <= 0) {
              delete item.dateUploaded;
              delete item.failed;
            }
            const isNewGeneric = ((_c = item.metadata) == null ? void 0 : _c.type) === "application/octet-stream" || item.mimeType === "application/octet-stream";
            const isOldGeneric = attachment.mimeType === "application/octet-stream";
            item = __spreadProps(__spreadValues({}, attachment), {
              mimeType: (
                // we keep whichever mime type is more specific
                isNewGeneric && !isOldGeneric ? attachment.mimeType : ((_d = item.metadata) == null ? void 0 : _d.type) || item.mimeType
              ),
              filename: (
                // we keep the filename based on which item's mime type we kept
                isNewGeneric && !isOldGeneric ? attachment.filename : ((_e = item.metadata) == null ? void 0 : _e.filename) || item.filename
              )
            });
            for (const noteId of item.noteIds || []) {
              yield this.db.relations.add(
                {
                  id: noteId,
                  type: "note"
                },
                attachment
              );
            }
          } else {
            delete item.dateUploaded;
            delete item.failed;
          }
        }
        const collectionKey = itemTypeToCollectionKey[itemType];
        if (!collectionKey) continue;
        if (itemType === "color") {
          item.dateModified = Date.now();
          item.synced = false;
          yield this.db.colors.collection.upsert(item);
        } else if (itemType === "tag") {
          item.dateModified = Date.now();
          item.synced = false;
          yield this.db.tags.collection.upsert(item);
        } else {
          queue[collectionKey] = queue[collectionKey] || [];
          (_f = queue[collectionKey]) == null ? void 0 : _f.push(item);
        }
      }
      for (const key in queue) {
        const collectionKey = key;
        const collection = collectionKey === "sessioncontent" ? this.db.noteHistory.sessionContent.collection : this.db[collectionKey].collection;
        if (!collection) continue;
        const items = queue[collectionKey];
        if (!items) continue;
        yield collection.put(items);
      }
    });
  }
  validate(backup) {
    return !!backup.date && !!backup.data && !!backup.type && validTypes.some((t) => t === backup.type);
  }
  verify(backup, data) {
    const { hash, hash_type } = backup;
    switch (hash_type) {
      case "md5": {
        return hash === SparkMD52.hash(typeof data === "string" ? data : JSON.stringify(data));
      }
      default: {
        return false;
      }
    }
  }
};

// src/collections/legacy-settings.ts
var LegacySettings = class {
  constructor(db2) {
    this.db = db2;
    this.name = "legacy-settings";
    this.settings = {
      type: "settings",
      dateModified: 0,
      dateCreated: 0,
      id: getId()
    };
  }
  init() {
    return __async(this, null, function* () {
      const settings = yield this.db.storage().read("settings");
      if (settings) this.settings = settings;
    });
  }
  get raw() {
    return this.settings;
  }
  /**
   * @deprecated only kept here for migration purposes
   */
  getAlias(id) {
    return this.settings.aliases && this.settings.aliases[id];
  }
};

// src/api/migrations.ts
var collections = [
  {
    name: "settings",
    table: "settings"
  },
  {
    name: "settingsv2",
    table: "settings"
  },
  {
    name: "attachments",
    table: "attachments"
  },
  {
    name: "notebooks",
    table: "notebooks"
  },
  {
    name: "tags",
    table: "tags"
  },
  {
    name: "colors",
    table: "colors"
  },
  {
    name: "content",
    table: "content"
  },
  {
    name: "shortcuts",
    table: "shortcuts"
  },
  {
    name: "reminders",
    table: "reminders"
  },
  {
    name: "relations",
    table: "relations"
  },
  {
    name: "notehistory",
    table: "notehistory"
  },
  {
    name: "sessioncontent",
    table: "sessioncontent"
  },
  {
    name: "notes",
    table: "notes"
  },
  {
    name: "vaults",
    table: "vaults"
  }
];
var Migrations = class {
  constructor(db2) {
    this.db = db2;
    this.migrator = new migrator_default();
    this.migrating = false;
    this.version = CURRENT_DATABASE_VERSION;
  }
  init() {
    return __async(this, null, function* () {
      this.version = (yield this.db.kv().read("v")) || (yield this.db.storage().read("v")) || CURRENT_DATABASE_VERSION;
      yield this.db.kv().write("v", this.version);
    });
  }
  required() {
    return this.version < CURRENT_DATABASE_VERSION;
  }
  migrate() {
    return __async(this, null, function* () {
      try {
        if (!this.required() || this.migrating) return;
        this.migrating = true;
        yield this.migrator.migrate(this.db, collections, this.version);
        yield this.db.kv().write("v", CURRENT_DATABASE_VERSION);
        this.version = CURRENT_DATABASE_VERSION;
      } finally {
        this.migrating = false;
      }
    });
  }
};
var migrations_default = Migrations;

// src/api/healthcheck.ts
var HealthCheck = class {
  static auth() {
    return __async(this, null, function* () {
      return check(constants_default.AUTH_HOST);
    });
  }
};
function check(host) {
  return __async(this, null, function* () {
    try {
      const response = yield http_default.get(`${host}/health`);
      return response.trim() === "Healthy";
    } catch (e) {
      return false;
    }
  });
}

// src/api/user-manager.ts
var ENDPOINTS2 = {
  signup: "/users",
  token: "/connect/token",
  user: "/users",
  deleteUser: "/users/delete",
  patchUser: "/account",
  verifyUser: "/account/verify",
  revoke: "/connect/revocation",
  recoverAccount: "/account/recover",
  resetUser: "/users/reset",
  activateTrial: "/subscriptions/trial"
};
var UserManager = class {
  constructor(db2) {
    this.db = db2;
    this.tokenManager = new token_manager_default(this.db.kv);
    EV.subscribe(EVENTS.userUnauthorized, (url) => __async(this, null, function* () {
      if (url.includes("/connect/token") || !(yield HealthCheck.auth())) return;
      try {
        yield this.tokenManager._refreshToken(true);
      } catch (e) {
        if (e instanceof Error && (e.message === "invalid_grant" || e.message === "invalid_client")) {
          yield this.logout(
            false,
            `Your token has been revoked. Error: ${e.message}.`
          );
        }
      }
    }));
  }
  init() {
    return __async(this, null, function* () {
      const user = yield this.getUser();
      if (!user) return;
    });
  }
  signup(email, password) {
    return __async(this, null, function* () {
      email = email.toLowerCase();
      const hashedPassword = yield this.db.storage().hash(password, email);
      yield http_default.post(`${constants_default.API_HOST}${ENDPOINTS2.signup}`, {
        email,
        password: hashedPassword,
        client_id: "notesnook"
      });
      EV.publish(EVENTS.userSignedUp);
      return yield this._login({ email, password, hashedPassword });
    });
  }
  authenticateEmail(email) {
    return __async(this, null, function* () {
      if (!email) throw new Error("Email is required.");
      email = email.toLowerCase();
      const result = yield http_default.post(`${constants_default.AUTH_HOST}${ENDPOINTS2.token}`, {
        email,
        grant_type: "email",
        client_id: "notesnook"
      });
      yield this.tokenManager.saveToken(result);
      return result.additional_data;
    });
  }
  authenticateMultiFactorCode(code, method) {
    return __async(this, null, function* () {
      if (!code || !method) throw new Error("code & method are required.");
      const token = yield this.tokenManager.getToken();
      if (!token || token.scope !== "auth:grant_types:mfa")
        throw new Error("No token found.");
      yield this.tokenManager.saveToken(
        yield http_default.post(
          `${constants_default.AUTH_HOST}${ENDPOINTS2.token}`,
          {
            grant_type: "mfa",
            client_id: "notesnook",
            "mfa:code": code,
            "mfa:method": method
          },
          token.access_token
        )
      );
      return true;
    });
  }
  authenticatePassword(email, password, hashedPassword, sessionExpired) {
    return __async(this, null, function* () {
      if (!email || !password) throw new Error("email & password are required.");
      const token = yield this.tokenManager.getToken();
      if (!token || token.scope !== "auth:grant_types:mfa_password")
        throw new Error("No token found.");
      email = email.toLowerCase();
      if (!hashedPassword) {
        hashedPassword = yield this.db.storage().hash(password, email);
      }
      try {
        yield this.tokenManager.saveToken(
          yield http_default.post(
            `${constants_default.AUTH_HOST}${ENDPOINTS2.token}`,
            {
              grant_type: "mfa_password",
              client_id: "notesnook",
              scope: "notesnook.sync offline_access IdentityServerApi",
              password: hashedPassword
            },
            token.access_token
          )
        );
        const user = yield this.fetchUser();
        if (!user) throw new Error("Failed to fetch user.");
        if (!sessionExpired) {
          yield this.db.setLastSynced(0);
          yield this.db.syncer.devices.register();
        }
        yield this.db.storage().deriveCryptoKey({
          password,
          salt: user.salt
        });
        EV.publish(EVENTS.userLoggedIn, user);
      } catch (e) {
        yield this.tokenManager.saveToken(token);
        throw e;
      }
    });
  }
  _login(_0) {
    return __async(this, arguments, function* ({
      email,
      password,
      hashedPassword,
      code,
      method
    }) {
      email = email && email.toLowerCase();
      if (!hashedPassword && password) {
        hashedPassword = yield this.db.storage().hash(password, email);
      }
      yield this.tokenManager.saveToken(
        yield http_default.post(`${constants_default.AUTH_HOST}${ENDPOINTS2.token}`, {
          username: email,
          password: hashedPassword,
          grant_type: code ? "mfa" : "password",
          scope: "notesnook.sync offline_access IdentityServerApi",
          client_id: "notesnook",
          "mfa:code": code,
          "mfa:method": method
        })
      );
      const user = yield this.fetchUser();
      if (!user) return;
      yield this.db.storage().deriveCryptoKey({
        password,
        salt: user.salt
      });
      yield this.db.setLastSynced(0);
      yield this.db.syncer.devices.register();
      EV.publish(EVENTS.userLoggedIn, user);
    });
  }
  getSessions() {
    return __async(this, null, function* () {
      const token = yield this.tokenManager.getAccessToken();
      if (!token) return;
      yield http_default.get(`${constants_default.AUTH_HOST}/account/sessions`, token);
    });
  }
  clearSessions(all = false) {
    return __async(this, null, function* () {
      const token = yield this.tokenManager.getToken();
      if (!token) return;
      const { access_token, refresh_token } = token;
      yield http_default.post(
        `${constants_default.AUTH_HOST}/account/sessions/clear?all=${all}`,
        { refresh_token },
        access_token
      );
    });
  }
  activateTrial() {
    return __async(this, null, function* () {
      const token = yield this.tokenManager.getAccessToken();
      if (!token) return false;
      yield http_default.post(
        `${constants_default.SUBSCRIPTIONS_HOST}${ENDPOINTS2.activateTrial}`,
        null,
        token
      );
      return true;
    });
  }
  logout(revoke = true, reason) {
    return __async(this, null, function* () {
      try {
        yield this.db.syncer.devices.unregister();
        if (revoke) yield this.tokenManager.revokeToken();
      } catch (e) {
        logger.error(e, "Error logging out user.", { revoke, reason });
      } finally {
        this.cachedAttachmentKey = void 0;
        yield this.db.reset();
        EV.publish(EVENTS.userLoggedOut, reason);
        EV.publish(EVENTS.appRefreshRequested);
      }
    });
  }
  setUser(user) {
    return this.db.kv().write("user", user);
  }
  getUser() {
    return this.db.kv().read("user");
  }
  /**
   * @deprecated
   */
  getLegacyUser() {
    return this.db.storage().read("user");
  }
  resetUser(removeAttachments = true) {
    return __async(this, null, function* () {
      const token = yield this.tokenManager.getAccessToken();
      if (!token) return;
      yield http_default.post(
        `${constants_default.API_HOST}${ENDPOINTS2.resetUser}`,
        { removeAttachments },
        token
      );
      return true;
    });
  }
  updateUser(partial) {
    return __async(this, null, function* () {
      const user = yield this.getUser();
      if (!user) return;
      const token = yield this.tokenManager.getAccessToken();
      yield http_default.patch.json(
        `${constants_default.API_HOST}${ENDPOINTS2.user}`,
        partial,
        token
      );
      yield this.setUser(__spreadValues(__spreadValues({}, user), partial));
    });
  }
  deleteUser(password) {
    return __async(this, null, function* () {
      const token = yield this.tokenManager.getAccessToken();
      const user = yield this.getUser();
      if (!token || !user) return;
      yield http_default.post(
        `${constants_default.API_HOST}${ENDPOINTS2.deleteUser}`,
        { password: yield this.db.storage().hash(password, user.email) },
        token
      );
      yield this.logout(false, "Account deleted.");
      return true;
    });
  }
  fetchUser() {
    return __async(this, null, function* () {
      try {
        const token = yield this.tokenManager.getAccessToken();
        if (!token) return;
        const user = yield http_default.get(
          `${constants_default.API_HOST}${ENDPOINTS2.user}`,
          token
        );
        if (user) {
          const oldUser = yield this.getUser();
          if (oldUser && (oldUser.subscription.type !== user.subscription.type || oldUser.subscription.provider !== user.subscription.provider)) {
            yield this.tokenManager._refreshToken(true);
            EV.publish(EVENTS.userSubscriptionUpdated, user.subscription);
          }
          if (oldUser && !oldUser.isEmailConfirmed && user.isEmailConfirmed)
            EV.publish(EVENTS.userEmailConfirmed);
          yield this.setUser(user);
          EV.publish(EVENTS.userFetched, user);
          return user;
        } else {
          return yield this.getUser();
        }
      } catch (e) {
        logger.error(e, "Error fetching user");
        return yield this.getUser();
      }
    });
  }
  changePassword(oldPassword, newPassword) {
    return this._updatePassword("change_password", {
      old_password: oldPassword,
      new_password: newPassword
    });
  }
  changeMarketingConsent(enabled) {
    return __async(this, null, function* () {
      const token = yield this.tokenManager.getAccessToken();
      if (!token) return;
      yield http_default.patch(
        `${constants_default.AUTH_HOST}${ENDPOINTS2.patchUser}`,
        {
          type: "change_marketing_consent",
          enabled
        },
        token
      );
    });
  }
  resetPassword(newPassword) {
    return this._updatePassword("reset_password", {
      new_password: newPassword
    });
  }
  getEncryptionKey() {
    return __async(this, null, function* () {
      const user = yield this.getUser();
      if (!user) return;
      const key = yield this.db.storage().getCryptoKey();
      if (!key) return;
      return { key, salt: user.salt };
    });
  }
  /**
   * @deprecated
   */
  getLegacyEncryptionKey() {
    return __async(this, null, function* () {
      const user = yield this.getLegacyUser();
      if (!user) return;
      const key = yield this.db.storage().getCryptoKey();
      if (!key) return;
      return { key, salt: user.salt };
    });
  }
  getAttachmentsKey() {
    return __async(this, null, function* () {
      if (this.cachedAttachmentKey) return this.cachedAttachmentKey;
      try {
        let user = yield this.getUser();
        if (!user) return;
        if (!user.attachmentsKey) {
          const token = yield this.tokenManager.getAccessToken();
          user = yield http_default.get(`${constants_default.API_HOST}${ENDPOINTS2.user}`, token);
        }
        if (!user) return;
        const userEncryptionKey = yield this.getEncryptionKey();
        if (!userEncryptionKey) return;
        if (!user.attachmentsKey) {
          const key = yield this.db.crypto().generateRandomKey();
          user.attachmentsKey = yield this.db.storage().encrypt(userEncryptionKey, JSON.stringify(key));
          yield this.updateUser({ attachmentsKey: user.attachmentsKey });
          return key;
        }
        const plainData = yield this.db.storage().decrypt(userEncryptionKey, user.attachmentsKey);
        if (!plainData) return;
        this.cachedAttachmentKey = JSON.parse(plainData);
        return this.cachedAttachmentKey;
      } catch (e) {
        logger.error(e, "Could not get attachments encryption key.");
        if (e instanceof Error)
          throw new Error(
            `Could not get attachments encryption key. Error: ${e.message}`
          );
      }
    });
  }
  sendVerificationEmail(newEmail) {
    return __async(this, null, function* () {
      const token = yield this.tokenManager.getAccessToken();
      if (!token) return;
      yield http_default.post(
        `${constants_default.AUTH_HOST}${ENDPOINTS2.verifyUser}`,
        { newEmail },
        token
      );
    });
  }
  changeEmail(newEmail, password, code) {
    return __async(this, null, function* () {
      const token = yield this.tokenManager.getAccessToken();
      if (!token) return;
      const user = yield this.getUser();
      if (!user) return;
      const email = newEmail.toLowerCase();
      yield http_default.patch(
        `${constants_default.AUTH_HOST}${ENDPOINTS2.patchUser}`,
        {
          type: "change_email",
          new_email: newEmail,
          password: yield this.db.storage().hash(password, email),
          verification_code: code
        },
        token
      );
      yield this.db.storage().deriveCryptoKey({
        password,
        salt: user.salt
      });
    });
  }
  recoverAccount(email) {
    return http_default.post(`${constants_default.AUTH_HOST}${ENDPOINTS2.recoverAccount}`, {
      email,
      client_id: "notesnook"
    });
  }
  verifyPassword(password) {
    return __async(this, null, function* () {
      try {
        const user = yield this.getUser();
        const key = yield this.getEncryptionKey();
        if (!user || !key) return false;
        const cipher = yield this.db.storage().encrypt(key, "notesnook");
        const plainText = yield this.db.storage().decrypt({ password }, cipher);
        return plainText === "notesnook";
      } catch (e) {
        return false;
      }
    });
  }
  _updatePassword(type, data) {
    return __async(this, null, function* () {
      const token = yield this.tokenManager.getAccessToken();
      const user = yield this.getUser();
      if (!token || !user) throw new Error("You are not logged in.");
      const { email, salt } = user;
      let { new_password, old_password } = data;
      if (old_password && !(yield this.verifyPassword(old_password)))
        throw new Error("Incorrect old password.");
      if (!new_password) throw new Error("New password is required.");
      const attachmentsKey = yield this.getAttachmentsKey();
      data.encryptionKey = data.encryptionKey || (yield this.getEncryptionKey());
      yield this.clearSessions();
      if (data.encryptionKey) yield this.db.sync({ type: "fetch", force: true });
      yield this.db.storage().deriveCryptoKey({
        password: new_password,
        salt
      });
      if (!(yield this.resetUser(false))) return;
      yield this.db.sync({ type: "send", force: true });
      if (attachmentsKey) {
        const userEncryptionKey = yield this.getEncryptionKey();
        if (!userEncryptionKey) return;
        user.attachmentsKey = yield this.db.storage().encrypt(userEncryptionKey, JSON.stringify(attachmentsKey));
        yield this.updateUser({ attachmentsKey: user.attachmentsKey });
      }
      if (old_password)
        old_password = yield this.db.storage().hash(old_password, email);
      if (new_password)
        new_password = yield this.db.storage().hash(new_password, email);
      yield http_default.patch(
        `${constants_default.AUTH_HOST}${ENDPOINTS2.patchUser}`,
        {
          type,
          old_password,
          new_password
        },
        token
      );
      return true;
    });
  }
};
var user_manager_default = UserManager;

// src/api/monographs.ts
var Monographs = class {
  constructor(db2) {
    this.db = db2;
    this.monographs = [];
  }
  clear() {
    return __async(this, null, function* () {
      this.monographs = [];
      yield this.db.kv().write("monographs", this.monographs);
    });
  }
  refresh() {
    return __async(this, null, function* () {
      try {
        const user = yield this.db.user.getUser();
        const token = yield this.db.tokenManager.getAccessToken();
        if (!user || !token || !user.isEmailConfirmed) return;
        const monographs = yield http_default.get(
          `${constants_default.API_HOST}/monographs`,
          token
        );
        yield this.db.kv().write("monographs", monographs);
        if (monographs) this.monographs = monographs;
      } catch (e) {
        logger.error(e, "Error while refreshing monographs.");
      }
    });
  }
  /**
   * Check if note is published.
   */
  isPublished(noteId) {
    return this.monographs && this.monographs.indexOf(noteId) > -1;
  }
  /**
   * Get note published monograph id
   */
  monograph(noteId) {
    return this.monographs[this.monographs.indexOf(noteId)];
  }
  /**
   * Publish a note as a monograph
   */
  publish(_0) {
    return __async(this, arguments, function* (noteId, opts = {}) {
      if (!this.monographs.length) yield this.refresh();
      const update = !!this.isPublished(noteId);
      const user = yield this.db.user.getUser();
      const token = yield this.db.tokenManager.getAccessToken();
      if (!user || !token) throw new Error("Please login to publish a note.");
      const note = yield this.db.notes.note(noteId);
      if (!note) throw new Error("No such note found.");
      if (!note.contentId) throw new Error("Cannot publish an empty note.");
      const contentItem = yield this.db.content.get(note.contentId);
      if (!contentItem || isDeleted(contentItem))
        throw new Error("Could not find content for this note.");
      if (contentItem.locked) throw new Error("Cannot published locked notes.");
      const content = yield this.db.content.downloadMedia(
        `monograph-${noteId}`,
        contentItem,
        false
      );
      const monograph = __spreadValues({
        id: noteId,
        title: note.title,
        userId: user.id,
        selfDestruct: opts.selfDestruct || false
      }, opts.password ? {
        encryptedContent: yield this.db.storage().encrypt(
          { password: opts.password },
          JSON.stringify({ type: content.type, data: content.data })
        )
      } : {
        content: JSON.stringify({
          type: content.type,
          data: content.data
        })
      });
      const method = update ? http_default.patch.json : http_default.post.json;
      const { id } = yield method(
        `${constants_default.API_HOST}/monographs`,
        monograph,
        token
      );
      this.monographs.push(id);
      return id;
    });
  }
  /**
   * Unpublish a note
   */
  unpublish(noteId) {
    return __async(this, null, function* () {
      if (!this.monographs.length) yield this.refresh();
      const user = yield this.db.user.getUser();
      const token = yield this.db.tokenManager.getAccessToken();
      if (!user || !token) throw new Error("Please login to publish a note.");
      if (!this.isPublished(noteId))
        throw new Error("This note is not published.");
      yield http_default.delete(`${constants_default.API_HOST}/monographs/${noteId}`, token);
      this.monographs.splice(this.monographs.indexOf(noteId), 1);
    });
  }
  get all() {
    var _a;
    return this.db.notes.collection.createFilter(
      (qb) => qb.where(isFalse("dateDeleted")).where(isFalse("deleted")).where("id", "in", this.monographs),
      (_a = this.db.options) == null ? void 0 : _a.batchSize
    );
  }
  get(monographId) {
    return http_default.get(`${constants_default.API_HOST}/monographs/${monographId}`);
  }
};

// src/api/offers.ts
var Offers = class {
  static getCode(promo, platform) {
    return __async(this, null, function* () {
      const result = yield http_default.get(
        `${constants_default.SUBSCRIPTIONS_HOST}/offers?promoCode=${promo}&clientId=${CLIENT_ID}&platformId=${platform}`
      );
      return result.code;
    });
  }
};

// src/api/debug.ts
var Debug = class {
  static report(reportData) {
    return __async(this, null, function* () {
      const { title, body, userId } = reportData;
      const response = yield fetch(`${constants_default.ISSUES_HOST}/create/notesnook`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ title, body, userId })
      });
      if (!response.ok) return;
      const json = yield response.json();
      return json.url;
    });
  }
};

// src/api/index.ts
import { Mutex as Mutex3 } from "async-mutex";

// src/collections/session-content.ts
var SessionContent = class {
  constructor(db2) {
    this.db = db2;
    this.name = "sessioncontent";
    this.collection = new SQLCollection(
      db2.sql,
      db2.transaction,
      "sessioncontent",
      db2.eventManager,
      db2.sanitizer
    );
  }
  init() {
    return __async(this, null, function* () {
      yield this.collection.init();
    });
  }
  add(sessionId, content, locked) {
    return __async(this, null, function* () {
      if (!sessionId || !content) return;
      yield this.collection.upsert({
        type: "sessioncontent",
        id: makeSessionContentId(sessionId),
        data: content.data,
        contentType: content.type,
        compressed: false,
        localOnly: true,
        locked,
        dateCreated: Date.now(),
        dateModified: Date.now()
      });
    });
  }
  get(sessionContentId) {
    return __async(this, null, function* () {
      const session = yield this.collection.get(sessionContentId);
      if (!session || isDeleted(session)) return;
      if (session.contentType === "tiny" && session.compressed && !session.locked && !isCipher(session.data)) {
        session.data = yield this.db.compressor().compress(
          tinyToTiptap(yield this.db.compressor().decompress(session.data))
        );
        session.contentType = "tiptap";
        yield this.collection.upsert(session);
      }
      return {
        data: session.compressed && !isCipher(session.data) ? yield this.db.compressor().decompress(session.data) : session.data,
        type: session.contentType
      };
    });
  }
  remove(sessionContentId) {
    return __async(this, null, function* () {
      yield this.collection.delete([sessionContentId]);
    });
  }
  // async all() {
  //   const indices = this.collection.indexer.indices;
  //   const items = await this.collection.getItems(indices);
  //   return Object.values(items);
  // }
};

// src/collections/note-history.ts
var NoteHistory = class {
  constructor(db2) {
    this.db = db2;
    this.name = "notehistory";
    this.versionsLimit = 100;
    this.sessionContent = new SessionContent(db2);
    this.collection = new SQLCollection(
      db2.sql,
      db2.transaction,
      "notehistory",
      db2.eventManager,
      db2.sanitizer
    );
  }
  init() {
    return __async(this, null, function* () {
      yield this.collection.init();
      yield this.sessionContent.init();
    });
  }
  // async get(noteId: string, order: "asc" | "desc" = "desc") {
  //   if (!noteId) return [];
  //   // const indices = this.collection.indexer.indices;
  //   // const sessionIds = indices.filter((id) => id.startsWith(noteId));
  //   // if (sessionIds.length === 0) return [];
  //   // const history = await this.getSessions(sessionIds);
  //   // return history.sort(function (a, b) {
  //   //   return b.dateModified - a.dateModified;
  //   // });
  // const history = await this.db
  //   .sql()
  //   .selectFrom("notehistory")
  //   .where("noteId", "==", noteId)
  //     .orderBy(`dateModified ${order}`)
  //     .selectAll()
  //     .execute();
  //   return history as HistorySession[];
  // }
  get(noteId) {
    return new FilteredSelector(
      "notehistory",
      this.db.sql().selectFrom("notehistory").where("noteId", "==", noteId)
    );
  }
  add(sessionId, content) {
    return __async(this, null, function* () {
      const { noteId, locked } = content;
      sessionId = `${noteId}_${sessionId}`;
      if (yield this.collection.exists(sessionId)) {
        yield this.collection.update([sessionId], { locked });
      } else {
        yield this.collection.upsert({
          type: "session",
          id: sessionId,
          sessionContentId: makeSessionContentId(sessionId),
          noteId,
          dateCreated: Date.now(),
          dateModified: Date.now(),
          localOnly: true,
          locked
        });
      }
      yield this.sessionContent.add(sessionId, content, locked);
      yield this.cleanup(noteId);
      return sessionId;
    });
  }
  cleanup(_0) {
    return __async(this, arguments, function* (noteId, limit = this.versionsLimit) {
      const history = yield this.db.sql().selectFrom("notehistory").where("noteId", "==", noteId).orderBy(`dateModified desc`).select(["id", "sessionContentId"]).offset(limit).limit(10).$narrowType().execute();
      for (const session of history) {
        yield this._remove(session);
      }
    });
  }
  content(sessionId) {
    return __async(this, null, function* () {
      const session = yield this.collection.get(sessionId);
      if (!session || isDeleted(session)) return;
      return yield this.sessionContent.get(session.sessionContentId);
    });
  }
  session(sessionId) {
    return __async(this, null, function* () {
      const session = yield this.collection.get(sessionId);
      if (!session || isDeleted(session)) return;
      return session;
    });
  }
  remove(sessionId) {
    return __async(this, null, function* () {
      const session = yield this.collection.get(sessionId);
      if (!session || isDeleted(session)) return;
      yield this._remove(session);
    });
  }
  clearSessions(...noteIds) {
    return __async(this, null, function* () {
      yield this.db.transaction(() => __async(this, null, function* () {
        const deletedIds = yield this.db.sql().deleteFrom("notehistory").where("noteId", "in", noteIds).returning("sessionContentId as sessionContentId").execute();
        yield this.db.sql().deleteFrom("sessioncontent").where(
          "id",
          "in",
          deletedIds.reduce((arr, item) => {
            if (item.sessionContentId && !arr.includes(item.sessionContentId))
              arr.push(item.sessionContentId);
            return arr;
          }, [])
        ).execute();
      }));
    });
  }
  _remove(session) {
    return __async(this, null, function* () {
      yield this.collection.delete([session.id]);
      yield this.sessionContent.remove(session.sessionContentId);
    });
  }
  restore(sessionId) {
    return __async(this, null, function* () {
      const session = yield this.collection.get(sessionId);
      if (!session || isDeleted(session)) return;
      const content = yield this.sessionContent.get(session.sessionContentId);
      const note = yield this.db.notes.note(session.noteId);
      if (!note || !content) return;
      if (session.locked && isCipher(content.data)) {
        yield this.db.content.add({
          id: note.contentId,
          noteId: session.noteId,
          sessionId: `${Date.now()}`,
          data: content.data,
          type: content.type
        });
      } else if (content.data && !isCipher(content.data)) {
        yield this.db.notes.add({
          id: session.noteId,
          sessionId: `${Date.now()}`,
          content: {
            data: content.data,
            type: content.type
          }
        });
      }
    });
  }
  // async all() {
  //   return this.getSessions(this.collection.indexer.indices);
  // }
  // private async getSessions(sessionIds: string[]): Promise<HistorySession[]> {
  //   const items = await this.collection.getItems(sessionIds);
  //   return Object.values(items).filter(
  //     (a) => !isDeleted(a)
  //   ) as HistorySession[];
  // }
};

// src/api/mfa-manager.ts
var ENDPOINTS3 = {
  setup: "/mfa",
  enable: "/mfa",
  disable: "/mfa",
  recoveryCodes: "/mfa/codes",
  send: "/mfa/send"
};
var MFAManager = class {
  constructor(tokenManager) {
    this.tokenManager = tokenManager;
  }
  setup(type, phoneNumber) {
    return __async(this, null, function* () {
      const token = yield this.tokenManager.getAccessToken();
      if (!token) return;
      return yield http_default.post(
        `${constants_default.AUTH_HOST}${ENDPOINTS3.setup}`,
        {
          type,
          phoneNumber
        },
        token
      );
    });
  }
  enable(type, code) {
    return __async(this, null, function* () {
      return this._enable(type, code, false);
    });
  }
  /**
   *
   * @param {"app" | "sms" | "email"} type
   * @param {string} code
   * @returns
   */
  enableFallback(type, code) {
    return __async(this, null, function* () {
      return this._enable(type, code, true);
    });
  }
  _enable(type, code, isFallback) {
    return __async(this, null, function* () {
      const token = yield this.tokenManager.getAccessToken();
      if (!token) return;
      return yield http_default.patch(
        `${constants_default.AUTH_HOST}${ENDPOINTS3.enable}`,
        { type, code, isFallback },
        token
      );
    });
  }
  disable() {
    return __async(this, null, function* () {
      const token = yield this.tokenManager.getAccessToken();
      if (!token) return;
      return yield http_default.delete(
        `${constants_default.AUTH_HOST}${ENDPOINTS3.disable}`,
        token
      );
    });
  }
  codes() {
    return __async(this, null, function* () {
      const token = yield this.tokenManager.getAccessToken();
      if (!token) return;
      return yield http_default.get(
        `${constants_default.AUTH_HOST}${ENDPOINTS3.recoveryCodes}`,
        token
      );
    });
  }
  sendCode(method) {
    return __async(this, null, function* () {
      const token = yield this.tokenManager.getAccessToken([
        "IdentityServerApi",
        "auth:grant_types:mfa"
      ]);
      if (!token) throw new Error("Unauthorized.");
      return yield http_default.post(
        `${constants_default.AUTH_HOST}${ENDPOINTS3.send}`,
        {
          type: method
        },
        token
      );
    });
  }
};
var mfa_manager_default = MFAManager;

// src/api/pricing.ts
var BASE_URL = `https://notesnook.com/api/v1/prices`;
var Pricing = class {
  static sku(platform, period) {
    return http_default.get(`${BASE_URL}/skus/${platform}/${period}`);
  }
  static price(period = "monthly") {
    return http_default.get(`${BASE_URL}/${period}`);
  }
};

// src/database/sql-cached-collection.ts
var SQLCachedCollection = class {
  // private cachedItems?: T[];
  constructor(sql13, startTransaction, type, eventManager, sanitizer) {
    this.type = type;
    this.cache = /* @__PURE__ */ new Map();
    this.collection = new SQLCollection(
      sql13,
      startTransaction,
      type,
      eventManager,
      sanitizer
    );
  }
  init() {
    return __async(this, null, function* () {
      yield this.collection.init();
      const records = yield this.collection.records([]);
      this.cache = new Map(Object.entries(records));
    });
  }
  // async add(item: MaybeDeletedItem<T>) {
  //   await this.collection.addItem(item);
  //   this.cache.set(item.id, item);
  //   this.invalidateCache();
  // }
  clear() {
    return __async(this, null, function* () {
      yield this.collection.clear();
      this.cache.clear();
    });
  }
  upsert(item) {
    return __async(this, null, function* () {
      yield this.collection.upsert(item);
      this.cache.set(item.id, item);
    });
  }
  delete(ids) {
    return __async(this, null, function* () {
      ids.forEach((id) => this.cache.delete(id));
      yield this.collection.delete(ids);
    });
  }
  softDelete(ids) {
    return __async(this, null, function* () {
      ids.forEach(
        (id) => this.cache.set(id, {
          id,
          deleted: true,
          dateModified: Date.now(),
          synced: false
        })
      );
      yield this.collection.softDelete(ids);
    });
  }
  exists(id) {
    const item = this.cache.get(id);
    return !!item && !isDeleted(item);
  }
  count() {
    return this.cache.size;
  }
  get(id) {
    const item = this.cache.get(id);
    if (!item || isDeleted(item)) return;
    return item;
  }
  put(items) {
    return __async(this, null, function* () {
      const entries = yield this.collection.put(items);
      for (const item of entries) {
        this.cache.set(item.id, item);
      }
      return entries;
    });
  }
  update(ids, partial) {
    return __async(this, null, function* () {
      yield this.collection.update(ids, partial);
      for (const id of ids) {
        const item = this.cache.get(id);
        if (!item) continue;
        this.cache.set(id, __spreadProps(__spreadValues(__spreadValues({}, item), partial), { dateModified: Date.now() }));
      }
    });
  }
  records(ids) {
    const items = {};
    for (const id of ids) {
      items[id] = this.cache.get(id);
    }
    return items;
  }
  items(ids) {
    const items = [];
    if (ids) {
      for (const id of ids) {
        const item = this.cache.get(id);
        if (!item || isDeleted(item)) continue;
        items.push(item);
      }
    } else {
      for (const [_key, value] of this.cache) {
        if (!value || isDeleted(value)) continue;
        items.push(value);
      }
    }
    return items;
  }
  *unsynced(chunkSize) {
    let chunk = [];
    for (const [_key, value] of this.cache) {
      if (value && !value.synced) {
        chunk.push(value);
        if (chunk.length === chunkSize) {
          yield chunk;
          chunk = [];
        }
      }
    }
    if (chunk.length > 0) yield chunk;
  }
  *stream() {
    for (const [_key, value] of this.cache) {
      if (value && !value.deleted) yield value;
    }
  }
  unsyncedCount() {
    return __async(this, null, function* () {
      return this.collection.unsyncedCount();
    });
  }
  // has(id: string) {
  //   return this.cache.has(id);
  // }
  // count() {
  //   return this.cache.size;
  // }
  // get(id: string) {
  //   const item = this.cache.get(id);
  //   if (!item || isDeleted(item)) return;
  //   return item;
  // }
  // getRaw(id: string) {
  //   const item = this.cache.get(id);
  //   return item;
  // }
  // raw() {
  //   return Array.from(this.cache.values());
  // }
  // items(map?: (item: T) => T | undefined) {
  //   if (this.cachedItems && this.cachedItems.length === this.cache.size)
  //     return this.cachedItems;
  //   this.cachedItems = [];
  //   this.cache.forEach((value) => {
  //     if (isDeleted(value)) return;
  //     const mapped = map ? map(value) : value;
  //     if (!mapped) return;
  //     this.cachedItems?.push(mapped);
  //   });
  //   this.cachedItems.sort((a, b) => b.dateCreated - a.dateCreated);
  //   return this.cachedItems;
  // }
  // async setItems(items: (MaybeDeletedItem<T> | undefined)[]) {
  //   await this.collection.setItems(items);
  // for (const item of items) {
  //   if (item) {
  //     this.cache.set(item.id, item);
  //   }
  // }
  //   this.invalidateCache();
  // }
  // *iterateSync(chunkSize: number) {
  //   yield* chunkedIterate(Array.from(this.cache.values()), chunkSize);
  // }
  // invalidateCache() {
  //   this.cachedItems = undefined;
  // }
};

// src/collections/shortcuts.ts
var ALLOWED_SHORTCUT_TYPES = ["notebook", "topic", "tag"];
var Shortcuts = class {
  constructor(db2) {
    this.db = db2;
    this.name = "shortcuts";
    this.collection = new SQLCachedCollection(
      db2.sql,
      db2.transaction,
      "shortcuts",
      db2.eventManager,
      db2.sanitizer
    );
  }
  init() {
    return this.collection.init();
  }
  add(shortcut) {
    return __async(this, null, function* () {
      if (!shortcut) return;
      if (shortcut.remote)
        throw new Error(
          "Please use db.shortcuts.merge to merge remote shortcuts."
        );
      if (shortcut.itemId && shortcut.itemType && !ALLOWED_SHORTCUT_TYPES.includes(shortcut.itemType))
        throw new Error("Cannot create a shortcut for this type of item.");
      const oldShortcut = shortcut.itemId ? this.shortcut(shortcut.itemId) : shortcut.id ? this.shortcut(shortcut.id) : null;
      shortcut = __spreadValues(__spreadValues({}, oldShortcut), shortcut);
      if (!shortcut.itemId || !shortcut.itemType)
        throw new Error("Cannot create a shortcut without an item.");
      const id = shortcut.id || shortcut.itemId;
      yield this.collection.upsert({
        id,
        type: "shortcut",
        itemId: shortcut.itemId,
        itemType: shortcut.itemType,
        dateCreated: shortcut.dateCreated || Date.now(),
        dateModified: shortcut.dateModified || Date.now(),
        sortIndex: -1
        // await this.collection.count()
      });
      return id;
    });
  }
  // get raw() {
  //   return this.collection.raw();
  // }
  get all() {
    return this.collection.items();
  }
  resolved(type = "all") {
    return __async(this, null, function* () {
      const tagIds = [];
      const notebookIds = [];
      for (const shortcut of this.all) {
        if ((type === "all" || type === "notebooks") && shortcut.itemType === "notebook")
          notebookIds.push(shortcut.itemId);
        else if ((type === "all" || type === "tags") && shortcut.itemType === "tag")
          tagIds.push(shortcut.itemId);
      }
      const notebooks = notebookIds.length > 0 ? yield this.db.notebooks.all.items(notebookIds) : [];
      const tags = tagIds.length > 0 ? yield this.db.tags.all.items(tagIds) : [];
      const sortedItems = [];
      this.all.sort((a, b) => a.dateCreated - b.dateCreated).forEach((shortcut) => {
        if (type === "all" || type === "notebooks") {
          const notebook = notebooks.find((n) => n.id === shortcut.itemId);
          if (notebook) sortedItems.push(notebook);
        }
        if (type === "all" || type === "tags") {
          const tag = tags.find((t) => t.id === shortcut.itemId);
          if (tag) sortedItems.push(tag);
        }
      });
      return sortedItems;
    });
  }
  exists(id) {
    return this.collection.exists(id);
  }
  shortcut(id) {
    return this.collection.get(id);
  }
  remove(...shortcutIds) {
    return __async(this, null, function* () {
      yield this.collection.softDelete(shortcutIds);
    });
  }
};

// src/collections/relations.ts
var Relations = class {
  constructor(db2) {
    this.db = db2;
    this.name = "relations";
    this.fromCache = /* @__PURE__ */ new Map();
    this.toCache = /* @__PURE__ */ new Map();
    this.collection = new SQLCollection(
      db2.sql,
      db2.transaction,
      "relations",
      db2.eventManager,
      db2.sanitizer
    );
  }
  init() {
    return __async(this, null, function* () {
      yield this.collection.init();
    });
  }
  add(from, to) {
    return __async(this, null, function* () {
      if (!from.id || !to.id) throw new Error("Invalid item reference.");
      yield this.collection.upsert({
        id: generateId(from, to),
        type: "relation",
        dateCreated: Date.now(),
        dateModified: Date.now(),
        fromId: from.id,
        fromType: from.type,
        toId: to.id,
        toType: to.type
      });
    });
  }
  from(reference, type) {
    return new RelationsArray(
      this.db,
      reference,
      type ? Array.isArray(type) ? type : [type] : void 0,
      "from"
    );
  }
  to(reference, type) {
    return new RelationsArray(
      this.db,
      reference,
      type ? Array.isArray(type) ? type : [type] : void 0,
      "to"
    );
  }
  buildCache() {
    return __async(this, null, function* () {
      console.time("cache build");
      this.fromCache.clear();
      this.toCache.clear();
      console.time("query");
      const relations = yield this.db.sql().selectFrom("relations").select(["toId", "fromId"]).$narrowType().execute();
      console.timeEnd("query");
      for (const { fromId, toId } of relations) {
        const fromIds = this.fromCache.get(fromId) || [];
        fromIds.push(toId);
        this.fromCache.set(fromId, fromIds);
        const toIds = this.toCache.get(toId) || [];
        toIds.push(fromId);
        this.toCache.set(toId, toIds);
      }
      console.timeEnd("cache build");
    });
  }
  // get raw() {
  //   return this.collection.raw();
  // }
  // get all(): Relation[] {
  //   return this.collection.items();
  // }
  // relation(id: string) {
  //   return this.collection.get(id);
  // }
  remove(...ids) {
    return __async(this, null, function* () {
      yield this.collection.softDelete(ids);
    });
  }
  unlink(from, to) {
    return __async(this, null, function* () {
      yield this.remove(generateId(from, to));
      this.db.eventManager.publish(EVENTS.databaseUpdated, {
        collection: "relations",
        type: "unlink",
        reference: to,
        direction: "from",
        types: [from.type]
      });
    });
  }
  unlinkOfType(type, ids) {
    return __async(this, null, function* () {
      yield this.db.sql().replaceInto("relations").columns(["id", "dateModified", "deleted", "synced"]).expression(
        (eb) => eb.selectFrom("relations").where(
          (eb2) => eb2.or([eb2("fromType", "==", type), eb2("toType", "==", type)])
        ).$if(
          ids !== void 0 && ids.length > 0,
          (eb2) => eb2.where(
            (eb3) => eb3.or([eb3("fromId", "in", ids), eb3("toId", "in", ids)])
          )
        ).select((eb2) => [
          "relations.id",
          eb2.lit(Date.now()).as("dateModified"),
          eb2.lit(1).as("deleted"),
          eb2.lit(0).as("synced")
        ])
      ).execute();
      this.db.eventManager.publish(EVENTS.databaseUpdated, {
        collection: "relations",
        type: "unlink",
        reference: { type, ids: ids || [] },
        direction: "from",
        types: Object.keys(TABLE_MAP)
      });
      this.db.eventManager.publish(EVENTS.databaseUpdated, {
        collection: "relations",
        type: "unlink",
        reference: { type, ids: ids || [] },
        direction: "to",
        types: Object.keys(TABLE_MAP)
      });
    });
  }
};
function generateId(a, b) {
  const str = `${a.id}${b.id}${a.type}${b.type}`;
  return makeId(str);
}
var TABLE_MAP = {
  note: "notes",
  notebook: "notebooks",
  reminder: "reminders",
  tag: "tags",
  color: "colors",
  attachment: "attachments",
  vault: "vaults"
};
var RelationsArray = class {
  constructor(db2, reference, types, direction) {
    this.db = db2;
    this.reference = reference;
    this.types = types;
    this.direction = direction;
  }
  get selector() {
    var _a;
    if (!this.types)
      throw new Error("Cannot use selector when no tables are specified.");
    if (this.types.length > 1)
      throw new Error(
        "Cannot use selector when more than 1 tables are specified."
      );
    const table = TABLE_MAP[this.types[0]];
    return new FilteredSelector(
      table,
      this.db.sql().selectFrom(table).where(
        "id",
        "in",
        (b) => b.selectFrom("relations").$call(
          (eb) => this.buildRelationsQuery()(
            eb
          )
        )
      ).where(isFalse("deleted")),
      (_a = this.db.options) == null ? void 0 : _a.batchSize
    );
  }
  resolve(limit) {
    return __async(this, null, function* () {
      const items = yield this.selector.filter.$if(limit !== void 0 && limit > 0, (b) => b.limit(limit)).selectAll().execute();
      return items;
    });
  }
  unlink() {
    return __async(this, null, function* () {
      yield this.db.sql().replaceInto("relations").columns(["id", "dateModified", "deleted", "synced"]).expression(
        (eb) => eb.selectFrom("relations").$call(this.buildRelationsQuery()).clearSelect().select((eb2) => [
          "relations.id",
          eb2.lit(Date.now()).as("dateModified"),
          eb2.lit(true).as("deleted"),
          eb2.lit(false).as("synced")
        ])
      ).execute();
      this.db.eventManager.publish(EVENTS.databaseUpdated, {
        collection: "relations",
        type: "unlink",
        reference: this.reference,
        direction: this.direction,
        types: this.types
      });
    });
  }
  get() {
    return __async(this, null, function* () {
      const relations = yield this.db.sql().selectFrom("relations").$call(this.buildRelationsQuery()).clearSelect().select(["fromId", "toId", "fromType", "toType"]).$narrowType().execute();
      return relations;
    });
  }
  count() {
    return __async(this, null, function* () {
      const result = yield this.db.sql().selectFrom("relations").$call(this.buildRelationsQuery()).clearSelect().select((b) => b.fn.count("relations.id").as("count")).executeTakeFirst();
      if (!result) return 0;
      return result.count;
    });
  }
  has(...ids) {
    return __async(this, null, function* () {
      const result = yield this.db.sql().selectFrom("relations").$call(this.buildRelationsQuery()).clearSelect().where(this.direction === "from" ? "toId" : "fromId", "in", ids).select((b) => b.fn.count("id").as("count")).executeTakeFirst();
      if (!result) return false;
      return result.count > 0;
    });
  }
  hasAll(...ids) {
    return __async(this, null, function* () {
      const result = yield this.db.sql().selectFrom("relations").$call(this.buildRelationsQuery()).clearSelect().where(this.direction === "from" ? "toId" : "fromId", "in", ids).select((b) => b.fn.count("id").as("count")).executeTakeFirst();
      if (!result) return false;
      return result.count === ids.length;
    });
  }
  /**
   * Build an optimized query for obtaining relations based on the given
   * parameters. The resulting query uses a covering index (the most
   * optimizable index) for obtaining relations.
   */
  buildRelationsQuery() {
    return (builder) => {
      var _a, _b, _c, _d;
      if (this.direction === "to") {
        return builder.$if(
          !!this.types,
          (eb) => eb.where(
            "fromType",
            this.types.length > 1 ? "in" : "==",
            this.types.length > 1 ? this.types : this.types[0]
          )
        ).where("toType", "==", this.reference.type).where(
          "toId",
          isItemReferences(this.reference) ? "in" : "==",
          isItemReferences(this.reference) ? this.reference.ids : this.reference.id
        ).$if(
          !!((_a = this.types) == null ? void 0 : _a.includes("note")) && this.db.trash.cache.notes.length > 0,
          (b) => b.where("fromId", "not in", this.db.trash.cache.notes)
        ).$if(
          !!((_b = this.types) == null ? void 0 : _b.includes("notebook")) && this.db.trash.cache.notebooks.length > 0,
          (b) => b.where("fromId", "not in", this.db.trash.cache.notebooks)
        ).select("relations.fromId as id").$narrowType();
      } else {
        return builder.$if(
          !!this.types,
          (eb) => eb.where(
            "toType",
            this.types.length > 1 ? "in" : "==",
            this.types.length > 1 ? this.types : this.types[0]
          )
        ).where("fromType", "==", this.reference.type).where(
          "fromId",
          isItemReferences(this.reference) ? "in" : "==",
          isItemReferences(this.reference) ? this.reference.ids : this.reference.id
        ).$if(
          !!((_c = this.types) == null ? void 0 : _c.includes("note")) && this.db.trash.cache.notes.length > 0,
          (b) => b.where("toId", "not in", this.db.trash.cache.notes)
        ).$if(
          !!((_d = this.types) == null ? void 0 : _d.includes("notebook")) && this.db.trash.cache.notebooks.length > 0,
          (b) => b.where("toId", "not in", this.db.trash.cache.notebooks)
        ).select("relations.toId as id").$narrowType();
      }
    };
  }
};
function isItemReferences(ref) {
  return "ids" in ref;
}

// src/api/subscriptions.ts
var Subscriptions = class {
  constructor(tokenManager) {
    this.tokenManager = tokenManager;
  }
  cancel() {
    return __async(this, null, function* () {
      const token = yield this.tokenManager.getAccessToken();
      if (!token) return;
      yield http_default.delete(`${constants_default.SUBSCRIPTIONS_HOST}/subscriptions`, token);
    });
  }
  refund() {
    return __async(this, null, function* () {
      const token = yield this.tokenManager.getAccessToken();
      if (!token) return;
      yield http_default.post(
        `${constants_default.SUBSCRIPTIONS_HOST}/subscriptions/refund`,
        null,
        token
      );
    });
  }
  transactions() {
    return __async(this, null, function* () {
      const token = yield this.tokenManager.getAccessToken();
      if (!token) return;
      return yield http_default.get(
        `${constants_default.SUBSCRIPTIONS_HOST}/subscriptions/transactions`,
        token
      );
    });
  }
  updateUrl() {
    return __async(this, null, function* () {
      const token = yield this.tokenManager.getAccessToken();
      if (!token) return;
      return yield http_default.get(
        `${constants_default.SUBSCRIPTIONS_HOST}/subscriptions/update`,
        token
      );
    });
  }
};

// src/collections/settings.ts
var DEFAULT_GROUP_OPTIONS = (key) => ({
  groupBy: "default",
  sortBy: key === "trash" ? "dateDeleted" : key === "tags" ? "dateCreated" : key === "reminders" ? "dueDate" : "dateEdited",
  sortDirection: key === "reminders" ? "asc" : "desc"
});
var defaultSettings = {
  timeFormat: "12-hour",
  dateFormat: "DD-MM-YYYY",
  titleFormat: "Note $date$ $time$",
  defaultNotebook: void 0,
  trashCleanupInterval: 7,
  profile: void 0,
  "groupOptions:trash": DEFAULT_GROUP_OPTIONS("trash"),
  "groupOptions:tags": DEFAULT_GROUP_OPTIONS("tags"),
  "groupOptions:notes": DEFAULT_GROUP_OPTIONS("notes"),
  "groupOptions:notebooks": DEFAULT_GROUP_OPTIONS("notebooks"),
  "groupOptions:favorites": DEFAULT_GROUP_OPTIONS("favorites"),
  "groupOptions:home": DEFAULT_GROUP_OPTIONS("home"),
  "groupOptions:reminders": DEFAULT_GROUP_OPTIONS("reminders"),
  "toolbarConfig:desktop": void 0,
  "toolbarConfig:mobile": void 0,
  "toolbarConfig:tablet": void 0,
  "toolbarConfig:smallTablet": void 0,
  "sideBarOrder:routes": [],
  "sideBarOrder:colors": [],
  "sideBarOrder:shortcuts": [],
  "sideBarHiddenItems:routes": [],
  "sideBarHiddenItems:colors": []
};
var KEY_IDS = Object.fromEntries(
  Object.keys(defaultSettings).map((key) => [key, makeId(key)])
);
var Settings = class {
  constructor(db2) {
    this.name = "settings";
    this.collection = new SQLCachedCollection(
      db2.sql,
      db2.transaction,
      "settings",
      db2.eventManager,
      db2.sanitizer
    );
  }
  init() {
    return this.collection.init();
  }
  set(key, value) {
    return __async(this, null, function* () {
      const id = KEY_IDS[key];
      if (!id) throw new Error(`Invalid settings key: ${key}.`);
      const oldItem = this.collection.get(id);
      if (oldItem && oldItem.key !== key) throw new Error("Key conflict.");
      yield this.collection.upsert({
        id,
        key,
        value,
        type: "settingitem",
        dateCreated: (oldItem == null ? void 0 : oldItem.dateCreated) || Date.now(),
        dateModified: (oldItem == null ? void 0 : oldItem.dateCreated) || Date.now()
      });
      return id;
    });
  }
  get(key) {
    const item = this.collection.get(KEY_IDS[key]);
    if (!item || item.key !== key) return defaultSettings[key];
    return item.value;
  }
  getGroupOptions(key) {
    return this.get(`groupOptions:${key}`);
  }
  setGroupOptions(key, groupOptions) {
    return this.set(`groupOptions:${key}`, groupOptions);
  }
  setToolbarConfig(platform, config) {
    return this.set(`toolbarConfig:${platform}`, config);
  }
  getToolbarConfig(platform) {
    return this.get(`toolbarConfig:${platform}`);
  }
  setTrashCleanupInterval(interval) {
    return this.set("trashCleanupInterval", interval);
  }
  getTrashCleanupInterval() {
    return this.get("trashCleanupInterval");
  }
  setDefaultNotebook(item) {
    return this.set("defaultNotebook", item);
  }
  getDefaultNotebook() {
    return this.get("defaultNotebook");
  }
  setTitleFormat(format2) {
    return this.set("titleFormat", format2);
  }
  getTitleFormat() {
    return this.get("titleFormat");
  }
  getDateFormat() {
    return this.get("dateFormat");
  }
  setDateFormat(format2) {
    return this.set("dateFormat", format2);
  }
  getTimeFormat() {
    return this.get("timeFormat");
  }
  setTimeFormat(format2) {
    return this.set("timeFormat", format2);
  }
  getSideBarOrder(section) {
    return this.get(`sideBarOrder:${section}`);
  }
  setSideBarOrder(section, order) {
    return this.set(`sideBarOrder:${section}`, order);
  }
  getSideBarHiddenItems(section) {
    return this.get(`sideBarHiddenItems:${section}`);
  }
  setSideBarHiddenItems(section, ids) {
    return this.set(`sideBarHiddenItems:${section}`, ids);
  }
  getProfile() {
    return this.get("profile");
  }
  setProfile(partial) {
    const profile = !partial ? void 0 : __spreadValues(__spreadValues({}, this.getProfile()), partial);
    return this.set("profile", profile);
  }
};

// src/api/index.ts
import { sql as sql12 } from "@streetwriters/kysely";

// src/database/cached-collection.ts
var CachedCollection = class {
  constructor(storage, type) {
    this.cache = /* @__PURE__ */ new Map();
    this.collection = new IndexedCollection(storage, type);
  }
  init() {
    return __async(this, null, function* () {
      yield this.collection.init();
      const data = yield this.collection.indexer.readMulti(
        this.collection.indexer.indices
      );
      this.cache = new Map(data);
    });
  }
  add(item) {
    return __async(this, null, function* () {
      yield this.collection.addItem(item);
      this.cache.set(item.id, item);
      this.invalidateCache();
    });
  }
  clear() {
    return __async(this, null, function* () {
      yield this.collection.clear();
      this.cache.clear();
      this.invalidateCache();
    });
  }
  exists(id) {
    const item = this.cache.get(id);
    return this.collection.exists(id) && !!item && !isDeleted(item);
  }
  has(id) {
    return this.cache.has(id);
  }
  count() {
    return this.cache.size;
  }
  delete(id) {
    return __async(this, null, function* () {
      this.cache.delete(id);
      yield this.collection.deleteItem(id);
      this.invalidateCache();
    });
  }
  items(map) {
    if (this.cachedItems && this.cachedItems.length === this.cache.size)
      return this.cachedItems;
    this.cachedItems = [];
    this.cache.forEach((value) => {
      var _a;
      if (isDeleted(value)) return;
      const mapped = map ? map(value) : value;
      if (!mapped) return;
      (_a = this.cachedItems) == null ? void 0 : _a.push(mapped);
    });
    this.cachedItems.sort((a, b) => b.dateCreated - a.dateCreated);
    return this.cachedItems;
  }
  get(id) {
    const item = this.cache.get(id);
    if (!item || isDeleted(item)) return;
    return item;
  }
  invalidateCache() {
    this.cachedItems = void 0;
  }
};

// src/collections/vaults.ts
var Vaults = class {
  constructor(db2) {
    this.db = db2;
    this.name = "vaults";
    this.collection = new SQLCollection(
      db2.sql,
      db2.transaction,
      "vaults",
      db2.eventManager,
      db2.sanitizer
    );
  }
  init() {
    return __async(this, null, function* () {
      yield this.collection.init();
    });
  }
  add(item) {
    return __async(this, null, function* () {
      const id = item.id || getId();
      const oldVault = item.id ? yield this.vault(item.id) : void 0;
      if (!item.title && !(oldVault == null ? void 0 : oldVault.title)) throw new Error("Title is required.");
      yield this.collection.upsert({
        id,
        dateCreated: item.dateCreated || (oldVault == null ? void 0 : oldVault.dateCreated) || Date.now(),
        dateModified: item.dateModified || (oldVault == null ? void 0 : oldVault.dateModified) || Date.now(),
        title: item.title || (oldVault == null ? void 0 : oldVault.title) || "",
        key: item.key,
        type: "vault"
      });
      return id;
    });
  }
  remove(id) {
    return __async(this, null, function* () {
      yield this.db.transaction(() => __async(this, null, function* () {
        yield this.db.relations.unlinkOfType("vault", [id]);
        yield this.collection.softDelete([id]);
      }));
    });
  }
  vault(id) {
    return this.collection.get(id);
  }
  /**
   * This is temporary until we add proper support for multiple vaults
   * @deprecated
   */
  default() {
    return __async(this, null, function* () {
      return (yield this.all.items()).at(0);
    });
  }
  get all() {
    var _a;
    return this.collection.createFilter(
      (qb) => qb.where(isFalse("deleted")),
      (_a = this.db.options) == null ? void 0 : _a.batchSize
    );
  }
  itemExists(reference) {
    return __async(this, null, function* () {
      return (yield this.db.relations.to(reference, "vault").count()) > 0;
    });
  }
};

// src/database/sanitizer.ts
var Sanitizer = class {
  constructor(db2) {
    this.db = db2;
    this.tables = {};
    this.logger = logger.scope("sanitizer");
  }
  init() {
    return __async(this, null, function* () {
      const metadata = yield this.db().introspection.getTables({
        withInternalKyselyTables: false
      });
      for (const table of metadata) {
        this.tables[table.name] = new Set(table.columns.map((c) => c.name));
      }
    });
  }
  /**
   * Sanitization is done based on the latest table schema in the database. All
   * unrecognized keys are removed
   */
  sanitize(table, item) {
    const schema = this.tables[table];
    if (!schema) {
      if (process.env.NODE_ENV === "test")
        throw new Error(
          `Invalid table: ${table} (expected one of ${Object.keys(
            this.tables
          ).join(", ")})`
        );
      return false;
    }
    for (const key in item) {
      if (schema.has(key)) continue;
      if (process.env.NODE_ENV === "test")
        throw new Error(`Found invalid key in item ${key} (${table})`);
      else
        this.logger.debug("Found invalid key in item", {
          table,
          key,
          value: item[key]
        });
      delete item[key];
    }
    return true;
  }
};

// src/database/triggers.ts
import { sql as sql10 } from "@streetwriters/kysely";
function createTriggers(db2) {
  return __async(this, null, function* () {
    yield db2.schema.createTrigger("content_after_insert_content_fts").temporary().ifNotExists().onTable("content", "main").after().addEvent("insert").when(
      (eb) => eb.and([
        eb("new.noteId", "is not", null),
        eb("new.data", "is not", null),
        eb("new.deleted", "is not", true)
      ])
    ).addQuery(
      (c) => c.insertInto("content_fts").values({
        rowid: sql10`new.rowid`,
        id: sql10`new.id`,
        data: sql10`IIF(new.locked == 1, '', new.data)`,
        noteId: sql10`new.noteId`
      })
    ).execute();
    yield db2.schema.createTrigger("content_after_delete_content_fts").temporary().ifNotExists().onTable("content", "main").after().addEvent("delete").when(
      (eb) => eb.and([
        eb("old.noteId", "is not", null),
        eb("old.data", "is not", null),
        eb("old.deleted", "is not", true)
      ])
    ).addQuery(
      (c) => c.insertInto("content_fts").values({
        content_fts: sql10.lit("delete"),
        rowid: sql10.ref("old.rowid"),
        id: sql10.ref("old.id"),
        data: sql10`IIF(old.locked == 1, '', old.data)`,
        noteId: sql10.ref("old.noteId")
      })
    ).execute();
    yield db2.schema.createTrigger("content_after_update_content_fts").temporary().ifNotExists().onTable("content", "main").after().addEvent("update").when(
      (eb) => eb.and([
        eb("old.noteId", "is not", null),
        eb("old.data", "is not", null),
        eb("old.deleted", "is not", true)
      ])
    ).addQuery(
      (c) => c.insertInto("content_fts").values({
        content_fts: sql10.lit("delete"),
        rowid: sql10.ref("old.rowid"),
        id: sql10.ref("old.id"),
        data: sql10`IIF(old.locked == 1, '', old.data)`,
        noteId: sql10.ref("old.noteId")
      })
    ).addQuery(
      (c) => c.insertInto("content_fts").values({
        rowid: sql10`new.rowid`,
        id: sql10`new.id`,
        data: sql10`IIF(new.locked == 1, '', new.data)`,
        noteId: sql10`new.noteId`
      })
    ).execute();
    yield db2.schema.createTrigger("notes_after_insert_notes_fts").temporary().ifNotExists().onTable("notes", "main").after().addEvent("insert").when(
      (eb) => eb.and([
        eb("new.title", "is not", null),
        eb("new.deleted", "is not", true)
      ])
    ).addQuery(
      (c) => c.insertInto("notes_fts").values({
        rowid: sql10`new.rowid`,
        id: sql10.ref("new.id"),
        title: sql10.ref("new.title")
      })
    ).execute();
    yield db2.schema.createTrigger("notes_after_delete_notes_fts").temporary().ifNotExists().onTable("notes", "main").after().addEvent("delete").when(
      (eb) => eb.and([
        eb("old.title", "is not", null),
        eb("old.deleted", "is not", true)
      ])
    ).addQuery(
      (c) => c.insertInto("notes_fts").values({
        notes_fts: sql10.lit("delete"),
        rowid: sql10.ref("old.rowid"),
        id: sql10.ref("old.id"),
        title: sql10.ref("old.title")
      })
    ).execute();
    yield db2.schema.createTrigger("notes_after_update_notes_fts").temporary().ifNotExists().onTable("notes", "main").after().addEvent("update").when(
      (eb) => eb.and([
        eb("old.deleted", "is not", true),
        eb("old.title", "is not", null)
      ])
    ).addQuery(
      (c) => c.insertInto("notes_fts").values({
        notes_fts: sql10.lit("delete"),
        rowid: sql10.ref("old.rowid"),
        id: sql10.ref("old.id"),
        title: sql10.ref("old.title")
      })
    ).addQuery(
      (c) => c.insertInto("notes_fts").values({
        rowid: sql10`new.rowid`,
        id: sql10.ref("new.id"),
        title: sql10.ref("new.title")
      })
    ).execute();
  });
}
function dropTriggers(db2) {
  return __async(this, null, function* () {
    yield db2.schema.dropTrigger("content_after_insert_content_fts").execute();
    yield db2.schema.dropTrigger("content_after_delete_content_fts").execute();
    yield db2.schema.dropTrigger("content_after_update_content_fts").execute();
    yield db2.schema.dropTrigger("notes_after_insert_notes_fts").execute();
    yield db2.schema.dropTrigger("notes_after_delete_notes_fts").execute();
    yield db2.schema.dropTrigger("notes_after_update_notes_fts").execute();
  });
}

// src/database/migrations.ts
import {
  sql as sql11
} from "@streetwriters/kysely";
var COLLATE_NOCASE = (col) => col.modifyEnd(sql11`collate nocase`);
var NNMigrationProvider = class {
  getMigrations() {
    return __async(this, null, function* () {
      return {
        "1": {
          up(db2) {
            return __async(this, null, function* () {
              yield db2.schema.createTable("kv").modifyEnd(sql11`without rowid`).addColumn("key", "text", (c) => c.primaryKey().unique().notNull()).addColumn("value", "text").addColumn("dateModified", "integer").execute();
              yield db2.schema.createTable("notes").$call(addBaseColumns).$call(addTrashColumns).addColumn("title", "text", COLLATE_NOCASE).addColumn("headline", "text").addColumn("contentId", "text").addColumn("pinned", "boolean").addColumn("favorite", "boolean").addColumn("localOnly", "boolean").addColumn("conflicted", "boolean").addColumn("readonly", "boolean").addColumn("dateEdited", "integer").execute();
              yield createFTS5Table(
                "notes_fts",
                [{ name: "id" }, { name: "title" }],
                { contentTable: "notes", tokenizer: ["porter", "trigram"] }
              ).execute(db2);
              yield db2.schema.createTable("content").$call(addBaseColumns).addColumn("noteId", "text").addColumn("data", "text").addColumn("locked", "boolean").addColumn("localOnly", "boolean").addColumn("conflicted", "text").addColumn("sessionId", "text").addColumn("dateEdited", "integer").addColumn("dateResolved", "integer").execute();
              yield createFTS5Table(
                "content_fts",
                [{ name: "id" }, { name: "noteId" }, { name: "data" }],
                { contentTable: "content", tokenizer: ["porter", "trigram"] }
              ).execute(db2);
              yield db2.schema.createTable("notehistory").modifyEnd(sql11`without rowid`).$call(addBaseColumns).addColumn("noteId", "text").addColumn("sessionContentId", "text").addColumn("localOnly", "boolean").addColumn("locked", "boolean").execute();
              yield db2.schema.createTable("sessioncontent").modifyEnd(sql11`without rowid`).$call(addBaseColumns).addColumn("data", "text").addColumn("contentType", "text").addColumn("locked", "boolean").addColumn("compressed", "boolean").addColumn("localOnly", "boolean").execute();
              yield db2.schema.createTable("notebooks").modifyEnd(sql11`without rowid`).$call(addBaseColumns).$call(addTrashColumns).addColumn("title", "text", COLLATE_NOCASE).addColumn("description", "text").addColumn("dateEdited", "integer").addColumn("pinned", "boolean").execute();
              yield db2.schema.createTable("tags").modifyEnd(sql11`without rowid`).$call(addBaseColumns).addColumn("title", "text", COLLATE_NOCASE).execute();
              yield db2.schema.createTable("colors").modifyEnd(sql11`without rowid`).$call(addBaseColumns).addColumn("title", "text", COLLATE_NOCASE).addColumn("colorCode", "text", (c) => c.unique()).execute();
              yield db2.schema.createTable("vaults").modifyEnd(sql11`without rowid`).$call(addBaseColumns).addColumn("title", "text", COLLATE_NOCASE).addColumn("key", "text").execute();
              yield db2.schema.createTable("relations").modifyEnd(sql11`without rowid`).$call(addBaseColumns).addColumn("fromType", "text").addColumn("fromId", "text").addColumn("toType", "text").addColumn("toId", "text").execute();
              yield db2.schema.createTable("shortcuts").modifyEnd(sql11`without rowid`).$call(addBaseColumns).addColumn("sortIndex", "integer").addColumn("itemId", "text").addColumn("itemType", "text").execute();
              yield db2.schema.createTable("reminders").modifyEnd(sql11`without rowid`).$call(addBaseColumns).addColumn("title", "text", COLLATE_NOCASE).addColumn("description", "text").addColumn("priority", "text").addColumn("date", "integer").addColumn("mode", "text").addColumn("recurringMode", "text").addColumn("selectedDays", "text").addColumn("localOnly", "boolean").addColumn("disabled", "boolean").addColumn("snoozeUntil", "integer").execute();
              yield db2.schema.createTable("attachments").modifyEnd(sql11`without rowid`).$call(addBaseColumns).addColumn("iv", "text").addColumn("salt", "text").addColumn("size", "integer").addColumn("alg", "text").addColumn("key", "text").addColumn("chunkSize", "integer").addColumn("hash", "text", (c) => c.unique()).addColumn("hashType", "text").addColumn("mimeType", "text").addColumn("filename", "text").addColumn("dateDeleted", "integer").addColumn("dateUploaded", "integer").addColumn("failed", "text").execute();
              yield db2.schema.createTable("settings").modifyEnd(sql11`without rowid`).$call(addBaseColumns).addColumn("key", "text", (c) => c.unique()).addColumn("value", "text").execute();
              yield db2.schema.createIndex("notehistory_noteid").on("notehistory").column("noteId").execute();
              yield db2.schema.createIndex("relation_from_general").on("relations").columns(["fromType", "toType", "fromId"]).where("toType", "!=", "note").where("toType", "!=", "notebook").execute();
              yield db2.schema.createIndex("relation_to_general").on("relations").columns(["fromType", "toType", "toId"]).where("fromType", "!=", "note").where("fromType", "!=", "notebook").execute();
              yield db2.schema.createIndex("relation_from_note_notebook").on("relations").columns(["fromType", "toType", "fromId", "toId"]).where(
                (eb) => eb.or([
                  eb("toType", "==", "note"),
                  eb("toType", "==", "notebook")
                ])
              ).execute();
              yield db2.schema.createIndex("relation_to_note_notebook").on("relations").columns(["fromType", "toType", "toId", "fromId"]).where(
                (eb) => eb.or([
                  eb("fromType", "==", "note"),
                  eb("fromType", "==", "notebook")
                ])
              ).execute();
              yield db2.schema.createIndex("note_type").on("notes").columns(["type"]).execute();
              yield db2.schema.createIndex("note_deleted").on("notes").columns(["deleted"]).execute();
              yield db2.schema.createIndex("note_date_deleted").on("notes").columns(["dateDeleted"]).execute();
              yield db2.schema.createIndex("notebook_type").on("notebooks").columns(["type"]).execute();
              yield db2.schema.createIndex("attachment_hash").on("attachments").column("hash").execute();
              yield db2.schema.createIndex("content_noteId").on("content").columns(["noteId"]).execute();
            });
          },
          down(db2) {
            return __async(this, null, function* () {
            });
          }
        },
        "2": {
          up(db2) {
            return __async(this, null, function* () {
              yield rebuildSearchIndex(db2);
            });
          }
        },
        "3": {
          up(db2) {
            return __async(this, null, function* () {
              yield db2.updateTable("notes").where(
                "id",
                "in",
                (eb) => eb.selectFrom("content").select("noteId as id").where(
                  (eb2) => eb2.or([
                    eb2("conflicted", "is", null),
                    eb2("conflicted", "==", false)
                  ])
                ).$castTo()
              ).set({ conflicted: false }).execute();
            });
          }
        },
        "4": {
          up(db2) {
            return __async(this, null, function* () {
              yield db2.schema.createTable("config").modifyEnd(sql11`without rowid`).addColumn("name", "text", (c) => c.primaryKey().unique().notNull()).addColumn("value", "text").addColumn("dateModified", "integer").execute();
            });
          }
        },
        "5": {
          up(db2) {
            return __async(this, null, function* () {
              yield db2.deleteFrom("relations").where(
                (eb) => eb.or([eb("fromId", "is", null), eb("toId", "is", null)])
              ).execute();
            });
          }
        },
        "6": {
          up(db2) {
            return __async(this, null, function* () {
              yield db2.transaction().execute((tx) => __async(this, null, function* () {
                yield tx.schema.dropTable("content_fts").execute();
                yield tx.schema.dropTable("notes_fts").execute();
                yield createFTS5Table(
                  "notes_fts",
                  [{ name: "id" }, { name: "title" }],
                  {
                    contentTable: "notes",
                    tokenizer: ["porter", "trigram", "remove_diacritics 1"]
                  }
                ).execute(tx);
                yield createFTS5Table(
                  "content_fts",
                  [{ name: "id" }, { name: "noteId" }, { name: "data" }],
                  {
                    contentTable: "content",
                    tokenizer: ["porter", "trigram", "remove_diacritics 1"]
                  }
                ).execute(tx);
              }));
              yield rebuildSearchIndex(db2);
            });
          }
        }
      };
    });
  }
};
var addBaseColumns = (builder) => {
  return builder.addColumn("id", "text", (c) => c.primaryKey().unique().notNull()).addColumn("type", "text").addColumn("dateModified", "integer").addColumn("dateCreated", "integer").addColumn("synced", "boolean").addColumn("deleted", "boolean");
};
var addTrashColumns = (builder) => {
  return builder.addColumn("dateDeleted", "integer").addColumn("itemType", "text").addColumn("deletedBy", "text");
};
function createFTS5Table(name, columns, options = {}) {
  const _options = [];
  if (options.contentTable) _options.push(`content='${options.contentTable}'`);
  if (options.contentTableRowId)
    _options.push(`content_rowid='${options.contentTableRowId}'`);
  if (options.tokenizer)
    _options.push(`tokenize='${options.tokenizer.join(" ")}'`);
  if (options.prefix) _options.push(`prefix='${options.prefix.join(" ")}'`);
  if (options.columnSize) _options.push(`columnsize='${options.columnSize}'`);
  if (options.detail) _options.push(`detail='${options.detail}'`);
  const args = sql11.join([
    sql11.join(
      columns.map((c) => sql11.ref(`${c.name}${c.unindexed ? " UNINDEXED" : ""}`))
    ),
    sql11.join(_options.map((o) => sql11.raw(o)))
  ]);
  return sql11`CREATE VIRTUAL TABLE ${sql11.raw(name)} USING fts5(${args})`;
}

// src/database/config.ts
var ConfigStorage = class {
  constructor(db2) {
    this.db = db2;
  }
  getItem(name) {
    return __async(this, null, function* () {
      const result = yield this.db().selectFrom("config").where("name", "==", name).select("value").limit(1).executeTakeFirst();
      if (!(result == null ? void 0 : result.value)) return;
      return JSON.parse(result.value);
    });
  }
  setItem(name, value) {
    return __async(this, null, function* () {
      yield this.db().replaceInto("config").values({
        name,
        value: JSON.stringify(value),
        dateModified: Date.now()
      }).execute();
    });
  }
  removeItem(name) {
    return __async(this, null, function* () {
      yield this.db().deleteFrom("config").where("name", "==", name).execute();
    });
  }
  clear() {
    return __async(this, null, function* () {
      yield this.db().deleteFrom("config").execute();
    });
  }
};

// src/api/index.ts
var Database = class {
  constructor() {
    this.isInitialized = false;
    this.eventManager = new event_manager_default();
    this.sseMutex = new Mutex3();
    this.storage = () => {
      var _a;
      if (!((_a = this.options) == null ? void 0 : _a.storage))
        throw new Error(
          "Database not initialized. Did you forget to call db.setup()?"
        );
      return this.options.storage;
    };
    this.fs = () => {
      var _a;
      if (!((_a = this.options) == null ? void 0 : _a.fs))
        throw new Error(
          "Database not initialized. Did you forget to call db.setup()?"
        );
      return this._fs || (this._fs = new FileStorage(this.options.fs, this.tokenManager));
    };
    this.crypto = () => {
      if (!this.options)
        throw new Error(
          "Database not initialized. Did you forget to call db.setup()?"
        );
      return new Crypto(this.storage);
    };
    this.compressor = () => {
      var _a;
      if (!((_a = this.options) == null ? void 0 : _a.compressor))
        throw new Error(
          "Database not initialized. Did you forget to call db.setup()?"
        );
      return this.options.compressor;
    };
    this.sql = () => {
      if (!this._sql)
        throw new Error(
          "Database not initialized. Did you forget to call db.init()?"
        );
      return this._sql;
    };
    this.kv = () => this._kv || new KVStorage(this.sql);
    this.config = () => this._config || new ConfigStorage(this.sql);
    this.transaction = (executor) => __async(this, null, function* () {
      yield executor(this.sql());
    });
    this.tokenManager = new token_manager_default(this.kv);
    this.mfa = new mfa_manager_default(this.tokenManager);
    this.subscriptions = new Subscriptions(this.tokenManager);
    this.offers = new Offers();
    this.debug = new Debug();
    this.pricing = Pricing;
    this.user = new user_manager_default(this);
    this.syncer = new SyncManager(this);
    this.vault = new Vault2(this);
    this.lookup = new Lookup(this);
    this.backup = new Backup(this);
    this.migrations = new migrations_default(this);
    this.monographs = new Monographs(this);
    this.trash = new Trash(this);
    this.sanitizer = new Sanitizer(this.sql);
    this.notebooks = new Notebooks(this);
    this.tags = new Tags(this);
    this.colors = new Colors(this);
    this.content = new Content(this);
    this.attachments = new Attachments(this);
    this.noteHistory = new NoteHistory(this);
    this.shortcuts = new Shortcuts(this);
    this.reminders = new Reminders(this);
    this.relations = new Relations(this);
    this.notes = new Notes(this);
    this.vaults = new Vaults(this);
    this.settings = new Settings(this);
    /**
     * @deprecated only kept here for migration purposes
     */
    this.legacyTags = new CachedCollection(this.storage, "tags");
    /**
     * @deprecated only kept here for migration purposes
     */
    this.legacyColors = new CachedCollection(this.storage, "colors");
    /**
     * @deprecated only kept here for migration purposes
     */
    this.legacyNotes = new CachedCollection(this.storage, "notes");
    /**
     * @deprecated only kept here for migration purposes
     */
    this.legacySettings = new LegacySettings(this);
  }
  // constructor() {
  //   this.sseMutex = new Mutex();
  //   // this.lastHeartbeat = undefined; // { local: 0, server: 0 };
  //   // this.timeErrorFailures = 0;
  // }
  setup(options) {
    this.options = options;
  }
  reset() {
    return __async(this, null, function* () {
      yield this.storage().clear();
      yield dropTriggers(this.sql());
      for (const statement of [
        "PRAGMA writable_schema = 1",
        "DELETE FROM sqlite_master",
        "PRAGMA writable_schema = 0",
        "VACUUM",
        "PRAGMA integrity_check"
      ]) {
        yield sql12.raw(statement).execute(this.sql());
      }
      yield initializeDatabase(
        this.sql().withTables(),
        new NNMigrationProvider()
      );
      yield this.onInit(this.sql());
      yield this.initCollections();
      return true;
    });
  }
  changePassword(password) {
    return __async(this, null, function* () {
      if (!this._sql) return;
      yield changeDatabasePassword(this._sql, password);
    });
  }
  init() {
    return __async(this, null, function* () {
      if (!this.options)
        throw new Error(
          "options not specified. Did you forget to call db.setup()?"
        );
      EV.subscribeMulti(
        [EVENTS.userLoggedIn, EVENTS.userFetched, EVENTS.tokenRefreshed],
        this.connectSSE,
        this
      );
      EV.subscribe(EVENTS.attachmentDeleted, (attachment) => __async(this, null, function* () {
        yield this.fs().cancel(attachment.hash);
      }));
      EV.subscribe(EVENTS.userLoggedOut, () => __async(this, null, function* () {
        yield this.monographs.clear();
        yield this.fs().clear();
        this.disconnectSSE();
      }));
      this._sql = yield createDatabase("notesnook", __spreadProps(__spreadValues({}, this.options.sqliteOptions), {
        migrationProvider: new NNMigrationProvider(),
        onInit: (db2) => this.onInit(db2)
      }));
      yield this.sanitizer.init();
      yield this.initCollections();
      yield this.migrations.init();
      this.isInitialized = true;
      if (this.migrations.required()) {
        logger.warn("Database migration is required.");
      }
    });
  }
  onInit(db2) {
    return __async(this, null, function* () {
      yield createTriggers(db2);
    });
  }
  initCollections() {
    return __async(this, null, function* () {
      yield this.legacySettings.init();
      yield this.settings.init();
      yield this.notebooks.init();
      yield this.tags.init();
      yield this.colors.init();
      yield this.content.init();
      yield this.attachments.init();
      yield this.noteHistory.init();
      yield this.shortcuts.init();
      yield this.reminders.init();
      yield this.relations.init();
      yield this.notes.init();
      yield this.vaults.init();
      yield this.trash.init();
      yield this.legacyTags.init();
      yield this.legacyColors.init();
      yield this.legacyNotes.init();
      this.monographs.refresh().catch(logger.error);
    });
  }
  disconnectSSE() {
    if (!this.eventSource) return;
    this.eventSource.onopen = null;
    this.eventSource.onmessage = null;
    this.eventSource.onerror = null;
    this.eventSource.close();
    this.eventSource = null;
  }
  /**
   *
   * @param {{force: boolean, error: any}} args
   */
  connectSSE(args) {
    return __async(this, null, function* () {
      yield this.sseMutex.runExclusive(() => __async(this, null, function* () {
        const forceReconnect = args && args.force;
        const EventSource = this.options.eventsource;
        if (!EventSource || !forceReconnect && this.eventSource && this.eventSource.readyState === this.eventSource.OPEN)
          return;
        this.disconnectSSE();
        const token = yield this.tokenManager.getAccessToken();
        if (!token) return;
        this.eventSource = new EventSource(`${constants_default.SSE_HOST}/sse`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        this.eventSource.onopen = () => __async(this, null, function* () {
          console.log("SSE: opened channel successfully!");
        });
        this.eventSource.onerror = function(error) {
          console.log("SSE: error:", error);
        };
        this.eventSource.onmessage = (event) => __async(this, null, function* () {
          try {
            const message = JSON.parse(event.data);
            const data = JSON.parse(message.data);
            switch (message.type) {
              case "upgrade": {
                const user = yield this.user.getUser();
                if (!user) break;
                user.subscription = data;
                yield this.user.setUser(user);
                EV.publish(EVENTS.userSubscriptionUpdated, data);
                yield this.tokenManager._refreshToken(true);
                break;
              }
              case "logout": {
                yield this.user.logout(true, data.reason || "Unknown.");
                break;
              }
              case "emailConfirmed": {
                yield this.tokenManager._refreshToken(true);
                yield this.user.fetchUser();
                EV.publish(EVENTS.userEmailConfirmed);
                break;
              }
            }
          } catch (e) {
            console.log("SSE: Unsupported message. Message = ", event.data);
            return;
          }
        });
      }));
    });
  }
  lastSynced() {
    return __async(this, null, function* () {
      return (yield this.kv().read("lastSynced")) || 0;
    });
  }
  setLastSynced(lastSynced) {
    return this.kv().write("lastSynced", lastSynced);
  }
  sync(options) {
    return this.syncer.start(options);
  }
  hasUnsyncedChanges() {
    return this.syncer.sync.collector.hasUnsyncedChanges();
  }
  host(hosts2) {
    constants_default.AUTH_HOST = hosts2.AUTH_HOST || constants_default.AUTH_HOST;
    constants_default.API_HOST = hosts2.API_HOST || constants_default.API_HOST;
    constants_default.SSE_HOST = hosts2.SSE_HOST || constants_default.SSE_HOST;
    constants_default.SUBSCRIPTIONS_HOST = hosts2.SUBSCRIPTIONS_HOST || constants_default.SUBSCRIPTIONS_HOST;
    constants_default.ISSUES_HOST = hosts2.ISSUES_HOST || constants_default.ISSUES_HOST;
  }
  version() {
    return http_default.get(`${constants_default.API_HOST}/version`);
  }
  announcements() {
    return __async(this, null, function* () {
      let url = `${constants_default.API_HOST}/announcements/active`;
      const user = yield this.user.getUser();
      if (user) url += `?userId=${user.id}`;
      return http_default.get(url);
    });
  }
};
var api_default = Database;
export {
  CHECK_IDS,
  CLIENT_ID,
  CURRENT_DATABASE_VERSION,
  Crypto,
  DATE_FORMATS,
  dataurl_default as DataURL,
  api_default as Database,
  Debug,
  DefaultColors,
  DocumentMimeTypes,
  EMPTY_CONTENT,
  EV,
  EVENTS,
  EventManager,
  FilteredSelector,
  GroupingKey,
  HTMLParser,
  HTMLRewriter,
  LogLevel,
  MONTHS_FULL,
  Monographs,
  NEWLINE_STRIP_REGEX,
  PDFMimeType,
  Pricing,
  QueueValue,
  RequestError,
  SYNC_CHECK_IDS,
  TIME_FORMATS,
  Tiptap,
  VAULT_ERRORS,
  VirtualizedGrouping,
  WebClipMimeType,
  addItem,
  checkIsUserPremium,
  checkSyncStatus,
  chunkedIterate,
  chunkify,
  clone,
  createInternalLink,
  createKeySelector,
  createObjectId,
  deleteItem,
  deleteItems,
  ellipsize,
  errorTransformer,
  extractFirstParagraph,
  extractHostname,
  extractInternalLinks,
  findById,
  findItemAndDelete,
  format,
  formatDate,
  formatReminderTime,
  formatTitle,
  getContentFromData,
  getDummyDocument,
  getFileNameWithExtension,
  getId,
  getInnerText,
  getObjectIdTimestamp,
  getServerNameFromHost,
  getSortSelectors,
  getSortValue,
  getTimeFormat,
  getUpcomingReminder,
  getWeekGroupFromTimestamp,
  groupArray,
  hasItem,
  hasRequire,
  highlightInternalLinks,
  hosts,
  initialize,
  isAudio,
  isCipher,
  isDecryptedContent,
  isDeleted,
  isDocument,
  isEncryptedContent,
  isGroupHeader,
  isGroupingKey,
  isHTMLEqual,
  isImage,
  isInternalLink,
  isReminderActive,
  isReminderToday,
  isServerCompatible,
  isTrashItem,
  isVideo,
  isWebClip,
  logManager,
  logger,
  makeId,
  makeSessionContentId,
  parseHTML,
  parseInternalLink,
  randomBytes,
  randomInt,
  sanitizeTag,
  sendMigrationProgressEvent,
  sendSyncProgressEvent,
  set,
  toChunks,
  transformQuery
};
